const RequestService = require('../shared/services/request.service').RequestService;
const Modal = require('../shared/classes/modal.class').Modal;

// user avatar
const USER_AVATAR_EDIT_BUTTON_CLASS = 'user-avatar-edit';
const USER_AVATAR_EDIT_MODAL_CLASS = 'user-avatar-modal';
const USER_AVATAR_EDIT_MODAL_ID = 'user-avatar-form';
const USER_AVATAR_MAIN_IMG_CLASS = 'dashboard-user-image';
const USER_AVATAR_ITEM_CLASS = 'user-avatar-item-input';

/**
 * select user avatar
 * @param {Array} avatarsList - list of user avatars
 * @param {string} userAvatarUrl - url for selected user avatar
 */
function selectUserAvatar(avatarsList, userAvatarUrl) {
    avatarsList.forEach(element => {
        if (element.getAttribute('value') === userAvatarUrl) {
            element.setAttribute('checked', 'checked');
        }
    });
}

/**
 * Show add address form
 * @param {string} url - address form url
 * @param {Object} context - class instance
 */
function showEditAvatar(url, context) {
    RequestService.setEditAvatarForm(url);
    RequestService.getEditAvatarForm(null, (res) => {
        context.setContent(res, USER_AVATAR_EDIT_MODAL_CLASS);
        $(`#${context.id}`).modal('show');
        selectUserAvatar(
            Array.from(document.querySelectorAll(`.${USER_AVATAR_ITEM_CLASS}`)),
            document.querySelectorAll(`#${USER_AVATAR_EDIT_MODAL_ID}`)[0].dataset.userAvatar
        );
        $(`#${context.id}`).on('hidden.bs.modal', function () {
            context.destroyWrapper(context.id);
        });
    });
}

/**
 * Save avatar
 * @param {string} url - save address url
 * @param {string} data - save address requset body
 * @param {Object} context - class instance
 */
function saveAvatar(url, data, context) {
    if (!url || !data) return;
    RequestService.setSaveAvatar(url);
    RequestService.saveAvatar(null, (res) => {
        const resJson = JSON.parse(res);
        if (resJson.success) {
            document.querySelectorAll(`.${USER_AVATAR_MAIN_IMG_CLASS}`)[0].setAttribute('src', resJson.avatarUrl);
        }
        $(`#${context.id}`).modal('hide');
    }, data);
}

module.exports = {
    init: function () {
        const userEditAvatarButton = document.querySelectorAll(`.${USER_AVATAR_EDIT_BUTTON_CLASS}`)[0];
        if (userEditAvatarButton) {
            Modal.build({
                openButtonClass: USER_AVATAR_EDIT_BUTTON_CLASS,
                modalConfigurations: {
                    cancelButtonText: userEditAvatarButton.dataset.avatarModalCancelBtnText,
                    okButtonText: userEditAvatarButton.dataset.avatarModalOkBtnText,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.avatarUrl;
                        const form = document.querySelectorAll(`#${USER_AVATAR_EDIT_MODAL_ID}`)[0];
                        if (url) {
                            const formData = `${$(form).serialize()}`;
                            saveAvatar(url, formData, context);
                        }
                    },
                    cancelButtonHandler: (context) => {
                        $(`#${context.id}`).modal('hide');
                    }
                },
                openHandler: (target, context) => {
                    const url = context.triggerButton.getAttribute('href');
                    if (url !== 'null') {
                        showEditAvatar(url, context);
                    }
                }
            });
        }
    }
};
