var base = require('./base');
/**
 * Get Updated minicart
 */
function updateCartView() {
    var minicartContainer = $('.js-minicart-container');
    if (minicartContainer.length > 0) {
        var url = minicartContainer.data('url');
        if (url) {
            $.spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success(data) {
                    var cartHelper = require('../cart/cart');
                    cartHelper.updateCartTotals(data);
                    var minicartViewData = base.parseMinicartHtml(data.minicartView);
                    minicartContainer.find('.product-summary').replaceWith(minicartViewData.products);
                    minicartContainer.find('.cart-error').replaceWith(minicartViewData.cartError);
                    $('.js-product-note').each(function () {
                        $(this).unbind('click');
                        $(this).click(() => { base.showProductComment(this); });
                    });
                },
                error() {
                    $.spinner().stop();
                }
            });
        }
    }
}

module.exports = {
    updateCartView: updateCartView
};
