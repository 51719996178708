const storeLocator = require('../storeLocator/storeLocator');

// get autocmplete country range list
const ADDRESS_RANGE_SELECTOR = '#addressRange';
const ADDRESS_RANGE_DATA_ATTR = 'address';

const AUTOCOMPLETE_FORM_SELECTOR = '.js-save-delivery-address';

// init autocomplete selectors list
const SELECTORS_FOR_INIT = [{
    INPUT_SELECTOR: '.js-address-autocomplete',
    COORDINATE_SELECTOR: {
        LAT: '.locationLat',
        LNG: '.locationLng'
    }
}];

// autocomplete range list
const ADDRESS_RANGE_NODE = document.querySelectorAll(ADDRESS_RANGE_SELECTOR)[0];
const ADDRESS_RANGE_STRING = ADDRESS_RANGE_NODE ? ADDRESS_RANGE_NODE.dataset[ADDRESS_RANGE_DATA_ATTR] : null;
/* eslint-disable */
const ADDRESS_RANGE_LIST = ADDRESS_RANGE_STRING ? (ADDRESS_RANGE_STRING.includes(',') ? ADDRESS_RANGE_STRING.spilt(',') : [ADDRESS_RANGE_STRING]) : null;
/* eslint-enable */

/**
 * init autocomplete
 * @param {Object} selector - html node
 * @param {Object} options - options for autocomplete component
 * @param {Object} coordinatesSelector - object with selectors for autocomplete
 */
function initAutocomplate(selector, options, coordinatesSelector) {
    /* eslint-disable */
    if (typeof google === 'undefined') return;

    const autocomplete = new google.maps.places.Autocomplete(selector, {
        ...options
    });

    /**
     * get coordinates functionality
     * @param {Object} autocomplete - google Autocomplete instance
     */
    function getPlaceCoordinates(autocomplete) {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
            return {
                lat: null,
                lng: null
            }
        }

        const coordinates = place.geometry.location;

        return {
            lat: coordinates.lat(),
            lng: coordinates.lng()
        }
    }

    autocomplete.addListener("place_changed", () => {
        const coordinates = getPlaceCoordinates(autocomplete);
        const latInput = document.querySelectorAll(coordinatesSelector.LAT)[0];
        const lngInput = document.querySelectorAll(coordinatesSelector.LNG)[0];
        latInput.value = coordinates.lat;
        lngInput.value = coordinates.lng;

        if (window.loadStoreLocator) {
            storeLocator.addressDataRequst($(AUTOCOMPLETE_FORM_SELECTOR));
        }
    });
    /* eslint-enable */
}

module.exports = {
    init: function () {
        SELECTORS_FOR_INIT.forEach((selector) => {
            const nodesList = document.querySelectorAll(selector.INPUT_SELECTOR);
            nodesList.forEach((item) => {
                initAutocomplate(
                    item,
                    {
                        componentRestrictions: {
                            country: ADDRESS_RANGE_LIST
                        }
                    },
                    selector.COORDINATE_SELECTOR
                );
            });
        });
    },
    config: {
        address_range: ADDRESS_RANGE_LIST
    }
};
