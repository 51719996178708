// imports section
const utils = require('./shared/helpers/utils');

/**
 * disable scroll global variable
 */
const SCROLL_SPACE = '14px';

/**
 * created const for names class and ID
 */
const MODAL_LOGIN_ID = 'modalLogin';
const HEADER = 'header';
const BODY = 'body';
const LOCK_SCROLL = 'lock-scroll';
const SHOW = 'show';
const MODAL_CONFIRM_MSG = 'myConfirmMsgModal';

/**
 * created elements
 */
const isPhone = utils.resolution.isPhone;
const isTablet = utils.resolution.isTablet;
const header = document.querySelectorAll(`.${HEADER}`)[0];
const modalLogin = document.getElementById(MODAL_LOGIN_ID);
const body = document.querySelector(BODY);
const modalConfirmMsgLogin = document.getElementById(MODAL_CONFIRM_MSG);

/**
 * function that do hide scroll
 */
function hideScroll() {
    header.style.paddingRight = 0;
}

/**
 * function that do show scroll
 */
function showScroll() {
    header.style.paddingRight = SCROLL_SPACE;
}

/**
 * function that do lock scroll
 */
function lockScroll() {
    body.classList.add(LOCK_SCROLL);
}

/**
 * function that do unlock scroll
 */
function unlockScroll() {
    body.classList.remove(LOCK_SCROLL);
}
/**
 * disable scroll
 */
function bgScroll() {
    if (!isPhone && !isTablet) {
        $(BODY).on('shown.bs.modal', showScroll());
        $(BODY).on('hide.bs.modal', hideScroll());
        $(BODY).on('hidden.bs.modal', () => {
            if (!modalLogin.classList.contains(SHOW)) {
                hideScroll();
            }
        });
    }
    $(`#${MODAL_LOGIN_ID}`).on('shown.bs.modal', () => {
        lockScroll();
        showScroll();
    });

    $(`#${MODAL_LOGIN_ID}`).on('hidden.bs.modal', () => {
        unlockScroll();
        hideScroll();
    });

    $(`#${MODAL_CONFIRM_MSG}`).on('shown.bs.modal', () => {
        lockScroll();
        showScroll();
    });

    $(`#${MODAL_CONFIRM_MSG}`).on('hidden.bs.modal', () => {
        unlockScroll();
        hideScroll();
    });
}

module.exports = function () {
    if (modalLogin || modalConfirmMsgLogin) {
        bgScroll();
    }
};
