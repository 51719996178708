const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    'Accept-Encoding': 'gzip, deflate, br'
};

class KlaviyoApi {
    static build(data) {
        return new KlaviyoApi()
            .setApiKey(data.apiKey)
            .setListId(data.listId)
            .setKlaviyoApiUrl(data.klaviyoApiUrl)
            .setProxyServer(data.proxyServer);
    }

    setProxyServer(proxyServer) {
        this.proxyServer = proxyServer;
        return this;
    }

    setApiKey(apiKey) {
        this.apiKey = apiKey;
        return this;
    }

    setListId(listId) {
        this.listId = listId;
        return this;
    }

    setKlaviyoApiUrl(klaviyoApiUrl) {
        this.klaviyoApiUrl = klaviyoApiUrl;
        return this;
    }

    subscribeProfileToList(email) {
        const options = {
            method: 'POST',
            headers,
            body: JSON.stringify({
                profiles: [
                    {
                        email: email
                    }
                ]
            })
        };

        const urlString = `${this.proxyServer}${this.klaviyoApiUrl}list/${this.listId}/subscribe?api_key=${this.apiKey}`;
        fetch(urlString, options)
            .then(response => response.json())
            .then(response => console.log(response)) // eslint-disable-line
            .catch(err => console.error(err)); // eslint-disable-line
    }

    addMemeberToList(email) {
        const options = {
            method: 'POST',
            headers,
            body: JSON.stringify({
                profiles: [
                    { email: email }
                ]
            })
        };

        const urlString = `${this.proxyServer}${this.klaviyoApiUrl}list/${this.listId}/members?api_key=${this.apiKey}`;
        fetch(urlString, options)
            .then(response => response.json())
            .then(response => console.log(response)) // eslint-disable-line
            .catch(err => console.error(err)); // eslint-disable-line
    }
}

module.exports = {
    KlaviyoApi: KlaviyoApi
};
