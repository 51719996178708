// class require JQuery
const ACTIVE_ITEM_CLASS = 'active';
const DOCUMENT = $(document);

class SelectionProposal {
    static build(data) {
        return new SelectionProposal()
            .setInput(data.input)
            .setDropdown(data.dropdown)
            .initEvents();
    }

    setInput(input) {
        this.input = $(input);
        return this;
    }

    setDropdown(dropdown) {
        this.setDropdownWrapper(dropdown.wrapper)
            .setDropdownTrigger(dropdown.trigger)
            .setDropdownList(dropdown.list)
            .setDropdownItem(dropdown.item);
        return this;
    }

    setDropdownWrapper(wrapper) {
        this.dropdownWrapper = $(wrapper);
        this.dropdownWrapperClass = wrapper;
        return this;
    }

    setDropdownTrigger(trigger) {
        this.dropdownTrigger = $(trigger);
        return this;
    }

    setDropdownList(list) {
        this.dropdownList = $(list);
        return this;
    }

    setDropdownItem(item) {
        this.dropdownItem = $(item);
        return this;
    }

    hideDropdown() {
        this.dropdownTrigger.removeClass(ACTIVE_ITEM_CLASS);
        this.dropdownList.slideUp();
    }

    showDropdown() {
        this.dropdownTrigger.addClass(ACTIVE_ITEM_CLASS);
        this.dropdownList.slideDown();
    }

    toggleDropdown() {
        this.dropdownTrigger.toggleClass(ACTIVE_ITEM_CLASS);
        this.dropdownList.slideToggle();
    }

    initDropdown(itemCallback) {
        this.dropdownList.hide();

        this.dropdownTrigger.on('click', () => {
            this.toggleDropdown();
        });

        this.dropdownItem.on('click', (e) => {
            this.hideDropdown();
            $(e.target).parent().find(this.dropdownItem).removeClass(ACTIVE_ITEM_CLASS);
            $(e.target).addClass(ACTIVE_ITEM_CLASS);

            itemCallback($(e.target).text());
        });

        DOCUMENT.on('click', (e) => {
            if (!$(e.target).closest(this.dropdownWrapperClass).length) {
                this.hideDropdown();
            }
        });
    }

    initEvents() {
        this.initDropdown((value) => {
            this.input.val(value.trim());
        });

        this.input.on('change', () => {
            this.dropdownItem.removeClass(ACTIVE_ITEM_CLASS);
        });

        return this;
    }
}

module.exports = {
    SelectionProposal: SelectionProposal
};
