'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var debounce = require('lodash/debounce');
const TouchEvents = require('../shared/classes/swipe.class').TouchEvents;
const utils = require('../shared/helpers/utils');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

/**
 * Get welcome message according to date time
 * @returns {string} welcome message
 * */
function getWelcomeMessage() {
    var welcomeMsg;
    var currentTimeHours = new Date().getHours();
    if (currentTimeHours >= 6 && currentTimeHours < 12) {
        welcomeMsg = $('input[name=morning]').val();
    } else if (currentTimeHours >= 12 && currentTimeHours < 17) {
        welcomeMsg = $('input[name=day]').val();
    } else if (currentTimeHours >= 17 && currentTimeHours < 23) {
        welcomeMsg = $('input[name=evening]').val();
    } else {
        welcomeMsg = $('input[name=night]').val();
    }
    return welcomeMsg;
}

/**
 * Define padding to main wrapper based to header
 * @returns {void}
 * */
function stickyHeaderInit() {
    var padding = $('.header').outerHeight();
    var confirmationHeader = $('.header.checkout .navbar-header').outerHeight();
    $('#maincontent').css('paddingTop', padding || confirmationHeader);
    $('.header-banner').css('top', padding || confirmationHeader);
}

/**
 * Init BannerToggler class
 * @param {JQuery} btn button selector
 * @returns {void}
 * */
function BannerToggler(btn) {
    this.target = btn.data('target');
    this.btn = btn;

    this.initEvents();
}

/**
 * Close menu
 * @returns {void}
 * */
function closeMainMenu() {
    $('body').removeClass('lock-scroll');
    $('.main-menu').removeClass('in').attr('aria-hidden', 'true');
}

BannerToggler.prototype.toggle = function () {
    $(this.btn).toggleClass('collapsed');
    $(this.target).slideToggle();
};

BannerToggler.prototype.hide = function () {
    $(this.btn).addClass('collapsed');
    $(this.target).slideUp();
};

BannerToggler.prototype.show = function () {
    $(this.btn).removeClass('collapsed');
    $(this.target).slideDown();
};

BannerToggler.prototype.initEvents = function () {
    var self = this;
    this.btn.on('click', function () {
        self.toggle(this);
    });
};

module.exports = function () {
    var bannerWrapper = document.querySelectorAll('.header-banner_wrapper')[0];
    var timeout;
    var secondLevelTimeout;
    var lastLevelTimeout = [];

    if (bannerWrapper) {
        var Banner = new BannerToggler($('.header-banner_btn'));

        TouchEvents.build({
            swipeUpCallbeck: function () { Banner.hide(); },
            swipeDownCallbeck: function () { Banner.show(); },
            target: bannerWrapper
        });
    }

    stickyHeaderInit();

    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    $('.main-menu').on('click', function (e) {
        if (e.target && !e.target.closest('.js-menu-content')) {
            closeMainMenu();
        }
    });

    $('.js-menu-nav').on('mouseenter', function () {
        clearTimeout(timeout);
    });

    $('.nav-container')
        .on('mouseenter', function () {
            clearTimeout(timeout);
            $('.js-menu-nav').addClass('menu-nav--open').attr('aria-hidden', 'false');
        })
        .on('mouseleave', function (e) {
            if (e.target && !e.target.closest('.menu-dropdown')) {
                timeout = setTimeout(() => {
                    $('.js-menu-nav').removeClass('menu-nav--open').attr('aria-hidden', 'true');
                }, 600);
            }
        });

    $('.navbar-menu .navbar-menu__item')
        .on('mouseenter', function () {
            clearTimeout(timeout);
            secondLevelTimeout = setTimeout(()=> {
                $(this).find('.menu-dropdown').addClass('menu-dropdown--open').attr('aria-hidden', 'false');
            }, 600);
        })
        .on('mouseleave', function () {
            clearTimeout(secondLevelTimeout);
            lastLevelTimeout.push(setTimeout(()=> {
                $(this).find('.menu-dropdown').removeClass('menu-dropdown--open').attr('aria-hidden', 'true');
            }, 600));
        });

    $('.menu-dropdown')
        .on('mouseenter', function () {
            if (lastLevelTimeout) {
                lastLevelTimeout.forEach(t => clearTimeout(t));
                lastLevelTimeout.timeouts = [];
            }
        })
        .on('mouseleave', function () {
            timeout = setTimeout(() => {
                $('.js-menu-nav').removeClass('menu-nav--open').attr('aria-hidden', 'true');
            }, 600);
        });

    keyboardAccessibility(
        '.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.main-menu .close-button').on('click', function (e) {
        e.preventDefault();
        $('.main-menu').removeClass('in').attr('aria-hidden', 'true');
        $('body').removeClass('lock-scroll');
    });

    $('.js-enter-account').on('click', function (e) {
        e.preventDefault();
        $('.main-menu').removeClass('in').attr('aria-hidden', 'true');
        utils.modalIndex.set('.header.sticky');
        utils.modalIndex.set('#modalLogin');
        window.MODAL_COUNTER += 1;
    });

    $('.modal-header .close').on('click', function () {
        window.MODAL_COUNTER -= 1;
        if (window.MODAL_COUNTER === 0) {
            $('body').removeClass('lock-scroll');
        }
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();

        window.MODAL_COUNTER -= 1;
        if (window.MODAL_COUNTER === 0) {
            $('body').removeClass('lock-scroll');
        }
    });

    $('.navbar-toggler, .navbar-toggler-mobile').click(function (e) {
        e.preventDefault();

        if ($('.main-menu').hasClass('in')) {
            closeMainMenu();
        } else {
            $('body').addClass('lock-scroll');
            // Update welcome message
            var welcomeMsg = getWelcomeMessage();
            $('.js-welcome').text(welcomeMsg);
            var isUserAuthenticated = $('div.page').attr('data-user-authenticated');
            if (isUserAuthenticated === 'true') {
                var name = $('div.page').attr('data-user-name');
                $('.js-welcome-name').text(name);
            }
            $('.main-menu').addClass('in').attr('aria-hidden', 'false');
        }

        utils.modalIndex.set('.main-menu');
        window.MODAL_COUNTER += 1;
    });

    keyboardAccessibility(
        '.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });

    $('body').on('click', '#myaccount', function (event) {
        event.preventDefault();
    });

    $(window).on('resize', debounce(function () {
        stickyHeaderInit();
    }, 300));
};
