'use strict';

const checkGiftCert = function () {
    $('body').on('submit', '.check-gift-cert-js', function (e) {
        e.preventDefault();
        const $form = $(this);

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (res) {
                $form[0].reset();
                if (!res.error) {
                    $('.banner-valid-card').removeClass('d-none').addClass('d-lg-block');
                    $('.banner-header').removeClass('d-lg-block').addClass('d-none');
                    $('.banner-footer').removeClass('d-lg-block').addClass('d-none');
                    $('.gift-card-error').addClass('d-none');
                    $('.gift-value .value').html(res.amount);
                } else {
                    $('.banner-header').removeClass('d-none').addClass('d-lg-block');
                    $('.banner-footer').removeClass('d-none').addClass('d-lg-block');
                    $('.banner-valid-card').removeClass('d-lg-block').addClass('d-none');
                    $('.gift-card-error').removeClass('d-none');
                }
            },
            error: function (error) {
                console.log(error); // eslint-disable-line
            }
        });
    });
};

module.exports = {
    init: function () {
        checkGiftCert();
    }
};
