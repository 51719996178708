const clientValidation = require('../components/clientSideValidation');
var breakpoints = require('../utils/breakpoints');

const Selector = {
    saveToListButton: '.js-list-add-all',
    productsSlider: '.js-custom-products-slider',
    productTile: '.js-product-tile',
    selectionCheckbox: '.js-selection-checkbox',
    saveToListForm: '.save-multiproducts-to-list',
    listNameInput: '.js-list-name',
    addNewListButton: '.js-generate-new-list',
    totalAmountSpan: '.js-total-value',
    editorialPage: '.js-page',
    replaceProductBtn: '.js-replace-recipe-product',
    recipePage: '.js-recipe-page',
    replacementsContainer: '.js-product-replacement-modal',
    modal: '#replacementModal',
    modalContent: '.js-replacement-modal-content',
    saveRecipe: '.js-save-recipe',
    removeFromWishlist: '.js-remove-from-list',
    recipeTile: '.js-recipe-tile',
    recipeDashboardList: '.items-recipe-dashboard-list'
};

const Class = {
    addedToWishlist: 'added-to-wishlist'
};

const calculateSliderTotalAmout = () => {
    let $totalAmountSpan = $(Selector.totalAmountSpan);
    let productTiles = $(Selector.productsSlider).find(Selector.productTile);
    let productsPriceList = [];

    $(productTiles).each((i, tile) => {
        if (!$(tile).hasClass('product-tile--unavailable')) {
            let tilePrice = $(tile).data('price');
            productsPriceList.push(tilePrice);
        }
    });

    let totalAmount = 0;

    $(productsPriceList).each((i, el) => {
        let price = el.sales.value;
        totalAmount += price;
    });

    totalAmount = Math.round(totalAmount * 100) / 100;

    let currency = $(Selector.editorialPage).data('currency');

    $totalAmountSpan.empty().text(currency + totalAmount);
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

const productReplacement = (tile) => {
    let parent = $(tile).closest('.js-product-replacement-modal');
    let productToReplaceID = $(parent).data('uuid');
    let thisProductsSlider = $(tile).closest(Selector.productsSlider);
    let productToReplaceTile = $(thisProductsSlider).find(`.uuid-${productToReplaceID}`);

    let url = $(tile).data('url');
    let form = {
        pid: $(tile).data('pid')
    };

    $.ajax({
        url: url,
        data: form,
        type: 'get',
        dataType: 'json',
        success(data) {
            if (data.renderedTemplate) {
                let containerToFill = productToReplaceTile.parent();
                productToReplaceTile.remove();
                containerToFill.empty().append(data.renderedTemplate);
            }
            calculateSliderTotalAmout();

            if (document.body.clientWidth > breakpoints.lg) {
                let modal = $(tile).closest(Selector.modal);
                modal.find('.js-close-recipe-replacements').trigger('click');
            }
            $.spinner().stop();
            return true;
        }
    });
};

export const bindEvent = () => {
    if ($(Selector.productsSlider).length > 0) {
        calculateSliderTotalAmout();
    }

    $('body').on('click', Selector.saveToListButton, function () {
        $.spinner().start();

        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    $('.js-cart-list').empty().append(data.renderedTemplate);
                    $('.custom-select').selectmenu({
                        classes: {
                            'ui-selectmenu-menu': 'add-to-list',
                            'ui-selectmenu-button': 'add-to-list'
                        }
                    });
                    $('.custom-select').menu({
                        position: {
                            of: '#cartListModal'
                        }
                    });
                    $('#cartListModal').modal('show');
                } else {
                    if ($('#modalLogin').find('#action-common-error')) {
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                        $('#modalLogin').modal('show');
                    }
                    $('#modalLogin').on('hide.bs.modal', function () {
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                    });
                }
            }
        });
        $.spinner().stop();
        return true;
    });

    $('body').on('click', Selector.addNewListButton, function () {
        $.spinner().start();
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.multiProductsListTemplate) {
                    $('.js-cart-list').empty().append(data.multiProductsListTemplate);
                    clientValidation.invalid();
                }
            }
        });
        $.spinner().stop();
    });

    $('body').on('submit', Selector.saveToListForm, function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form = $(this);
        var url = $form.attr('action');
        clientValidation.functions.validateForm($form, e);

        var productsList = $(Selector.productsSlider).find(Selector.productTile);
        var pidsList = [];

        productsList.each((i, tile) => {
            var tileCheckbox = $(tile).find(Selector.selectionCheckbox);
            var tilePid = $(tileCheckbox).val();
            if ($(tileCheckbox).is(':checked') && !pidsList.includes(tilePid)) {
                pidsList.push(tilePid);
            }
        });

        var listNameSelect = $form.find(Selector.listNameInput);
        var listName = $(listNameSelect).val();

        pidsList = JSON.stringify(pidsList);

        var form = {
            pidsList,
            listName
        };

        if (pidsList && listName) {
            var crf = $('.csrfToken')[0];
            form[crf.name] = crf.value;

            $.ajax({
                url: url,
                type: 'post',
                data: form,
                success(data) {
                    if (data.errorMessage) {
                        $form.find('.invalid-feedback').text(data.errorMessage).show();
                    } else if (data.success === 'Ok') {
                        $('#cartListModal').modal('hide');
                    }
                },
                error() {
                    return false;
                }
            });
        }
        $.spinner().stop();
        return true;
    });

    $('body').on('submit', Selector.saveRecipe, function (e) {
        e.preventDefault();
        var $form = $(this);
        var $recipePage = $(Selector.recipePage);
        var $recipeTile = $form.closest(Selector.recipeTile);
        var url = $form.attr('action');
        clientValidation.functions.validateForm($form, e);
        var pidsList = [];
        var listName = $(e.currentTarget).find('.js-recipe-list-name').val();
        var recipeID = $(e.currentTarget).find('.js-recipe-id').val();

        // retrieve recipe ID from page if not available on button
        if (!recipeID) {
            recipeID = $(Selector.recipePage).attr('id');
        }

        pidsList.push(recipeID);
        pidsList = JSON.stringify(pidsList);

        var form = {
            pidsList,
            listName
        };

        if (pidsList && listName) {
            var crf = $('.csrfToken')[0];
            form[crf.name] = crf.value;

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                data: form,
                success(data) {
                    if (data.success === false) {
                        $form.find('.invalid-feedback').text(data.errorMessage).show();
                    } else if (data.isCustomerAuthenticated && data.success === 'Ok') {
                        if ($recipePage.length > 0) {
                            $recipePage.addClass(Class.addedToWishlist);
                        }

                        if ($recipeTile.length > 0) {
                            $recipeTile.addClass(Class.addedToWishlist);
                        }
                    } else if (!data.isCustomerAuthenticated) {
                        if ($('#modalLogin').find('#action-common-error')) {
                            $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                            $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                            $('input[type="email"]').val('');
                            $('input[type="password"]').val('');
                            $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                            $('#modalLogin').modal('show');
                        }
                        $('#modalLogin').on('hide.bs.modal', function () {
                            $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                            $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                            $('input[type="email"]').val('');
                            $('input[type="password"]').val('');
                            $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                        });
                    }
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                    return false;
                }
            });
        }
        return true;
    });

    $('body').on('click', Selector.replaceProductBtn, function () {
        $.spinner().start();
        var $this = $(this);
        var actionUrl = $this.data('url');
        $.ajax({
            url: actionUrl,
            method: 'GET',
            dataType: 'json',
            success(data) {
                if (data.productReplacements.length > 0) {
                    var replacementsContainer = $(Selector.recipePage).find(Selector.replacementsContainer).clone().removeClass('d-none');
                    var uuid = $this.parent().attr('data-pid');
                    var replacementElement = replacementsContainer.find('.product-tile-replacement');
                    var replacementCarousel = replacementsContainer.find('.js-replacement-carousel').empty();
                    if (document.body.clientWidth > breakpoints.lg) {
                        $(Selector.modal).modal('show');
                        $(Selector.modalContent).empty().html(replacementsContainer);
                    } else {
                        $this.closest(`div.pid-${uuid}`).addClass('d-none').after(replacementsContainer);
                    }
                    replacementsContainer.attr('data-uuid', uuid);
                    data.productReplacements.forEach((replacement) => {
                        var template = replacementElement.clone();
                        template.attr('data-pid', replacement.id);
                        if (replacement.image) {
                            template.find('.product-image img').attr('src', replacement.image.url);
                            template.find('.product-image img').attr('alt', replacement.image.alt);
                        }
                        $(template).attr('data-show-confirmation-legal-notice', replacement.showConfirmationLegalNotice);
                        template.find('.product-name').text(replacement.name);
                        template.find('.product-price .sales .value').text(replacement.price.sales.formatted);
                        if (replacement.price.list) {
                            template.find('.product-price .list .value').text(replacement.price.list.formatted);
                        } else {
                            template.find('.product-price .sales').removeClass('discounted-price');
                            template.find('.product-price .list').addClass('d-none');
                        }
                        template.find('.js-select-product-replacement').attr('id', replacement.id);
                        template.find('.js-select-product-replacement-label').attr('for', replacement.id);
                        replacementCarousel.append(template);
                    });

                    // on desktop wait for the modal to show to initialize the slider
                    if (document.body.clientWidth > breakpoints.lg) {
                        setTimeout(() => {
                            replacementsContainer.find('.js-replacement-carousel').attr('dir', 'rtl');
                            replacementsContainer.find('.js-replacement-carousel').not('.slick-initialized')
                                .slick({
                                    slidesToShow: 4,
                                    slidesToScroll: 1,
                                    infinite: false,
                                    arrows: true,
                                    rtl: true
                                });
                        }, 250);
                    } else {
                        replacementsContainer.find('.js-replacement-carousel').attr('dir', 'rtl');
                        replacementsContainer.find('.js-replacement-carousel').not('.slick-initialized')
                            .slick({
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                infinite: false,
                                arrows: true,
                                rtl: true
                            });
                    }
                }

                $().vEllipsis({
                    element: '.product-tile-replacement .product-name',
                    lines: 1,
                    onlyFullWords: false,
                    tolerance: 1
                });
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            }
        });
    });

    $('body').on('change', '.js-select-product-replacement', function () {
        var tile = $(this).closest('.product-tile-replacement');
        productReplacement(tile);
    });

    $('body').on('click', '.js-close-recipe-replacements', function () {
        if (document.body.clientWidth > breakpoints.lg) {
            let replacementsContainer = $(Selector.modal).find('.cart-product-replacement');
            replacementsContainer.remove();
        } else {
            let replacementsContainer = $(this).closest('.cart-product-replacement');
            let uuid = replacementsContainer.attr('data-uuid');
            replacementsContainer.siblings(`.uuid-${uuid}`).removeClass('d-none');
            replacementsContainer.remove();
        }
    });

    $(Selector.recipePage).on('click', '.js-close-recipe-replacements', () => {
        if ($(Selector.modal).hasClass('show')) {
            $(Selector.modal).modal('hide');
        }
    });

    $(Selector.modal).on('hidden.bs.modal', () => {
        $('.js-close-recipe-replacements').trigger('click');
    });

    $('body').on('click', Selector.removeFromWishlist, (e) => {
        e.preventDefault();
        let $button = $(e.currentTarget);
        let $recipePage = $(Selector.recipePage);
        let $recipeDashboardList = $(Selector.recipeDashboardList);
        let $recipeTile = $button.closest(Selector.recipeTile);
        let $tileWrapper = $recipeTile.closest('.js-recipe-tile-wrapper');
        let url = $button.data('action');

        var recipeID = $button.data('item');

        // retrieve recipe ID from page if not available on button
        if (!recipeID) {
            recipeID = $(Selector.recipePage).attr('id');
        }

        let params = {
            listID: $button.data('list'),
            itemID: recipeID
        };

        url = appendToUrl(url, params);

        $.spinner().start();

        $.ajax({
            method: 'POST',
            url: url,
            data: params,
            success() {
                if ($recipePage.length > 0) {
                    $recipePage.removeClass(Class.addedToWishlist);
                }
                if ($recipeTile.length > 0) {
                    $recipeTile.removeClass(Class.addedToWishlist);
                }
                if ($tileWrapper.length > 0) {
                    $tileWrapper.remove();
                }
                if ($recipeDashboardList.children().length === 0) {
                    $recipeDashboardList.append('<p class="text-center mx-auto my-5">לא נשמרו מתכונים, למה אתם מחכים?</p>');
                }
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            }
        });
    });
};
