const utils = require('../helpers/utils');

const PREFIX = 'custom';
const ITEM_CLASS = 'dropdown-item';
const LIST_CLASS = 'dropdown-wrapper';

class Dropdown {
    static build() {
        return new Dropdown()
            .initEvents();
    }

    setId(id) {
        this.id = id;
        return this;
    }

    setSelectItemCallback(selectItemCallback) {
        this.selectItemCallback = selectItemCallback;
        return this;
    }

    renderList(list, attrs = false) {
        const listContent = list.reduce((accumulator, item) => {
            if (!attrs) {
                return accumulator + `<li class='${PREFIX}-${ITEM_CLASS}'>${item}</li>`;
            }
            return accumulator + `<li class='${PREFIX}-${ITEM_CLASS}' data-place-id="${item.place_id}">${item.label}</li>`;
        }, '');
        const listWrapper = document.createElement('ul');
        listWrapper.setAttribute('class', `${PREFIX}-${LIST_CLASS}`);
        listWrapper.setAttribute('id', this.id);
        listWrapper.innerHTML = listContent;
        return listContent ? listWrapper : '';
    }

    selectItem(target) {
        if (!this.selectItemCallback) return;
        const value = target.innerText;
        this.selectItemCallback(value, target);
    }

    initEvents() {
        const body = document.querySelectorAll('body')[0];
        body.addEventListener('click', (e) => {
            const itemSelector = `${PREFIX}-${ITEM_CLASS}`;
            const hasSomeParentTheClass = utils.hasSomeParentTheClass(e.target, itemSelector);

            if (e.target.classList.contains(itemSelector) || hasSomeParentTheClass) {
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, itemSelector);
                    if (!target) return;
                }
                e.preventDefault();
                this.selectItem(target);
            }
        });

        return this;
    }
}

module.exports = {
    Dropdown: Dropdown
};
