var details = require('*/../../project/cartridges/app_sunnie_foods/cartridge/client/default/js/product/details');
var base = require('./base');
var cartHelper = require('../cart/cart');
const utils = require('../shared/helpers/utils');
const { checkStore } = require('../shared/helpers/storeHelpers');

window.MODAL_COUNTER = 0;

const LOGOUT_BUTTON_SELECTOR = '.logout-button';
const isPhone = utils.resolution.isPhone;
const Selector = {
    minicart: '#minicart'
};
const Class = {
    cartFreeShipping: 'cart--freeshipping'
};

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.add-to-cart').data('pid');
}

/**
 * Retrieves the relevant uuid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getUuidValue($el) {
    return $($el).closest('.add-to-cart').data('uuid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-update-quantity');
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    if (response && response.items) {
        let product;
        const pid = el.closest('.add-to-cart').data('pid').toString();
        const products = response.items.filter(item => item.id.toString() === pid && !item.isBonusProductLineItem);
        if (products && products.length > 0) {
            product = products.pop();
            if (product.secondaryMeasurementUnit === null && product.unitConversion) {
                var weight = product.quantity * product.unitConversion;
                $(`.add-to-cart[data-uuid="${product.uuid}"]`).find('.update-quantity .quantity').html(parseFloat(Number(weight).toFixed(2)));
            } else {
                $(`.add-to-cart[data-uuid="${product.uuid}"]`).find('.update-quantity .quantity').html(product.quantity);
            }
            $(`.add-to-cart[data-pid=${pid}]`).attr('data-quantity', product.quantity);
        }
        // Update free shipping message
        $('#freeShippingMessage').empty();
        if (response.freeShippingMessage) {
            $('#freeShippingMessage').append(response.freeShippingMessage);
        }
        if (response.isPromoTriggered) {
            $('#freeShippingMessage').addClass('triggeredFree');
            if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                $(Selector.minicart).addClass(Class.cartFreeShipping);
            }
        } else {
            $('#freeShippingMessage').removeClass('triggeredFree');
            if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                $(Selector.minicart).addClass(Class.cartFreeShipping);
            }
        }
    }
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="'}${data.maxBonusItems}"` +
        `data-UUID="${data.uuid}"` +
        `data-pliUUID="${data.pliUUID}"` +
        `data-addToCartUrl="${data.addToCartUrl}"` +
        'data-pageStart="0"' +
        `data-pageSize="${data.pageSize}"` +
        `data-moreURL="${data.showProductsUrlRuleBased}"` +
        `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">` +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `    <span class="">${data.labels.selectprods}</span>` +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @param {string} secondaryMeasurementUnit - secondaryMeasurementUnit
 * @param {string} unitConversion - unitConversion
 * @return {undefined}
 */
function updateAddToCart(quantity, selector, secondaryMeasurementUnit, unitConversion) {
    if (secondaryMeasurementUnit) {
        $(selector).empty().html(parseFloat(Number(quantity * unitConversion).toFixed(2)));
        $(selector).closest('.add-to-cart').attr('data-secondary-measurement-unit', secondaryMeasurementUnit);
        $(selector).closest('.add-to-cart').attr('data-unit-conversion', unitConversion);
    } else {
        $(selector).empty().html(quantity);
    }
    if (quantity && quantity > 0) {
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').removeClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').addClass('show');
    } else {
        $(selector).empty().html(1);
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').removeClass('show');
    }
}

/**
 * Update PDP add to cart button after minicart update
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    const items = $('.cart-json').data('cart');
    const pid = $('.product-detail').attr('data-pid');
    let quantity = 0;
    var secondaryMeasurementUnit;
    var unitConversion;
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            if (item.id === pid) {
                quantity = item.qty;
                secondaryMeasurementUnit = item.secondaryMeasurementUnit;
                unitConversion = item.unitConversion;
            }
        });
    }
    updateAddToCart(quantity, `.detail-itemquantity-${pid} .quantity`, secondaryMeasurementUnit, unitConversion);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    $('.minicart').trigger('count:update', response);
    if (response && response.cart) {
        if (response.cart.items) {
            const pid = el.closest('.add-to-cart').data('pid');
            const products = response.cart.items.filter(item => item.id === pid);
            if (products && products.length > 0) {
                el.closest('.add-to-cart').find('.update-quantity').addClass('show');
                el.removeClass('show');
            }
        }

        // Update free shipping message
        $('#freeShippingMessage').empty();
        if (response.cart.freeShippingMessage) {
            $('#freeShippingMessage').append(response.cart.freeShippingMessage);
        }
        if (response.cart.isPromoTriggered) {
            $('#freeShippingMessage').addClass('triggeredFree');
            $(Selector.minicart).addClass(Class.cartFreeShipping);
        } else {
            $('#freeShippingMessage').removeClass('triggeredFree');
            $(Selector.minicart).removeClass(Class.cartFreeShipping);
        }
    }

    const messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('.js-minicart-container').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(`<div class="alert ${messageType} add-to-basket-alert text-right" role="alert">${
            response.message
        }</div>`);

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Save legal age confirmation for authenticated customer
* @param {string} url - endpoint url
 */
function saveAgeConfirmation(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // age confirmation saved in customer profile
            }
        });
    }
}

/**
 * info animation function
 * @param {Object} element - JQuery object
 * @param {number} delayTime - time for animation hide delay
 * @param {number} animationTime - animation timer
 *
 */
function showQuickInfoAnimation(element, delayTime, animationTime) {
    $(element).fadeIn(animationTime, () => {
        setTimeout(() => {
            $(element).fadeOut(animationTime);
        }, delayTime);
    });
}

/**
 * Continue to add product to cart after conditions are met
 * @param {*} el - Add to cart button
 * @param {string} confirmAgeUrl - endpoint url for saving legal age confirmation in customer profile (optional)
 *
 */
function continueAddToCart(el, confirmAgeUrl) {
    $('body').trigger('product:beforeAddToCart', el);
    showQuickInfoAnimation($('.add-item-confirmation-text'), 2000, 500);
    $('.details-add-to-cart').prop('disabled', false);

    const pid = getPidValue($(el));
    var uuid = getUuidValue($(el));
    const addToCartUrl = getAddToCartUrl(el);
    var selectedMeasurementUnit = $('#quickViewModal .measurement-unit-slider-input');
    var secondaryMeasurementUnit = $(`#quickViewModal #secondaryUnitMeasure-${pid}`);
    var quantity = selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary' ? 0.5 : $(secondaryMeasurementUnit).data('unitconversion');
    var pidsObj;
    var setPids;
    var setItems = $('.set-items');

    if (setItems.length) {
        setPids = [];
        $('.set-item').each(function () {
            var currentCheckbox = $(this).find('input[type="checkbox"]')[0];
            if (currentCheckbox.checked) {
                setPids.push({
                    pid: currentCheckbox.id,
                    qty: parseFloat($(this).find('.quantity').attr('data-quantity'))
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
    }

    const form = {
        pid,
        pidsObj,
        uuid,
        quantity: quantity
    };

    if (selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary') {
        secondaryMeasurementUnit = $(`#quickViewModal #secondaryUnitMeasure-${pid}`);
        form.secondaryMeasurementUnit = $(secondaryMeasurementUnit).data('unitlaw');
        form.unitConversion = $(secondaryMeasurementUnit).data('unitconversion');
    } else if (selectedMeasurementUnit.length > 0) {
        var primaryMeasurementUnit = $(`#quickViewModal #primaryUnitMeasure-${pid}`);
        form.unitConversion = $(primaryMeasurementUnit).data('unitconversion');
    }

    checkStore().then(({ noStore }) => {
        if (addToCartUrl && !noStore) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success(data) {
                    base.updateMinicartView(function () {}, true);
                    handlePostCartAdd(data, el);
                    if (typeof cartHelper === 'object') {
                        cartHelper.updateCartTotals(data.cart);
                    }
                    if (data.renderedQuantityTemplate) {
                        $('.product-add-to-cart-first-button').hide();
                        $('.product-add-to-cart-quantity-selector').append(data.renderedQuantityTemplate);
                        $('.sticky-panel input.measurement-unit-slider-input').closest('div').addClass('d-none');
                    }
                    // Update product tiles on page for this product
                    $(`input.measurement-unit-slider-input-${pid}`).each(function () {
                        if (secondaryMeasurementUnit && selectedMeasurementUnit.val() === 'secondary') {
                            $(this).prop('checked', true);
                            $(this).val('secondary');
                        } else {
                            $(this).prop('checked', false);
                            $(this).val('primary');
                        }
                    });
                    // Hide legal notice confirmation if present
                    $('.js-confirmationLegalNotice').addClass('d-none');
                    $('.js-product-main').removeClass('d-none');
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                    if (confirmAgeUrl) {
                        saveAgeConfirmation(confirmAgeUrl);
                    }
                },
                error() {
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * remove user session data
 *
 */
function removeUserSessionData() {
    window.sessionStorage.removeItem('hasShowedConfirmationLegalNotice');
}

/**
 * remove user local store data
 *
 */
function removeUserLocalStoreData() {
    localStorage.removeItem('storeSelected');
    localStorage.removeItem('userSelected');
    localStorage.removeItem('transactionShown');
}

/**
 * Add to cart
 *
 */
function addToCart() {
    $(document).on('click', 'button.details-add-to-cart', function () {
        const el = $(this);
        var showConfirmationLegalNotice = $(this).data('showconfirmationlegalnotice');
        var isAlcoholicProduct = $(this).data('isalcoholicproduct');
        var isAlcoholRestrictedTime = $(this).data('isalcoholrestrictedtime');
        var hasShowedConfirmationLegalNotice = window.sessionStorage.getItem('hasShowedConfirmationLegalNotice');
        var disableAddToCart = window.sessionStorage.getItem('disableAddToCart');
        var popupContent = $('.popup-temporary-container').html();
        var $quickViewWrapper = $('.js-product-main');
        var $minicartViewWrapper = $('.js-minicart-container');

        $('.details-add-to-cart').prop('disabled', true);
        if (isAlcoholRestrictedTime) {
            $('#alcoholRestrictionModal').modal('show');
        } else if (showConfirmationLegalNotice && !JSON.parse(hasShowedConfirmationLegalNotice)) {
            $('.js-confirmationLegalNotice').removeClass('d-none');
            $quickViewWrapper.addClass('d-none');
            $('#quickViewModal').scrollTop(0);

            $('.under-age').on('click', function () {
                $('button.details-add-to-cart').css('pointer-events', 'none');
            });
            const $confirmAgeBtn = $('.confirm-age');
            if ($confirmAgeBtn.length > 0) {
                $confirmAgeBtn.on('click', function () {
                    window.sessionStorage.setItem('hasShowedConfirmationLegalNotice', true);
                    if (localStorage.getItem('storeSelected') === 'true' && !localStorage.getItem('userSelected') && !localStorage.getItem('transactionShown')) {
                        if (isPhone) {
                            $minicartViewWrapper.append(popupContent);
                            $('.js-minicart-container .transaction-popup-wrapper').show();
                        } else {
                            $quickViewWrapper.append(popupContent);
                            $('.js-product-main .transaction-popup-wrapper').show();
                        }
                        localStorage.setItem('transactionShown', 'true');
                    }
                    continueAddToCart(el, $confirmAgeBtn.data('url'));
                });
            }

            $('.js-confirmationLegalNotice .close-button').on('click', function () {
                $('.js-confirmationLegalNotice').addClass('d-none');
                $('.js-product-main').removeClass('d-none');
            });
        } else if (isAlcoholicProduct && JSON.parse(disableAddToCart)) {
            $(this).css('pointer-events', 'none');
        } else {
            if (localStorage.getItem('storeSelected') === 'true' && !localStorage.getItem('userSelected') && !localStorage.getItem('transactionShown')) {
                if (isPhone) {
                    $minicartViewWrapper.append(popupContent);
                    $('.js-minicart-container .transaction-popup-wrapper').show();
                } else {
                    $quickViewWrapper.append(popupContent);
                    $('.js-product-main .transaction-popup-wrapper').show();
                    localStorage.setItem('transactionShown', 'true');
                }
                localStorage.setItem('transactionShown', 'true');
            }
            continueAddToCart(el);
        }
    });
}

/**
 * Update quantity
 *
 */
function updateQuantityCart() {
    $(document).on('click', 'button.detail-update-quantity', function () {
        const el = $(this);

        $('body').trigger('product:beforeAddToCart', this);

        const pid = getPidValue($(this));
        var uuid = getUuidValue($(el));
        const updateQuantityUrl = getUpdateQuantityUrl($(this));
        let decrease = $(this).data('decrease');
        if (!decrease) {
            decrease = false;
        }
        var selectedMeasurementUnit = $('#quickViewModal .measurement-unit-slider-input');
        var secondaryMeasurementUnit = $(`#quickViewModal #secondaryUnitMeasure-${pid}`);
        var updateQuantityStep = 1;

        if (selectedMeasurementUnit.length > 0) {
            updateQuantityStep = selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary' ? 0.5 : $(secondaryMeasurementUnit).data('unitconversion');
        }

        var currentQuantity = parseFloat($(this).closest('.add-to-cart').attr('data-quantity'));
        var targetQuantity = decrease ? currentQuantity - updateQuantityStep : currentQuantity + updateQuantityStep;
        targetQuantity = Number(targetQuantity.toFixed(2));
        if (targetQuantity <= 0) {
            $('.product-add-to-cart-first-button').show();
            $('.product-add-to-cart-quantity-selector').empty();
            $('.cart-delete-confirmation-btn[data-pid=' + pid + ']').trigger('click');
            $('.add-to-cart[data-pid=' + pid + ']').attr('data-quantity', targetQuantity);
            $.spinner().stop();
        }

        let $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }
        const form = {
            pid,
            uuid,
            decrease,
            quantity: targetQuantity
        };

        if (selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary') {
            secondaryMeasurementUnit = $(`#quickViewModal #secondaryUnitMeasure-${pid}`);
            form.secondaryMeasurementUnit = $(secondaryMeasurementUnit).data('unitlaw');
            form.unitConversion = $(secondaryMeasurementUnit).data('unitconversion');
        } else if (selectedMeasurementUnit.length > 0) {
            var primaryMeasurementUnit = $(`#quickViewModal #primaryUnitMeasure-${pid}`);
            form.unitConversion = $(primaryMeasurementUnit).data('unitconversion');
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (updateQuantityUrl) {
            $.ajax({
                url: updateQuantityUrl,
                method: 'GET',
                data: form,
                success(data) {
                    base.updateMinicartView(function () {}, decrease);
                    el.closest('.add-to-cart').attr('data-quantity', targetQuantity);
                    if (data && data.basket && data.basket.items) {
                        const id = el.closest('.add-to-cart').data('pid').toString();
                        const products = data.basket.items.filter(item => item.id.toString() === id);
                        if (products.length === 0) {
                            el.closest('.add-to-cart').find('.update-quantity').removeClass('show');
                            el.closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
                        }
                    } else if (data && data.items) {
                        updateQuantity(data, el);
                        if (typeof cartHelper === 'object') {
                            cartHelper.updateCartTotals(data);
                        }
                    }
                    // Update product tiles on page for this product
                    $(`input.measurement-unit-slider-input-${pid}`).each(function () {
                        if (secondaryMeasurementUnit && selectedMeasurementUnit.val() === 'secondary') {
                            $(this).prop('checked', true);
                            $(this).val('secondary');
                        } else {
                            $(this).prop('checked', false);
                            $(this).val('primary');
                        }
                    });
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                },
                error() {
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * Update minicart
 *
 */
function updateMinicart() {
    $('body').on('cart:update', () => {
        updateQuantities();
    });
}

$(document).on('click', LOGOUT_BUTTON_SELECTOR, () => {
    removeUserSessionData();
    removeUserLocalStoreData();
});

$(document.body).on('click', '.js-item-checkbox', function () {
    var $this = $(this);
    var $productCont = $this.closest('.product-quickview.product-set');
    var $productPriceCont = $productCont.find('.js-quickview-prices');

    /** Price calculation functional */
    var currency = $productPriceCont.data('currency');
    var listPrice = Number(parseFloat($productPriceCont.data('listPrice')).toFixed(2));
    var salesPrice = Number(parseFloat($productPriceCont.data('salesPrice')).toFixed(2));

    var itemQuantity = Number(parseInt($this.data('quantity'), 10));
    var itemSalePrice = Number(parseFloat($this.data('salesPrice')).toFixed(2));
    var itemListPrice = Number(parseFloat($this.data('listPrice')).toFixed(2));

    var addition = $this[0].checked;
    var $priceCont = $productCont.find('.prices .product-set-price span');

    if (addition) {
        listPrice = Number((listPrice + (itemListPrice * itemQuantity)).toFixed(2));
        salesPrice = Number((salesPrice + (itemSalePrice * itemQuantity)).toFixed(2));
    } else {
        listPrice = Number((listPrice - (itemListPrice * itemQuantity)).toFixed(2));
        salesPrice = Number((salesPrice - (itemSalePrice * itemQuantity)).toFixed(2));
    }

    $productPriceCont.data('salesPrice', salesPrice);
    $productPriceCont.data('listPrice', listPrice);
    if (salesPrice === listPrice) {
        $($priceCont).empty().append(salesPrice.toLocaleString('iw-IL', { style: 'currency', currency: currency }));
        $priceCont.find('.discounted-price').empty();
    } else {
        $($priceCont).empty();
        if (currency) {
            $($priceCont).append('<span class=\'discounted-price sales\'>' + salesPrice.toLocaleString('iw-IL', { style: 'currency', currency: currency }) + ' </span><span class=\'strike-through list\'>' + listPrice.toLocaleString('iw-IL', { style: 'currency', currency: currency }) + '</span>');
        }
    }
    /* Price calculation functional */

    /** blocking the addition of an empty product */
    var isBlockButton = true;
    var $allProductsChekboxes = $productCont.find('.js-item-checkbox');
    var $addButtons = $productCont.find('.details-add-to-cart');
    /* eslint-disable consistent-return */
    $allProductsChekboxes.each(function () {
        if (this.checked) {
            isBlockButton = false;
            return false;
        }
    });
    /* eslint-enable consistent-return */
    $addButtons.prop('disabled', isBlockButton);
    /* blocking the addition of an empty product */
});

var productDetails = $.extend({}, details, { addToCart: addToCart, updateQuantityCart: updateQuantityCart, updateMinicart: updateMinicart });
module.exports = productDetails;
