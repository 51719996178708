const RequestService = require('../shared/services/request.service').RequestService;
const Modal = require('../shared/classes/modal.class').Modal;
const OperationComponent = require('../components/operation.component').OperationComponent;
const RemoveComponent = require('../components/remove.component').RemoveComponent;
const utils = require('../shared/helpers/utils');
const base = require('../product/base');

// list details
const LIST_DETAILS_MODAL_BTN_CLASS = 'shoping-list-modal-btn';
// order details
const ORDER_SHOPING_WRAPPER_CLASS = 'order-products-list-wrapper';
const ORDER_SHOPING_BUTTON_CLASS = 'order-products-list-btn';
const ORDER_SHOPING_PRODUCT_NAME_CLASS = 'order-details-tile-name-value';
const ORDER_SHOPING_ITEM_HEADER_TITLE_CLASS = 'order-shoping-item-header-title';
// operation component
const OPERATION_WRAPPER_CLASS = 'order-shoping-tile-quantity';
const OPERATION_BUTTON_CLASS = 'tile-operation-button';
const OPERATION_VALUE_CLASS = 'tile-total-value';
// remove component
const TILE_WRAPPER_CLASS = 'order-details-tile-wrapper';
const REMOVE_BUTTON_CLASS = 'product-tile-remove-item';
// remove list
const REMOVE_LIST_BUTTON_CLASS = 'order-products-list-remove-btn';
const TILE_LIST_CLASS = 'order-tile-wrapper';
const TILES_LIST_WRAPPER_CLASS = 'order-tile-list-product-set';
const TILES_LIST_LAST_WRAPPER_CLASS = 'shoping-list-tiles-wrapper';
const SAVE_TO_LIST_LINK_CLASS = 'save-to-list';
const MUTED_TEXT_CLASS_NAME = 'text-muted';
// remove confirmation modal
const REMOVE_CONFIRMATION_MODAL_CLASS = 'remove-confirmation-modal';
// remove shoping list modal
const REMOVE_SHOPING_LIST_MODAL_OK_BUTTON_CLASS = 'order-details-shoping-messages-remove-btn-ok';
const REMOVE_SHOPING_LIST_MODAL_CANCEL_BUTTON_CLASS = 'order-details-shoping-messages-remove-btn-cancel';
const REMOVE_SHOPING_LIST_MODAL_CONTENT_CLASS = 'order-details-shoping-messages-remove';
// add list's products to cart
const ADD_LIST_CONFIRMATION_MODAL_CLASS = 'add-list-confirmation-modal';
const ADD_LIST_MODAL_OK_BUTTON_CLASS = 'shoping-list-add-cart-btn-ok';
const ADD_LIST_MODAL_CONTENT_CLASS = 'shoping-list-add-cart-messages';
const ADD_LIST_MODAL_TOTAL_CLASS = 'dashboard-list-total';
// store locator modal
const CHANGE_STORE_EMPTY_CART_CLASS = 'js-changeStoreBody-emptycart';
const CHANGE_STORE_BODY_CLASS = 'js-changeStoreBody';
const CHANGE_STORE_ID = 'changeStore';
const ADDRESS_AUTOCOMPLETE_ID = 'address-autocomplete';
const DELIVERY_MODAL_ID = 'deliveryModal';
const ORDER_SHOPPING_ITEM_HEADER_SUBNAME_CLASS = 'order-shoping-item-header-subname';
const ORDER_TILE_PRODUCT_SET_TITLE_CLASS = 'order-tile-product-set-title';
const ORDER_TILE_PRODUCT_SET_QUANTITY_CLASS = 'order-tile-product-set-quiantity';
const ORDER_ITEM_WRAPPER = 'order-shoping-item-wrapper';
const DISABLED_CLASS = 'disabled';
const SLICK_SLIDER_INITED_CLASS = 'slick-initialized';
const CART_OPEN_CLASS = 'js-cart-icon';
const ORDER_DETAILS_SHOPING_CATEGORY_TITLE = 'order-details-shoping-category-title';

// utils helper
const isPhone = utils.resolution.isPhone;

/**
 * confirmation modal
 * @param {Modal} modal - instance of modal class
 * @param {function} buttonHandler - callback after button pressing
 * @param {string} content - string with html content
 * @param {string} additionalClass - addition class for confirmation window
 */
function confirmationModal(modal, buttonHandler, content, additionalClass) {
    modal.setButtonsHandler({
        okButtonHandler: () => {
            $(`#${modal.id}`).modal('hide');
        },
        cancelButtonHandler: () => {
            buttonHandler(() => {
                $(`#${modal.id}`).modal('hide');
            });
        }
    });
    modal.setContent(content, additionalClass);
    modal.overlapModals();
    $(`#${modal.id}`).modal('show');

    $(`#${modal.id}`).on('hidden.bs.modal', function () {
        modal.unoverlapModals();
        modal.destroyWrapper(modal.id);
    });
}

/**
 * Open minicart trigger
 */
function openMinicart() {
    document.querySelectorAll(`.${CART_OPEN_CLASS}`)[0].click();
}

/**
 * Open store locator modal
 */
function openStoreLocator() {
    $(`.${CHANGE_STORE_EMPTY_CART_CLASS}`).addClass('d-none');
    $(`.${CHANGE_STORE_BODY_CLASS}`).removeClass('d-none');
    $(`#${CHANGE_STORE_ID}`).addClass('d-none');
    $(`#${ADDRESS_AUTOCOMPLETE_ID}`).val('');
    $(`#${DELIVERY_MODAL_ID}`).modal('show');
}

/**
 * Add shoping list to cart
 * @param {string} url - url for add requst
 * @param {Object} context - modal instances
 * @param {Object} addProductsToCartModal - instance of modal class
 */
function addProductsToCart(url, context, addProductsToCartModal) {
    if (!url) return;
    const contents = document.querySelectorAll(`.${ADD_LIST_MODAL_CONTENT_CLASS}`);
    const content = contents[contents.length - 1].innerHTML;
    confirmationModal(addProductsToCartModal, () => {
        RequestService.setAddToCartUrl(url);
        RequestService.getAddToCard(null, (res) => {
            const resJson = JSON.parse(res);
            if (resJson.success === 'OK') {
                if (resJson.noStore) {
                    openStoreLocator();
                } else {
                    if (isPhone) {
                        openMinicart();
                    }
                    base.updateMinicartView();
                }
                if (context) {
                    $(`#${context.id}`).modal('hide');
                }
            }
        });
    }, content, ADD_LIST_CONFIRMATION_MODAL_CLASS);
}

/**
 * Hover event
 */
function hoverProductName() {
    $().vEllipsis({
        element: `.${ORDER_SHOPING_ITEM_HEADER_TITLE_CLASS}`,
        lines: 1,
        onlyFullWords: false,
        responsive: true,
        tolerance: 5,
        callback: function () {
            $(this).on('mouseenter', function () {
                $(this).siblings('.name-on-hover').removeClass('d-none');
            }).on('mouseleave', function () {
                $(this).siblings('.name-on-hover').addClass('d-none');
            });
        }
    });

    $().vEllipsis({
        element: `.${ORDER_SHOPING_PRODUCT_NAME_CLASS}`,
        lines: 2,
        onlyFullWords: false,
        responsive: true,
        tolerance: 5,
        callback: function () {
            $(this).on('mouseenter', function () {
                $(this).siblings('.name-on-hover').removeClass('d-none');
            }).on('mouseleave', function () {
                $(this).siblings('.name-on-hover').addClass('d-none');
            });
        }
    });
}

/**
 * Get shoping list details data
 * @param {string} url - order details url
 * @param {Object} context - modal instances
 */
function showProductsList(url, context) {
    if (!url) return;
    RequestService.setProductsListUrl(url);
    RequestService.getProductsList(null, (res) => {
        context.setContent(res, ORDER_SHOPING_WRAPPER_CLASS);
        $(`#${context.id}`).modal('show');
        if (parseFloat($(`.${ORDER_ITEM_WRAPPER}`).last().data('items-count')) <= 0) {
            $(`.${ORDER_SHOPING_WRAPPER_CLASS} button.btn-primary`).attr('disabled', 'disabled').addClass(DISABLED_CLASS);
        }

        $(window).on('shown.bs.modal', () => {
            hoverProductName();
        });
    });
}

/**
 * Update shoping list item
 * @param {string} url - update shoping list url
 * @param {Object} data - request params
 * @param {Function} callback - response callbeck
 */
function updateProductListItem(url, data, callback) {
    if (!url) return;
    RequestService.setUpdateProductListItemUrl(url);
    RequestService.updateProductListItem(null, res => {
        callback(res);
    }, data);
}

/**
 * Remove shoping list item
 * @param {string} url - Remove shoping list item url
 * @param {Object} data - request params
 * @param {Function} callbeck - response callbeck
 */
function removeProductListItem(url, data, callbeck) {
    if (!url) return;
    RequestService.setRemoveProductListItemUrl(url);
    RequestService.removeProductListItem(data, res => {
        callbeck(res);
    }, data);
}

/**
 * Remove tile trigger
 * @param {Object} target - clicked btn
 * @param {boolean} islastList - flag if delted list is last
 * @param {string} template - Template to render if last list
 */
function removeProductListTile(target, islastList, template) {
    if ($(`.${TILES_LIST_WRAPPER_CLASS}`).hasClass(SLICK_SLIDER_INITED_CLASS)) {
        $(`.${TILES_LIST_WRAPPER_CLASS}`).slick('slickRemove', target.dataset.slickIndex);
    }
    if (islastList) {
        $(`.${TILES_LIST_LAST_WRAPPER_CLASS}`).replaceWith(template);
        if ($('.empty-cart').is(':visible')) {
            $('body').trigger('productList:addClassTextMuted');
        } else {
            $('body').trigger('productList:removeClassTextMuted');
        }
    }
}

/**
 * Remove shoping list
 * @param {string} url - Remove shoping list url
 * @param {Object} target - clicked btn
 * @param {Modal} removeListItemModal - instance of modal class
 */
function removeProductList(url, target, removeListItemModal) {
    if (!url) return;
    const content = document.querySelectorAll(`.${REMOVE_SHOPING_LIST_MODAL_CONTENT_CLASS}`)[0].innerHTML;
    confirmationModal(removeListItemModal, () => {
        RequestService.setAddToCartUrl(url);
        RequestService.getAddToCard(null, (res) => {
            const isLastList = JSON.parse(res).isLastList;
            const template = JSON.parse(res).renderedTemplate;
            const wrapper = utils.getParentWithClass(target, TILE_LIST_CLASS);
            removeProductListTile(wrapper, isLastList, template);
        });
    }, content, REMOVE_CONFIRMATION_MODAL_CLASS);
}

/**
 * Remove shoping list
 */
function toggleClassTextMuted() {
    $('body').on('productList:addClassTextMuted', function () {
        $(`.${SAVE_TO_LIST_LINK_CLASS}`).addClass(MUTED_TEXT_CLASS_NAME);
    });
    $('body').on('productList:removeClassTextMuted', function () {
        $(`.${SAVE_TO_LIST_LINK_CLASS}`).removeClass(MUTED_TEXT_CLASS_NAME);
    });
}

/**
 * Remove shoping list modal
 * @returns {Object} instance of Modal class
 */
function initRemoveListModal() {
    const removeListOkBtn = document.querySelectorAll(`.${REMOVE_SHOPING_LIST_MODAL_OK_BUTTON_CLASS}`)[0];
    const removeListCancelBtn = document.querySelectorAll(`.${REMOVE_SHOPING_LIST_MODAL_CANCEL_BUTTON_CLASS}`)[0];
    if (removeListOkBtn && removeListCancelBtn) {
        return Modal.build({
            openButtonClass: null,
            modalConfigurations: {
                okButtonText: removeListOkBtn.innerHTML,
                cancelButtonText: removeListCancelBtn.innerHTML
            }
        });
    }
    return null;
}

module.exports = {
    init: function () {
        let addProductsToCartModal;
        const addProductsToCartModalOkBtn = document.querySelectorAll(`.${ADD_LIST_MODAL_OK_BUTTON_CLASS}`)[0];
        if (addProductsToCartModalOkBtn) {
            addProductsToCartModal = Modal.build({
                openButtonClass: null,
                modalConfigurations: {
                    cancelButtonText: addProductsToCartModalOkBtn.innerHTML
                }
            });
        }
        $('body').on('click', `.${REMOVE_LIST_BUTTON_CLASS}`, (e) => {
            e.preventDefault();
            const url = $(e.currentTarget).attr('href');
            const removeListItemModal = initRemoveListModal();
            removeProductList(url, e.target, removeListItemModal);
        });
        RemoveComponent.build({
            wrapper: TILE_WRAPPER_CLASS,
            button: REMOVE_BUTTON_CLASS,
            removeCallbeck: (target, context) => {
                const tile = utils.getParentWithClass(target, TILE_WRAPPER_CLASS);
                const url = target.dataset.url;
                removeProductListItem(url, null, res => {
                    context.removeWrapper(target);
                    var data = JSON.parse(res);
                    if (data.listName) {
                        $(`.${ORDER_TILE_PRODUCT_SET_TITLE_CLASS}[title=${data.listName}]`).parent().find(`.${ORDER_TILE_PRODUCT_SET_QUANTITY_CLASS}`).html(data.itemsCount);
                        $(`.${ORDER_SHOPPING_ITEM_HEADER_SUBNAME_CLASS}`).html(data.itemsCount);
                        if (data.checkLastItem === 0) {
                            $(`.${ORDER_DETAILS_SHOPING_CATEGORY_TITLE}`).empty();
                        } else {
                            var checkEmptyCategory = $(`.${ORDER_DETAILS_SHOPING_CATEGORY_TITLE}`);
                            checkEmptyCategory.each(function () {
                                var emptyCategory = $(this).next(`.${TILE_WRAPPER_CLASS}`);
                                if (emptyCategory.length === 0) {
                                    $(this).empty();
                                }
                            });
                        }
                    }
                    if (parseFloat(data.itemsCount) <= 0) {
                        $(`.${ORDER_SHOPING_WRAPPER_CLASS} button.btn-primary`).attr('disabled', 'disabled').addClass(DISABLED_CLASS);
                    }
                    $(`.${ADD_LIST_MODAL_TOTAL_CLASS}`).text(data.listTotal);
                    $(tile).spinner().stop();
                });
            }
        });

        OperationComponent.build({
            wrapper: OPERATION_WRAPPER_CLASS,
            button: OPERATION_BUTTON_CLASS,
            value: OPERATION_VALUE_CLASS,
            changeValueCallback: (value, target, context) => {
                const wrapper = utils.getParentWithClass(target, OPERATION_WRAPPER_CLASS);
                const tile = utils.getParentWithClass(target, TILE_WRAPPER_CLASS);
                $(tile).spinner().start();

                if (value === 0) {
                    $(tile).find(`.${REMOVE_BUTTON_CLASS}`)
                        .trigger('click');
                    return;
                }

                updateProductListItem(
                    wrapper.dataset.url,
                    {
                        quantity: value,
                        itemID: wrapper.dataset.itemId,
                        listID: wrapper.dataset.listId
                    }, res => {
                        var data = JSON.parse(res);
                        context.setValue(value, target);
                        $(tile).spinner().stop();
                        $(`.${ADD_LIST_MODAL_TOTAL_CLASS}`).text(data.listTotal);
                    }
                );
            }
        });

        const listDetailsModalText = document.querySelectorAll(`.${LIST_DETAILS_MODAL_BTN_CLASS}`)[0];

        if (listDetailsModalText) {
            Modal.build({
                openButtonClass: ORDER_SHOPING_BUTTON_CLASS,
                modalConfigurations: {
                    okButtonText: listDetailsModalText.innerHTML,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.orderUrl;
                        if (url !== 'null') {
                            addProductsToCart(url, context, addProductsToCartModal);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = target.getAttribute('href');
                    if (url !== 'null') {
                        showProductsList(url, context);
                    }
                }
            });
        }

        toggleClassTextMuted();
    }
};
