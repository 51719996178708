'use strict';

module.exports = function (element) {
    var position = element && element.length ? element.offset().top : 0;
    if ($('.navbar-header').length > 0) {
        position -= $('.navbar-header').height();
    }
    $('html, body').animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
