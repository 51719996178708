const Modal = require('../shared/classes/modal.class').Modal;
const AutocompleteForm = require('../shared/classes/autocompleteForm.class').AutocompleteForm;
const RequestService = require('../shared/services/request.service').RequestService;
const Dropdown = require('./../shared/classes/fieldDropdow.class').Dropdown;
const formValidation = require('base/components/formValidation');
const validator = require('./../components/clientSideValidation');
const carousel = require('../components/carousels');
const SelectionProposal = require('../components/selectionProposal.component').SelectionProposal;

// delivery selectbox
const DELIVERY_PROPOSAL_INPUT_SELECTOR = '.user-address-form-delivery-description';
const DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR = '.dropdown-custom-wrapper';
const DELIVERY_PROPOSAL_DROPDOWN_TRIGGER_SELECTOR = '.dropdown-custom-title';
const DELIVERY_PROPOSAL_DROPDOWN_LIST_SELECTOR = '.dropdown-custom-list';
const DELIVERY_PROPOSAL_DROPDOWN_ITEM_SELECTOR = '.dropdown-custom-item';

// user address
const ADD_USER_ADDRESS_BUTTON_CLASS = 'user-address-actions-link-modal';
const EDIT_USER_ADDRESS_BUTTON_CLASS = 'user-address-actions-link-modal-edit';
const REMOVE_USER_ADDRESS_BUTTON_CLASS = 'user-address-actions-link-modal-remove';
const ADD_USER_ADDRESS_MODAL_CLASS = 'user-address-modal';
const ADD_USER_ADDRESS_FORM_ID = 'user-address-form';
const USER_ADDRESS_TILE_WRAPPER_CLASS = 'user-address-tile-wrapper';
const USER_ADDRESS_SECTION_WRAPPER_CLASS = 'user-address-list-wrapper';
const USER_ADDRESS_TILE_BTN_CLASS = 'shipping-tile-card-btn';
const CHECKOUT_ADDRESS_TILE_WRAPPER_CLASS = 'preferred-address-container';
const ADD_USER_ADDRESS_PRIMARY_CHECKBOX = 'user-address-form-field-input-checkbox';

// remove confirmation modal
const REMOVE_USER_ADDRESS_MODAL_CLASS = 'remove-address-confirmation-modal';
// remove shoping list modal
const REMOVE_USER_ADDRESS_MODAL_OK_BUTTON_CLASS = 'user-address-messages-remove-btn-ok';
const REMOVE_USER_ADDRESS_MODAL_CANCEL_BUTTON_CLASS = 'user-address-messages-remove-btn-cancel';
const REMOVE_USER_ADDRESS_MODAL_CONTENT_CLASS = 'user-address-messages-remove';

// user address autocomplete
const FIELDS_AUTOCOMPLE_CLASS = 'user-address-form-field-input-autocomplete';
const FIELDS_AUTOCOMPLE_WRAPPER_CLASS = 'user-address-form-field';

// user delivery autocomplete
const COORDINATE_CLASSES = {
    LAT: 'locationLatForm',
    LNG: 'locationLngForm'
};

// created instances
/* eslint-disable */
const GoogleApiService = (typeof google !== 'undefined') ? new google.maps.places.AutocompleteService() : null;
const GoogleGeocoderApiService = (typeof google !== 'undefined') ? new google.maps.Geocoder() : null;

/* eslint-enable */
const FieldDropdown = Dropdown.build();

/**
 * Add padding to user adrress modal on mobile
 * @param {Object} modalWrapper - modal wrapper
 */
function addModalAddressPadding(modalWrapper) {
    if (window.matchMedia('(max-width: 1199px)').matches) {
        $('.modal-backdrop').hide();
        var height = $('.header').outerHeight();
        $(modalWrapper).css('padding-top', height);
    }
}

/**
 * Show add address form
 * @param {string} url - address form url
 * @param {Object} context - class instance
 */
function showAddUserAddress(url, context) {
    RequestService.setAddressForm(url);
    RequestService.getAddressForm(null, (res) => {
        context.setContent(res, ADD_USER_ADDRESS_MODAL_CLASS);
        $(`#${context.id}`).modal('show');
        $(`#${context.id}`).on('hidden.bs.modal', function () {
            context.destroyWrapper(context.id);
        });

        AutocompleteForm.build({
            formId: context.id,
            GoogleApiService: GoogleApiService,
            GoogleGeocoderApiService: GoogleGeocoderApiService,
            FieldDropdown: FieldDropdown,
            fieldsClass: FIELDS_AUTOCOMPLE_CLASS,
            coordinatesClass: COORDINATE_CLASSES,
            fieldsWrapperClass: FIELDS_AUTOCOMPLE_WRAPPER_CLASS
        });

        SelectionProposal.build({
            input: DELIVERY_PROPOSAL_INPUT_SELECTOR,
            dropdown: {
                wrapper: DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR,
                trigger: DELIVERY_PROPOSAL_DROPDOWN_TRIGGER_SELECTOR,
                list: DELIVERY_PROPOSAL_DROPDOWN_LIST_SELECTOR,
                item: DELIVERY_PROPOSAL_DROPDOWN_ITEM_SELECTOR
            }
        });

        if (!$(`.${USER_ADDRESS_TILE_WRAPPER_CLASS}`).length && !$(`.${CHECKOUT_ADDRESS_TILE_WRAPPER_CLASS}`).length) {
            $(`.${ADD_USER_ADDRESS_PRIMARY_CHECKBOX}`).attr({ disabled: 'disabled', checked: 'checked' });
        }
        addModalAddressPadding($(`.${ADD_USER_ADDRESS_MODAL_CLASS}`));
    });
}

/**
 * get address list
 * @param {string} url - get address list url
 * @param {function} callback - get address list callback
 */
function getAddressList(url, callback) {
    RequestService.setAddressListUrl(url);
    RequestService.getAddressList(null, (res) => {
        const resJson = JSON.parse(res);
        document.querySelectorAll(`.${USER_ADDRESS_SECTION_WRAPPER_CLASS}`)[0].innerHTML = resJson.renderedTemplate;
        callback();
    }, null);
}

/**
 * select main address from address liss
*/
function selectMainAddress() {
    if ($(`.${CHECKOUT_ADDRESS_TILE_WRAPPER_CLASS}`).length) {
        $(`.${CHECKOUT_ADDRESS_TILE_WRAPPER_CLASS}`)
            .eq(0).find(`.${USER_ADDRESS_TILE_BTN_CLASS}`)
            .trigger('click');
    }
}

/**
 * Save address
 * @param {string} url - save address url
 * @param {string} data - save address requset body
 * @param {Object} context - class instance
 */
function saveAddress(url, data, context) {
    if (!url || !data) return;
    RequestService.setSaveAddress(url);
    RequestService.saveAddress(null, (res) => {
        const resJson = JSON.parse(res);
        if (resJson.success) {
            const addressListUrl = document.querySelectorAll(`.${ADD_USER_ADDRESS_BUTTON_CLASS}`)[0];
            getAddressList(addressListUrl.dataset.addressListUrl, () => {
                $(`#${context.id}`).modal('hide');
                carousel.addressesSlider();
                selectMainAddress();
            });
        } else {
            const form = document.querySelectorAll(`#${ADD_USER_ADDRESS_FORM_ID}`)[0];
            formValidation(form, resJson);
        }
    }, data);
}

/**
 * confirmation modal
 * @param {Modal} removeUserAddressModal - instance of modal class
 * @param {function} removeUserAddressHandler - callback after button pressing
 * @param {string} content - string with html content
 * @param {string} additionalClass - addition class for confirmation window
 */
function confirmationModal(removeUserAddressModal, removeUserAddressHandler, content, additionalClass) {
    removeUserAddressModal.setButtonsHandler({
        okButtonHandler: () => {
            $(`#${removeUserAddressModal.id}`).modal('hide');
        },
        cancelButtonHandler: () => {
            removeUserAddressHandler(() => {
                $(`#${removeUserAddressModal.id}`).modal('hide');
            });
        }
    });
    removeUserAddressModal.setContent(content, additionalClass);
    removeUserAddressModal.overlapModals();
    $(`#${removeUserAddressModal.id}`).modal('show');

    $(`#${removeUserAddressModal.id}`).on('hidden.bs.modal', function () {
        removeUserAddressModal.unoverlapModals();
    });
}

/**
 * Remove address
 * @param {string} url - remove address url
 * @param {Object} target - element target object
 * @param {Modal} removeUserAddressModal - instance of modal class
 */
function removeAddress(url, target, removeUserAddressModal) {
    if (!url) return;
    const content = document.querySelectorAll(`.${REMOVE_USER_ADDRESS_MODAL_CONTENT_CLASS}`)[0].innerHTML;
    confirmationModal(removeUserAddressModal, () => {
        RequestService.setRemoveAddress(url);
        RequestService.removeAddress(null, () => {
            const addressListUrl = document.querySelectorAll(`.${ADD_USER_ADDRESS_BUTTON_CLASS}`)[0];
            getAddressList(addressListUrl.dataset.addressListUrl, () => {
                carousel.addressesSlider();
                selectMainAddress();
            });
        });
    }, content, REMOVE_USER_ADDRESS_MODAL_CLASS);
}

/**
 * Get checboxes values from form
 * @param {Object} form - form node
 * @returns {string} checkboxes - return checkbox values as string
 */
function getCheckboxesValue(form) {
    const checkboxNode = form.querySelectorAll('input[type=checkbox]');
    return Array.from(checkboxNode).map((item) => {
        return `${item.name}=${item.checked}`;
    }).join('&');
}

module.exports = {
    init: function () {
        const userAddressDetailsButton = document.querySelectorAll(`.${ADD_USER_ADDRESS_BUTTON_CLASS}`)[0];
        if (userAddressDetailsButton) {
            Modal.build({
                openButtonClass: ADD_USER_ADDRESS_BUTTON_CLASS,
                modalConfigurations: {
                    okButtonText: userAddressDetailsButton.dataset.saveBtnText,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.addressUrl;
                        const form = document.querySelectorAll(`#${ADD_USER_ADDRESS_FORM_ID}`)[0];
                        const valid = true || validator.functions.validateForm(form);
                        if (url && valid) {
                            let formData = `${$(form).serialize()}&${getCheckboxesValue(form)}`;
                            saveAddress(url, formData, context);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = context.triggerButton.getAttribute('href');
                    if (url !== 'null') {
                        showAddUserAddress(url, context);
                    }
                }
            });
        }

        const userAddressEditDetailsButton = document.querySelectorAll(`.${EDIT_USER_ADDRESS_BUTTON_CLASS}`)[0];
        if (userAddressEditDetailsButton) {
            Modal.build({
                openButtonClass: EDIT_USER_ADDRESS_BUTTON_CLASS,
                modalConfigurations: {
                    okButtonText: userAddressDetailsButton.dataset.saveBtnText,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.addressUrl;
                        const form = document.querySelectorAll(`#${ADD_USER_ADDRESS_FORM_ID}`)[0];
                        const valid = true || validator.functions.validateForm(form);
                        if (url && valid) {
                            let formData = `${$(form).serialize()}&${getCheckboxesValue(form)}`;
                            saveAddress(url, formData, context);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = context.triggerButton.getAttribute('href');
                    if (url !== 'null') {
                        showAddUserAddress(url, context);
                    }
                }
            });
        }
        let removeUserAddressModal;

        const removeUserAddressOkBtn = document.querySelectorAll(`.${REMOVE_USER_ADDRESS_MODAL_OK_BUTTON_CLASS}`)[0];
        const removeUserAddressCancelBtn = document.querySelectorAll(`.${REMOVE_USER_ADDRESS_MODAL_CANCEL_BUTTON_CLASS}`)[0];
        if (removeUserAddressOkBtn && removeUserAddressCancelBtn) {
            removeUserAddressModal = Modal.build({
                openButtonClass: null,
                modalConfigurations: {
                    cancelButtonText: removeUserAddressCancelBtn.innerHTML,
                    okButtonText: removeUserAddressOkBtn.innerHTML
                }
            });
        }

        $('body').on('click', `.${REMOVE_USER_ADDRESS_BUTTON_CLASS}`, (e) => {
            e.preventDefault();
            const url = $(e.currentTarget).attr('href');
            removeAddress(url, e.target, removeUserAddressModal);
        });
        $('body').on('hidden.bs.modal', function () {
            $(this).removeClass('modal-open');
        });
    }
};
