const base = require('base/product/base');
const productBase = require('../product/base');
const focusHelper = require('base/components/focus');
const clientValidation = require('./../components/clientSideValidation');
const carousels = require('../components/carousels');
const Modal = require('../shared/classes/modal.class').Modal;
const utils = require('../shared/helpers/utils');
const { checkStore } = require('../shared/helpers/storeHelpers');

// edit card modal
const CART_EDIT_WRAPPER_CLASS = 'product-summary-edit-mode';
const CARD_EDIT_MODAL_CLASS = 'card-edit-modal-wrapper';
const CARD_EDIT_PRODUCT_ITEM_CLASS = 'product-info';
const CANCEL_ORDER_FROM_ID = 'cancelOrderForm';
const MINICART_HEDER_TITLE_CLASS = 'minicart-update-header-title-container';
const Selector = {
    minicart: '#minicart'
};
const Class = {
    cartFreeShipping: 'cart--freeshipping'
};
window.MODAL_COUNTER = 0;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Show Product comment
 *
 * @param {*} el - DOM element for current product note button
 */
function showProductComment(el) {
    var productLineItemId = $(el).closest('.dm-cart__item-card').data('attr');
    var productId = $(el).closest('.dm-cart__item-card').data('pdid');
    $('div.add-comment').find('input[name=lineItemUUID]').val(productLineItemId);
    $('div.add-comment').find('input[name=productId]').val(productId);
    $('#addComment').val($(el).attr('data-product-note'));
    $('.modal.add-comment').modal('show');
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true" class="float-right">&times;</span>' +
                '</button>'}${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.empty-cart').removeClass('d-none');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');

        productBase.checkItemsAvailableForCheckout(data);
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * remove item from cart
 * @param {*} el - jq element of product card to be removed
 */
function removeItem(el) {
    const group = el.closest('.cartgroup');
    el.remove();
    const qty = group.find('.product-info.card').length;
    if (qty > 0) {
        group.find('.groupqty').html(qty);
    } else {
        group.find('.product-info.header').remove();
    }
}

/**
 * Change payment method related button
 * @param {boolean} IsPaymentMethodNeeded - equal to payment method related value
 */
function setPaymentMethod(IsPaymentMethodNeeded) {
    const HIDE_CLASS = 'd-none';
    const PAYMENT_BTN_CLASS = 'checkout-btn.editing-order';
    const NON_PAYMENT_BTN_CLASS = 'non-checkout-btn';

    if (IsPaymentMethodNeeded) {
        $(`.${PAYMENT_BTN_CLASS}`).removeClass(HIDE_CLASS);
        $(`.${NON_PAYMENT_BTN_CLASS}`).addClass(HIDE_CLASS);
    } else {
        $(`.${PAYMENT_BTN_CLASS}`).addClass(HIDE_CLASS);
        $(`.${NON_PAYMENT_BTN_CLASS}`).removeClass(HIDE_CLASS);
    }
}

/**
 * Updates the quantity and price for total order tile
 * @param {number} quantity - totatl products quantity
 * @param {string} price - total order price
 */
function updateEditCardTotal(quantity, price) {
    const MINICART_TILE_NEW_CLASS = 'minicart-tile-icon';
    const MINICART_TOTAL_QUANTITY_CLASS = 'minicart-tile-product-quantity';
    const MINICART_TOTAL_PRICE_CLASS = 'minicart-tile-subtitle';

    $(`.${MINICART_TILE_NEW_CLASS}`).find(`.${MINICART_TOTAL_QUANTITY_CLASS}`).text(quantity);
    $(`.${MINICART_TILE_NEW_CLASS}`).find(`.${MINICART_TOTAL_PRICE_CLASS}`).text(price);
}

/**
 * Validate out of stock products
 * @param {Array} inventoryList - List of out of stock products
 */
function validProductQuantity(inventoryList) {
    const TILE_WRAPPER_CLASS = 'product-info';
    const ERORR_TILE_CLASS = 'error-tile-container';
    const CHECKOUT_BTN_CLASS = 'checkout-btn';
    const DISABLED_CLASS = 'disabled';
    const SELECTOR_PREFIX = 'uuid-';

    $(`.${ERORR_TILE_CLASS}`).removeClass(ERORR_TILE_CLASS);

    if (inventoryList.length) {
        $(`.${CHECKOUT_BTN_CLASS}`).addClass(DISABLED_CLASS);
    }

    inventoryList.forEach((item) => {
        $(`.${TILE_WRAPPER_CLASS}.${SELECTOR_PREFIX}${item}`).addClass(ERORR_TILE_CLASS);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.total-savings-body-price').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);

    updateEditCardTotal(data.quantityTotal, data.totals.grandTotal);
    setPaymentMethod(data.IsPaymentMethodNeeded);

    if (data.quantityTotal === 0) {
        $('.minicart-quantity').addClass('d-none');
    } else {
        $('.minicart-quantity, .js-free-shipping, .js-cart-carousels').removeClass('d-none');
        $('.cart-slider').slick('refresh');
        $('.empty-cart').addClass('d-none');
        $('.minicart-quantity').empty().append(data.quantityTotal);
    }

    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    $('.js-b2bAmount').text(data.b2bProfileAmount);
    if (data.totals.discounts.length > 0) {
        var existingUUIDs = $('.promotion-information').map((idx, container) => {
            return $(container).attr('data-uuid');
        }).get();

        var incommingUUIDs = data.totals.discounts.map(discountItem => {
            return discountItem.UUID;
        });

        var checkNonExistingUUIDs = existingUUIDs.filter(uuid => {
            return !incommingUUIDs.includes(uuid);
        });

        // remove uneccessary promotions containers
        checkNonExistingUUIDs.forEach(uuid => {
            $(`.promotion-information[data-uuid="${uuid}"]`).remove();
        });

        data.totals.discounts.forEach((discountItem) => {
            if ($('.dummy-promotion-information').length) {
                $('.dummy-promotion-information').attr('data-uuid', discountItem.UUID);
                $('.dummy-promotion-information').addClass('promotion-information').removeClass('dummy-promotion-information');
            }

            // add new promotions containers
            var checkExists = $('.promotion-information').filter((idx, container) => {
                return $(container).attr('data-uuid') === discountItem.UUID;
            });

            if (checkExists.length === 0) {
                $($('.promotion-information')[0]).clone().appendTo('.minicart-discounts').attr('data-uuid', discountItem.UUID);
            }

            $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).find('.js-discount-price').empty().append(discountItem.price);
            if (discountItem.type === 'coupon') {
                $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).find('.promotion-name').addClass('coupon-code').removeClass('promotion-name');
                $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).find('.coupon-code').empty().append(discountItem.couponCode);
            } else {
                $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).find('.coupon-code').addClass('promotion-name').removeClass('coupon-code');
                $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).find('.promotion-name').empty().append(discountItem.lineItemText);
            }

            if (discountItem.isFreeShippingPromo) {
                $(`.promotion-information[data-uuid="${discountItem.UUID}"]`).addClass('d-none');
            }
        });
        $('.minicart-discounts').removeClass('d-none');
    } else {
        $($('.promotion-information')[0]).addClass('dummy-promotion-information').removeClass('promotion-information');
        $('.promotion-information').each((idx, container) => {
            $(container).remove();
        });
        $('.minicart-discounts').addClass('d-none');
    }

    if (document.getElementsByClassName('original-auth-total').length > 0) {
        const totalPriceEle = document.getElementsByClassName('original-auth-total')[0];
        const origTotalPriceString = totalPriceEle.innerText;
        const origTotalPrice = parseFloat(origTotalPriceString.substr(1));
        const saveEditBtn = document.getElementById('editOrderSaveBtn');
        const paymentEditBTn = document.getElementById('editOrderPaymentBtn');

        if (parseFloat(data.totals.grandTotal.substr(1), 10) > parseFloat(origTotalPrice, 10)) {
            saveEditBtn.style.display = 'none';
            paymentEditBTn.style.display = 'block';
        } else {
            saveEditBtn.style.display = 'block';
            paymentEditBTn.style.display = 'none';
        }
    }
    $('.groupqty').each(function (index, value) {
        if (data.groupedItems) {
            const items = data.groupedItems;
            let groupExists = false;
            for (let i = 0; i < items.length; i += 1) {
                const {
                    displayName
                } = items[i];
                const {
                    groupQty
                } = items[i];
                if (this.parentNode.innerText.indexOf(displayName) !== -1) {
                    value.innerText = groupQty;
                    groupExists = true;
                    break;
                }
            }
            if (!groupExists) {
                // if there is no more group inside the cart data clear the related DOM elements
                this.parentNode.remove();
            }
        }
    });

    if (data.totals.allTotalDiscounts.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`${data.totals.allTotalDiscounts.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`${data.totals.shippingLevelDiscountTotal.formatted} -`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true" class="float-right">&times;</span>' +
        '</button>'}${message}</div>`;
    if ($('.cart-error').text()) {
        $('.cart-error').empty().append(errorHtml);
    } else {
        $('.cart-error').append(errorHtml);
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';
    for (let i = 0; i < data.items.length; i += 1) {
        if (data.items[i].uuid === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $(`.availability-${lineItem.UUID}`).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach((message) => {
                messages += `<p class="line-item-attributes">${message}</p>`;
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += `<p class="line-item-attributes line-item-instock-date">${lineItem.availability.inStockDate
            }</p>`;
        }
    }

    $(`.availability-${lineItem.UUID}`).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (let i = 0, l = array.length; i < l; i += 1) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates the quantity of product in the cart.
 * @param {string} url - Url for handling the update
 * @param {string} uuid - UUID of the lineitem
 * @param {number} quantity - the quantity to set
 * @param {number} previousQuantity - the quantity previously selected
 */
function updateCartQuantity(url, uuid, quantity, previousQuantity) {
    $(this).parents('.card').spinner().start();

    $.ajax({
        url,
        type: 'get',
        context: this,
        dataType: 'json',
        success(data) {
            // Updates selects
            $(`.quantity[data-uuid="${uuid}"]`).val(quantity);
            $(this).data('pre-select-qty', quantity);
            // Updates + - buttons
            var secondaryMeasurementUnit = $(`.add-to-cart[data-uuid="${uuid}"]`).data('secondaryMeasurementUnit');
            var unitConversion = $(`.add-to-cart[data-uuid="${uuid}"]`).data('unitConversion');
            if (secondaryMeasurementUnit || unitConversion) {
                $(`.add-to-cart[data-uuid="${uuid}"]`).find('.update-quantity .quantity').html(parseFloat(Number(quantity * unitConversion).toFixed(2)));
            } else {
                $(`.add-to-cart[data-uuid="${uuid}"]`).find('.update-quantity .quantity').html(quantity);
            }
            $(`.add-to-cart[data-uuid="${uuid}"]`).data('quantity', quantity);

            var checkForDecrease = previousQuantity - quantity;

            var flagForMsg = false;
            if (checkForDecrease > 0) {
                flagForMsg = true;
            }

            productBase.updateMinicartView(() => {
                if (data.valid.error) {
                    validProductQuantity(data.valid.inventoryList);
                }
            }, flagForMsg);

            // Update free shipping message
            $('#freeShippingMessage').empty();
            if (data.freeShippingMessage) {
                $('#freeShippingMessage').append(data.freeShippingMessage);
            }
            if (data.isPromoTriggered) {
                $('#freeShippingMessage').addClass('triggeredFree');
                if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                    $(Selector.minicart).addClass(Class.cartFreeShipping);
                }
            } else {
                $('#freeShippingMessage').removeClass('triggeredFree');
                $(Selector.minicart).removeClass(Class.cartFreeShipping);
            }

            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);
            productBase.checkItemsAvailableForCheckout(data);
            const {
                iteminfos
            } = data;
            $('.cart-json').data('cart', iteminfos);
            $('body').trigger('cart:update');
            setPaymentMethod(data.IsPaymentMethodNeeded);
            updateEditCardTotal(data.quantityTotal, data.totals.grandTotal);
            $.spinner().stop();
            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                if (previousQuantity) {
                    $(this).val(parseInt(previousQuantity, 10));
                }
                $.spinner().stop();
            }
        }
    });
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, item => item.UUID === uuid);

    if (lineItem.variationAttributes) {
        const colorAttr = findItem(lineItem.variationAttributes, attr => attr.attributeId === 'color');

        if (colorAttr) {
            const colorSelector = `.Color-${uuid}`;
            const newColor = `Color: ${colorAttr.displayValue}`;
            $(colorSelector).text(newColor);
        }

        const sizeAttr = findItem(lineItem.variationAttributes, attr => attr.attributeId === 'size');

        if (sizeAttr) {
            const sizeSelector = `.Size-${uuid}`;
            const newSize = `Size: ${sizeAttr.displayValue}`;
            $(sizeSelector).text(newSize);
        }

        const imageSelector = `.card.product-info.uuid-${uuid} .item-image > img`;
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->' +
        '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return {
        body,
        footer
    };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Ajax request instrface
 * @param {string} url - url for request
 * @param {Object} data - request pararms
 * @param {function} successHandeler - success res Handeler
 * @param {function} errorHandler - error res Handler
 */
function ajaxRequest(url, data, successHandeler, errorHandler) {
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function (res) {
            successHandeler(res);
        },
        error: function (err) {
            errorHandler(err);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Append modal to body for improved structure
 * @param {string} modalId id of modal to be relocated
 */
function changeModalLocation(modalId) {
    const wrapperTag = $(`#${modalId}`).parent().prop('tagName');
    if (wrapperTag !== 'BODY') {
        $(`#${modalId}`).clone().appendTo('body');
        $(`#${modalId}`).remove();
    }
}
/**
 * Displays empty cart
 * @param {Object} data - request pararms
 */
function displayEmptyCart(data) {
    $('body').trigger('productList:addClassTextMuted');
    $('.minicart-quantity, .js-free-shipping, .js-cart-carousels, .product-summary, .js-minicart-discounts, .shipping-discount').addClass('d-none');
    $('.empty-cart').removeClass('d-none');
    $('.total-amount, .shipping-cost').empty().append(data.subTotals);
    $('.cart-error').empty();
    $('body').trigger('cart:update');
    $('.checkout-btn').addClass('disabled');
}

/**
 *  Initialise modal behavoiur
 */
function initActions() {
    const SAVE_ORDER_MODAL_ID = 'saveOrderModal';
    $(`[data-target='#${SAVE_ORDER_MODAL_ID}']`).on('click', function () {
        changeModalLocation(SAVE_ORDER_MODAL_ID);
    });
}

/**
 *  Initialisation modal
 * @returns {Modal} instance of Modal class
 */
function initDeleteModal() {
    const editModalWrapper = document.querySelectorAll(`.${CART_EDIT_WRAPPER_CLASS}`)[0];
    if (!editModalWrapper) return;

    const modalOkBtn = editModalWrapper.dataset.deleteOk;
    const modalCancelBtn = editModalWrapper.dataset.deleteCancel;
    // eslint-disable-next-line consistent-return
    return Modal.build({
        openButtonClass: null,
        modalConfigurations: {
            okButtonText: modalCancelBtn,
            cancelButtonText: modalOkBtn
        }
    });
}

/**
 *  Initialisation modal
 * @returns {Modal} instance of Modal class
 */
function initChangePriceModal() {
    const PRICE_MODAL_WRAPPER_CLASS = 'change-price-modal-wrapper';
    const MINICART_WRAPPER_CLASS = 'js-minicart-container';
    const miniCartWrapper = document.querySelectorAll(`.${MINICART_WRAPPER_CLASS}`)[0];
    if (!miniCartWrapper) return false;

    const modalOkBtn = miniCartWrapper.dataset.changePriceOk;
    const modalCancelBtn = miniCartWrapper.dataset.changePriceCancel;
    const modalText = miniCartWrapper.dataset.changePriceText;
    // eslint-disable-next-line consistent-return
    const modal = Modal.build({
        openButtonClass: null,
        modalConfigurations: {
            okButtonText: modalCancelBtn,
            cancelButtonText: modalOkBtn
        }
    });
    modal.setContent(modalText, PRICE_MODAL_WRAPPER_CLASS);
    setTimeout(() => {
        utils.modalIndex.set(`.${PRICE_MODAL_WRAPPER_CLASS} + .modal-backdrop`);
        utils.modalIndex.set(`.${PRICE_MODAL_WRAPPER_CLASS}`);
    });
    return modal;
}

/**
 *  Modal detection initialisation
 * @param {Object} deleteOrderModal - deleteOrderModal instance
 * @param {Function} okCallback - callback function
 * @param {Function} cancelCallback - callback function
 */
function deleteModalShowValidation(deleteOrderModal, okCallback, cancelCallback) {
    const editModalWrapper = document.querySelectorAll(`.${CART_EDIT_WRAPPER_CLASS}`)[0];
    const modalMessage = editModalWrapper.dataset.deleteMessage;
    deleteOrderModal.setButtonsHandler({
        okButtonHandler: () => {
            okCallback();
            $(`#${deleteOrderModal.id}`).modal('hide');
        },
        cancelButtonHandler: () => {
            cancelCallback();
            $(`#${deleteOrderModal.id}`).modal('hide');
        }
    });

    const wrapper = document.querySelectorAll('.js-minicart-container')[0];
    deleteOrderModal.setContent(modalMessage, CARD_EDIT_MODAL_CLASS, CARD_EDIT_MODAL_CLASS, wrapper);
    deleteOrderModal.overlapModals();
    $(`#${deleteOrderModal.id}`).modal('show');
    $(`#${deleteOrderModal.id}`).on('shown.bs.modal', function () {
        const modalBg = $('.modal-backdrop');
        $(wrapper).append(modalBg);
    });
    $(`#${deleteOrderModal.id}`).on('hidden.bs.modal', function () {
        deleteOrderModal.unoverlapModals();
        deleteOrderModal.destroyWrapper(deleteOrderModal.id);
    });
}

/**
 *  Initialise coupon code
 */
function initCoupon() {
    $().vEllipsis({
        element: '.coupon-code',
        lines: 2,
        onlyFullWords: false,
        responsive: true,
        tolerance: 5,
        callback: function () {
            $(this).mouseenter(function () {
                $(this).siblings('.name-on-hover').removeClass('d-none');
            }).mouseleave(function () {
                $(this).siblings('.name-on-hover').addClass('d-none');
            });
        }
    });

    $().vEllipsis({
        element: '.coupon-name',
        lines: 1,
        onlyFullWords: false,
        responsive: true,
        tolerance: 5,
        callback: function () {
            $(this).mouseenter(function () {
                $(this).siblings('.name-on-hover').removeClass('d-none');
            }).mouseleave(function () {
                $(this).siblings('.name-on-hover').addClass('d-none');
            });
        }
    });
}

/**
 *  Delete card item functionality
 * @param {Object} item - jquery object
 */
function deleteCardItem(item) {
    const productID = $(item).data('pid');
    let url = $(item).data('action');
    const uuid = $(item).data('uuid');
    const urlParams = {
        pid: productID,
        uuid
    };
    url = appendToUrl(url, urlParams);
    $('body > .modal-backdrop').remove();
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            if (data.basket.items.length === 0 && data.basket.giftCertificateLineItems.length === 0) {
                displayEmptyCart(data);
            } else {
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (let i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                        $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                    }
                }
                removeItem($(`.uuid-${uuid}`));
                $(`.uuid-${uuid}`).remove();
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                // Update free shipping message
                $('#freeShippingMessage').empty();
                if (data.freeShippingData.freeShippingMessage) {
                    $('#freeShippingMessage').append(data.freeShippingData.freeShippingMessage);
                }
                if (data.freeShippingData.isPromoTriggered) {
                    $('#freeShippingMessage').addClass('triggeredFree');
                    if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                        $(Selector.minicart).addClass(Class.cartFreeShipping);
                    }
                } else {
                    $('#freeShippingMessage').removeClass('triggeredFree');
                    $(Selector.minicart).removeClass(Class.cartFreeShipping);
                }
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                validateBasket(data.basket);
            }
            const {
                iteminfos
            } = data;
            $('.cart-json').data('cart', iteminfos);

            $('body').trigger('cart:update');

            // If after removal of productLineItem all items have been selected check the Allow Replacement button for entire basket
            const checkboxes = $('input[type=checkbox][data-uuid]').length;
            const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
            if (checkboxes === checkedboxes) {
                $('#isReplacementAllowed').prop('checked', true);
            }

            $.spinner().stop();
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

/**
 *  Initialise modal
 */
function initModal() {
    $(document).on('click', '.close-modal', function () {
        const modalSelector = $(this).data('target');
        $(modalSelector).hide();
    });
}

/**
 *  Discard oreder functionality
 *  @param {string} redirectUrl - url for redirect
 */
function discardOrder(redirectUrl) {
    const url = $('.discard-order-confirmation-btn').data('action');

    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            if (data.redirectUrl) {
                window.location.href = redirectUrl || data.redirectUrl;
            }
            $.spinner().stop();
        },
        error(err) {
            if (err.redirectUrl) {
                window.location.href = redirectUrl || err.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
            }
            $.spinner().stop();
        }
    });
}

/**
 *  Initialise params logic
 */
function showLoginModal() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const showLogin = urlParams.get('showLogin');

    if (showLogin === 'true') {
        $('.user [data-target="#modalLogin"]').trigger('click');
    }
}

/**
 *  Save confirmation
 *  @param {string} url - url for request
 */
function saveAgeConfirmation(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // age confirmation saved in customer profile
            }
        });
    }
}

/**
 *  Cart productReplacement logic
 *  @param {string} url - url for request
 *  @param {Object} dataParams - parameters for requiest
 *  @param {Object} element - jquery element
 */
function productReplacement(url, dataParams, element) {
    var $this = element;
    $.spinner().start();
    $.ajax({
        url,
        type: 'post',
        dataType: 'json',
        data: dataParams,
        success(data) {
            if (data.error) {
                $this.closest('.js-replace-product-error').text(data.errorMessage);
            } else {
                if (!data.error) $('.cart-error').text('');
                var replacementsContainer = $this.closest('.cart-product-replacement');
                var uuid = replacementsContainer.attr('data-uuid');
                replacementsContainer.siblings(`.uuid-${uuid}`).removeClass('d-none');
                replacementsContainer.remove();

                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                        $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                    }
                }

                var idOfReplacementTile = $this.attr('id');
                if (data.quantity && uuid !== data.uuid) {
                    // Update quantity
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).attr('data-quantity', data.quantity);

                    // Updates + - buttons
                    var secondaryMeasurementUnit = $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).data('secondaryMeasurementUnit');
                    var unitConversion = $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).data('unitConversion');
                    if (secondaryMeasurementUnit && unitConversion) {
                        $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).find('.update-quantity .quantity').html(parseFloat(Number(data.quantity * unitConversion).toFixed(2)));
                    } else {
                        $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).find('.update-quantity .quantity').html(data.quantity);
                    }
                } else {
                    var basketPLI = data.basket.items.find(function (item) {
                        return item.id === idOfReplacementTile;
                    });
                    // Update product tile with new product data
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).attr('data-quantity', 1);
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).find('.update-quantity .quantity').html(basketPLI.quantity);
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).find('.btn-add-to-cart').removeClass('show');
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).find('.update-quantity').addClass('show');
                    $(`.add-to-cart[data-pid=${idOfReplacementTile}]`).parents().closest('.product-tile').find('.promotion-badge-approaching, .tile-body')
                        .addClass('promo-active');
                    var productTile = $(`.dm-cart__item-card.uuid-${data.uuid}`);
                    productTile.next('div.out-of-stock-container').remove();
                    productTile.parent('div').empty().append(data.lineItemView);

                    $('.js-product-note').each(function () {
                        $(this).unbind('click');
                        $(this).click(() => {
                            showProductComment(this);
                        });
                    });
                }
                // Update free shipping message
                $('#freeShippingMessage').empty();
                if (data.basket.freeShippingMessage) {
                    $('#freeShippingMessage').append(data.basket.freeShippingMessage);
                }
                if (data.basket.isPromoTriggered) {
                    $('#freeShippingMessage').addClass('triggeredFree');
                    if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                        $(Selector.minicart).addClass(Class.cartFreeShipping);
                    }
                } else {
                    $('#freeShippingMessage').removeClass('triggeredFree');
                    $(Selector.minicart).removeClass(Class.cartFreeShipping);
                }

                updateCartTotals(data.basket);
                validateBasket(data.basket);
                const {
                    iteminfos
                } = data.basket;
                $('.cart-json').data('cart', iteminfos);
            }

            $.spinner().stop();
            $().vEllipsis({
                element: '.product-name',
                lines: 2,
                onlyFullWords: false,
                tolerance: 5
            });
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 *  Clean show edit confirmation modal data
 */
function cleanShowEditConfirmModal() {
    const PRICE_UPDATED_MODAL_PROP = 'priceModalShown';
    const PRICE_UPDATED_STORAGE_PROP = 'priceWasUpdated';
    localStorage.removeItem(PRICE_UPDATED_MODAL_PROP);
    localStorage.removeItem(PRICE_UPDATED_STORAGE_PROP);
}

/**
 *  Show edit confirmation modal data
 *  @param {Modal} changePriceModal - Instance  of modal class
 */
function showEditConfirmModal(changePriceModal) {
    if (!changePriceModal) {
        return;
    }

    const CLOSE_EDIT_MINICART_BUTTON_CLASS = 'js-close-cart';
    const PRICE_UPDATED_MODAL_PROP = 'priceModalShown';
    const PRICE_UPDATED_STORAGE_PROP = 'priceWasUpdated';
    const priceWasUpdated = localStorage.getItem(PRICE_UPDATED_STORAGE_PROP);
    const priceModalShown = localStorage.getItem(PRICE_UPDATED_MODAL_PROP);

    changePriceModal.setButtonsHandler({
        okButtonHandler: () => {
            $(`#${changePriceModal.id}`).modal('hide');
            localStorage.setItem(PRICE_UPDATED_MODAL_PROP, true);
        },
        cancelButtonHandler: () => {
            $(`.${CLOSE_EDIT_MINICART_BUTTON_CLASS}`).trigger('click');
            $(`#${changePriceModal.id}`).modal('hide');
            cleanShowEditConfirmModal();
        }
    });

    $(`#${changePriceModal.id}`).on('hidden.bs.modal', function () {
        changePriceModal.destroyWrapper(changePriceModal.id);
    });

    $(`#${changePriceModal.id} .close`).on('click', function () {
        $(`.${CLOSE_EDIT_MINICART_BUTTON_CLASS}`).trigger('click');
    });

    if (priceWasUpdated === 'true' && !priceModalShown) {
        $(`#${changePriceModal.id}`).modal('show');
    }
}

/**
 * Set up right template (mobile/desktop/cart) for shopping lists
 * @param {string} template - what template need to use
 */
function setUpCorrectTemplateForShoppingList(template) {
    $('.js-cart-list').empty().append(template);
}

/**
 * Applies Product Replacement events
 */
function applyReplacementEvents() {
    $('body').on('change', '.allow-replacement', function (e) {
        e.preventDefault();
        const $this = $(this);
        const actionUrl = $this.data('action');
        const productID = $this.data('pid');
        const uuid = $this.data('uuid');
        let val = 'false';
        if ($this.is(':checked')) {
            val = 'true';
        } else {
            val = 'false';
        }
        let urlParams;
        if (productID && uuid) {
            urlParams = {
                pid: productID,
                uuid,
                val
            };
        } else {
            urlParams = {
                val
            };
        }

        const url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                if (uuid && val === 'false') {
                    $('#isReplacementAllowed').prop('checked', false);
                } else if (uuid && val === 'true') {
                    const checkboxes = $('input[type=checkbox][data-uuid]').length;
                    const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                    if (checkboxes === checkedboxes) {
                        $('#isReplacementAllowed').prop('checked', true);
                    }
                } else if (!uuid && val === 'true') {
                    $('input[type=checkbox][data-uuid]').prop('checked', true);
                } else {
                    $('input[type=checkbox][data-uuid]').prop('checked', false);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.js-close-replacements', function () {
        const replacementsContainer = $(this).closest('.cart-product-replacement');
        const uuid = replacementsContainer.attr('data-uuid');
        replacementsContainer.siblings(`.uuid-${uuid}`).removeClass('d-none');
        replacementsContainer.remove();
    });

    $('body').on('click', '.js-replace-product', function () {
        $.spinner().start();
        const $this = $(this);
        const actionUrl = $this.data('url');
        checkStore().then(({ noStore }) => {
            if (actionUrl && !noStore) {
                $.ajax({
                    url: actionUrl,
                    method: 'GET',
                    dataType: 'json',
                    success(data) {
                        if (data.productReplacements.length > 0) {
                            const replacementsContainer = $('.product-summary').siblings('.cart-product-replacement').clone().removeClass('d-none');
                            const uuid = $this.parent().attr('data-uuid');
                            $this.closest(`div.uuid-${uuid}`).addClass('d-none').after(replacementsContainer);
                            const replacementElement = replacementsContainer.find('.product-tile-replacement');
                            const replacementCarousel = replacementsContainer.find('.js-replacement-carousel').empty();
                            replacementsContainer.attr('data-uuid', uuid);
                            data.productReplacements.forEach((replacement) => {
                                const template = replacementElement.clone();
                                template.attr('data-pid', replacement.id);
                                if (replacement.image) {
                                    template.find('.product-image img')
                                        .attr('src', replacement.image.url)
                                        .attr('alt', replacement.image.alt);
                                }
                                $(template).attr('data-show-confirmation-legal-notice', replacement.showConfirmationLegalNotice);
                                template.find('.product-name').text(replacement.name);
                                template.find('.product-price .sales .value').text(replacement.price.sales.formatted);
                                if (replacement.price.list) {
                                    template.find('.product-price .list .value').text(replacement.price.list.formatted);
                                } else {
                                    template.find('.product-price .sales').removeClass('discounted-price');
                                    template.find('.product-price .list').addClass('d-none');
                                }
                                template.find('.js-apply-product-replacement').attr('id', replacement.id);
                                template.find('.js-apply-product-replacement-label').attr('for', replacement.id);
                                replacementCarousel.append(template);
                            });

                            replacementCarousel
                                .attr('dir', 'rtl')
                                .not('.slick-initialized')
                                .slick({
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    arrows: true,
                                    rtl: true
                                });
                        }
                        $.spinner().stop();

                        $().vEllipsis({
                            element: '.product-tile-replacement .product-name',
                            lines: 1,
                            onlyFullWords: false,
                            tolerance: 1
                        });
                    },
                    error() {
                        $.spinner().stop();
                    }
                });
            }
        });
    });

    $('body').on('change', '.js-apply-product-replacement', function () {
        const $this = $(this);
        const url = $this.closest('.js-replacement-carousel').attr('data-url');
        const tile = $this.closest('.product-tile-replacement');
        const dataParams = {
            uuid: $this.closest('.cart-product-replacement').attr('data-uuid'),
            pid: $this.attr('id')
        };
        const showConfirmationLegalNotice = tile.data('showConfirmationLegalNotice');
        const hasShowedConfirmationLegalNotice = window.sessionStorage.getItem('hasShowedConfirmationLegalNotice');
        if (showConfirmationLegalNotice && !JSON.parse(hasShowedConfirmationLegalNotice)) {
            const $confirmationLegalNoticeModal = $('#confirmationLegalNoticeModal');
            $confirmationLegalNoticeModal.modal('show');
            utils.modalIndex.set('.show.modal-backdrop');
            utils.modalIndex.set('#confirmationLegalNoticeModal');
            window.MODAL_COUNTER += 1;

            $confirmationLegalNoticeModal.on('hidden.bs.modal', function () {
                $this.prop('checked', false);
                window.MODAL_COUNTER -= 1;
                $('.confirm-age').off();
            });
            $('.under-age').on('click', function () {
                $confirmationLegalNoticeModal.modal('hide');
            });
            $('.confirm-age').on('click', function () {
                window.sessionStorage.setItem('hasShowedConfirmationLegalNotice', true);
                $confirmationLegalNoticeModal.modal('hide');
                productReplacement(url, dataParams, $this);
                const confirmAgeUrl = $(this).data('url');
                saveAgeConfirmation(confirmAgeUrl);
            });
        } else {
            productReplacement(url, dataParams, $this);
        }
    });
}

module.exports = function () {
    const deleteOrderModal = initDeleteModal();
    const changePriceModal = initChangePriceModal();

    showEditConfirmModal(changePriceModal);
    showLoginModal();
    initModal();
    initActions();
    initCoupon();
    applyReplacementEvents();

    if ($('.out-of-stock').is(':visible')) {
        $('.checkout-btn').addClass('disabled');
    }

    $('.minicart-container').on('scroll', () => {
        initCoupon();
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        const $this = $(this);
        const actionUrl = $this.data('action');
        const productID = $this.data('pid');
        const productName = $this.data('name');
        const uuid = $this.data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.js-remove-product', function () {
        const $this = $(this);
        const productID = $this.parent().data('pid');
        let url = $this.data('action');
        const uuid = $this.parent().data('uuid');
        const urlParams = {
            pid: productID,
            uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.basket.items.length === 0 && data.basket.giftCertificateLineItems.length === 0) {
                    displayEmptyCart(data);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }
                    removeItem($(`.uuid-${uuid}`));
                    $(`.uuid-${uuid}`).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // Update free shipping message
                    $('#freeShippingMessage').empty();
                    if (data.freeShippingMessage) {
                        $('#freeShippingMessage').append(data.freeShippingMessage);
                    }
                    if (data.isPromoTriggered) {
                        $('#freeShippingMessage').addClass('triggeredFree');
                        if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                            $(Selector.minicart).addClass(Class.cartFreeShipping);
                        }
                    } else {
                        $('#freeShippingMessage').removeClass('triggeredFree');
                        $(Selector.minicart).removeClass(Class.cartFreeShipping);
                    }
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                const {
                    iteminfos
                } = data;
                $('.cart-json').data('cart', iteminfos);

                $('body').trigger('cart:update');

                // If after removal of productLineItem all items have been selected check the Allow Replacement button for entire basket
                const checkboxes = $('input[type=checkbox][data-uuid]').length;
                const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                if (checkboxes === checkedboxes) {
                    $('#isReplacementAllowed').prop('checked', true);
                }

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        const editProductItems = document.querySelectorAll(`.${CART_EDIT_WRAPPER_CLASS} .${CARD_EDIT_PRODUCT_ITEM_CLASS}`);
        const isEditMode = Boolean($(`.${CART_EDIT_WRAPPER_CLASS}`).length);
        if (isEditMode && editProductItems.length <= 1) {
            deleteModalShowValidation(
                deleteOrderModal,
                () => { },
                () => {
                    const $form = $(`#${CANCEL_ORDER_FROM_ID}`);
                    const url = $form.attr('action');
                    const homeUrl = $form.data('homeUrl');

                    $.ajax({
                        url: url,
                        type: 'POST',
                        dataType: 'json',
                        data: $form.serialize(),
                        success(data) {
                            if (data.success) {
                                discardOrder(homeUrl);
                            } else {
                                const errorClass = 'cart-edit-error';
                                const errorString = `<p class="text-danger ${errorClass}">${data.errorID}</p>`;
                                $(`.${MINICART_HEDER_TITLE_CLASS}`).append(errorString);
                                setTimeout(() => {
                                    $(`.${errorClass}`).remove();
                                }, 5000);
                            }
                        }
                    });
                }
            );
        } else {
            deleteCardItem(this);
        }
    });

    $('body').on('click', '.add-to-cart button.cart-update-quantity', function () {
        const productID = $(this).closest('.add-to-cart').data('pid');
        const currentQuantity = parseFloat($(this).closest('.add-to-cart').attr('data-quantity'));
        const stepQuantity = Number($(this).closest('.add-to-cart').data('stepquantity'));
        const uuid = $(this).closest('.add-to-cart').data('uuid');
        var secondaryMeasurementUnit = $(this).closest('.add-to-cart').data('secondary-measurement-unit');
        var unitConversion = $(this).closest('.add-to-cart').data('unit-conversion');
        let url = $(this).closest('.add-to-cart').data('action');
        const decrease = $(this).data('decrease');
        var updateQuantityStep = 1;

        if (unitConversion) {
            updateQuantityStep = secondaryMeasurementUnit ? 0.5 : stepQuantity;
        }

        const targetQuantity = decrease ? currentQuantity - updateQuantityStep : currentQuantity + updateQuantityStep;
        if (targetQuantity <= 0) {
            $(this).parents().closest('.product-info').find('.cart-delete-confirmation-btn')
                .trigger('click');
            return;
        }

        const urlParams = {
            pid: productID,
            quantity: targetQuantity.toFixed(2),
            uuid
        };

        if (secondaryMeasurementUnit) {
            urlParams.secondaryMeasurementUnit = secondaryMeasurementUnit;
        }
        if (unitConversion) {
            urlParams.unitConversion = unitConversion;
        }
        url = appendToUrl(url, urlParams);
        updateCartQuantity(url, uuid, targetQuantity, currentQuantity);
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        const currentQuantity = $(this).data('pre-select-qty');
        const targetQuantity = $(this).val();
        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');

        const urlParams = {
            pid: productID,
            quantity: targetQuantity,
            uuid
        };
        url = appendToUrl(url, urlParams);

        updateCartQuantity(url, uuid, targetQuantity, currentQuantity);
    });

    $('.shippingMethods').change(function () {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        const $eventTarget = $(e.target);
        $('.coupon-error-message').empty();
        if (!$eventTarget.find('.coupon-code-field').val()) {
            $eventTarget.find('.promo-code-form .form-control').addClass('is-invalid');
            $eventTarget.find('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $eventTarget.find('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $eventTarget.closest('.promo-code-form');
        $eventTarget.closest('.promo-code-form .form-control').removeClass('is-invalid');
        $eventTarget.closest('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $eventTarget.find('.promo-code-form .form-control').addClass('is-invalid');
                    $eventTarget.find('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $eventTarget.find('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    if (data.newCouponTemplate) {
                        $('.js-apply-coupon-area').addClass('d-none');
                    }
                    if (data.newCouponCarousel) {
                        $('.js-carousel-coupons')
                            .slick('slickAdd', data.newCouponCarousel, undefined, true)
                            .slick('slickGoTo', 0);
                    }
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    if ($('.empty-cart').is(':hidden')) {
                        productBase.updateMinicartView(function () { }, true);
                    }
                    var couponCode = data.discount.couponCode;
                    var $CouponItem = $(`.slick-track #${couponCode}`);

                    if ($CouponItem.length > 1) {
                        $('.js-carousel-coupons')
                            .slick('refresh');
                    }
                    initCoupon();
                }
                $eventTarget.find('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.add-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        const couponCode = $(this).data('code');
        const tokenname = $(this).data('tokenname');
        const tokenvalue = $(this).data('tokenvalue');
        const urlParams = {
            couponCode: couponCode
        };
        urlParams[tokenname] = tokenvalue;

        url = appendToUrl(url, urlParams);

        var element = $(this).parent('.coupon');
        var dataSlickIndex = +element.attr('data-slick-index');

        $('body > .modal-backdrop').remove();

        $('.coupon-error-message').empty();
        var $that = $(this);
        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    $($that.parents('.coupons')
                        .find('.coupon-error-message')[0])
                        .empty()
                        .append(data.errorMessage);
                } else {
                    if (data.newCouponCarousel) {
                        $('.js-carousel-coupons')
                            .slick('slickRemove', dataSlickIndex)
                            .slick('slickAdd', data.newCouponCarousel, undefined, true)
                            .slick('slickGoTo', 0);
                    }
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    if ($('.empty-cart')
                        .is(':hidden')) {
                        productBase.updateMinicartView(function () {
                        }, true);
                    }
                    initCoupon();
                }
                $.spinner()
                    .stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid
        };

        url = appendToUrl(url, urlParams);
        var element = $(this).parent('.coupon');
        var dataSlickIndex = +element.attr('data-slick-index');
        var lastElement = dataSlickIndex + 1 === $(this).closest('.slick-track').children().length;

        $('body > .modal-backdrop').remove();
        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $('.js-carousel-coupons')
                    .slick('slickRemove', dataSlickIndex)
                    .slick('slickAdd', data.oldCouponCarousel, !lastElement ? dataSlickIndex : dataSlickIndex - 1, !lastElement);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                if ($('.empty-cart').is(':hidden')) {
                    productBase.updateMinicartView(function () { }, true);
                }
                initCoupon();
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('click', '.save-to-list', function () {
        $.spinner().start();

        if ($('.product-summary .dm-cart__item-card:visible').length < 1) {
            $.spinner().stop();
            return false;
        }
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    setUpCorrectTemplateForShoppingList(data.renderedTemplate);
                    $('.custom-select').selectmenu({
                        classes: {
                            'ui-selectmenu-menu': 'add-to-list',
                            'ui-selectmenu-button': 'add-to-list'
                        }
                    });
                    $('.custom-select').menu({
                        position: {
                            of: '#cartListModal'
                        }
                    });
                    $('#cartListModal').modal('show');
                } else {
                    if ($('#modalLogin').find('#action-common-error')) {
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                        $('#modalLogin').modal('show');
                    }
                    $('#modalLogin').on('hide.bs.modal', function () {
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                    });
                }
            }
        });
        $.spinner().stop();
        return true;
    });

    $('body').on('click', '.fill-previous-order', function () {
        $.spinner().start();

        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    if (data.count !== 0) {
                        setUpCorrectTemplateForShoppingList(data.renderedTemplate);
                        $('#cartListModal').modal('show');
                        var orderIDChooseOrder;
                        $('body').on('click', '.choose-list-order', function (e) {
                            e.preventDefault();
                            var selectChooseListOrder = document.getElementById('selectChooseListOrder');
                            orderIDChooseOrder = selectChooseListOrder.value;
                            var buttonNew = $('#add-to-cart-list');
                            var urlNew = buttonNew.attr('data-url') + '?orderID=' + orderIDChooseOrder;
                            $.ajax({
                                url: urlNew,
                                type: 'get',
                                dataType: 'json',
                                success() {
                                    $.spinner().stop();
                                    productBase.updateMinicartView(function () { }, true);
                                    $('#cartListModal').modal('hide');
                                }
                            });
                        });
                    } else {
                        $('#js-save-from-order-error').text(data.errorMessage).show();
                    }
                } else {
                    if ($('#modalLogin').find('#action-common-error')) {
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                        $('#modalLogin').modal('show');
                    }
                    $('#modalLogin').on('hide.bs.modal', function () {
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                    });
                }
            }
        });
        $.spinner().stop();
        return true;
    });

    $('body').on('click', '.save-from-list-to-empty-cart', function () {
        $.spinner().start();

        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    if (data.shopingList.length !== 0) {
                        setUpCorrectTemplateForShoppingList(data.renderedTemplate);
                        $('#cartListModal').modal('show');
                        var listIDChooseList;
                        $('body').on('click', '.choose-list', function (e) {
                            e.preventDefault();
                            var selectChooseList = document.getElementById('selectChooseList');
                            listIDChooseList = selectChooseList.value;
                            var buttonNew = $('#add-to-cart-list');
                            var urlNew = buttonNew.attr('data-url') + '?listID=' + listIDChooseList;
                            $.ajax({
                                url: urlNew,
                                type: 'get',
                                dataType: 'json',
                                success() {
                                    $.spinner().stop();
                                    productBase.updateMinicartView(function () { }, true);
                                    $('#cartListModal').modal('hide');
                                }
                            });
                        });
                    }
                    if (data.shopingList.length === 0) {
                        $('#js-save-to-list-error').text(data.errorMessage).show();
                    }
                } else {
                    if ($('#modalLogin').find('#action-common-error')) {
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                        $('#modalLogin').modal('show');
                    }
                    $('#modalLogin').on('hide.bs.modal', function () {
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                        $('input[type="email"]').val('');
                        $('input[type="password"]').val('');
                        $('#modalLogin .modal-dialog .form-control.is-invalid').removeClass('is-invalid');
                    });
                }
            }
        });
        $.spinner().stop();
        return true;
    });

    $('body').on('click', '.cancel-create-list', function () {
        $.spinner().start();
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    setUpCorrectTemplateForShoppingList(data.renderedTemplate);
                }
            }
        });
        $.spinner().stop();
    });

    $('body').on('click', '.add-new-list', function () {
        $.spinner().start();
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.renderedTemplate) {
                    setUpCorrectTemplateForShoppingList(data.renderedTemplate);
                    clientValidation.invalid();
                }
            }
        });
        $.spinner().stop();
    });

    $('body').on('click', '.add-new-list-mobile', function () {
        $.spinner().start();
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                setUpCorrectTemplateForShoppingList(data.renderedTemplateMobile);
                clientValidation.invalid();
            }
        });
        $.spinner().stop();
    });

    $('body').on('submit', '.save-new-list', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form = $(this);
        clientValidation.functions.validateForm($form, e);
        var url = $form.attr('action');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.errorMessage) {
                    $form.find('.form-control').addClass('is-invalid');
                    $form.find('.invalid-feedback').text(data.errorMessage).show();
                } else if (data.savedCartUrl) {
                    $.ajax({
                        url: data.savedCartUrl,
                        type: 'post',
                        dataType: 'json',
                        data: $form.serialize(),
                        success(res) {
                            if (res.renderedTemplate) {
                                $('.order-list-wrapper').empty().append(res.renderedTemplate);
                                carousels.accountDashboardShoppingList();
                            } else {
                                window.location.href = res.redirectUrl;
                            }
                        }
                    });
                    $('.save-to-list-section').removeClass('list-class');
                    $('.save-to-list-section').addClass('saved');
                    if (data.renderedTemplateDashboard) {
                        $('.shoping-list-tiles-wrapper').replaceWith(data.renderedTemplateDashboard);
                        carousels.accountDashboardShoppingList();
                    }
                    $('#cartListModal').modal('hide');
                } else if (data.success === 'Ok') {
                    if (data.listName) {
                        $(`.order-tile-product-set-title[title=${data.listName}]`).parent().find('.order-tile-product-set-quiantity').html(data.itemsCount);
                        $('.order-shoping-item-header-subname').html(data.itemsCount);
                    }

                    $('.save-to-list-section').removeClass('list-class');
                    $('.save-to-list-section').addClass('saved');
                    if (data.listName && data.itemsCount) {
                        $('.order-tile-product-set-title[title=' + data.listName + ']').parent().find('.order-tile-product-set-quiantity').html(data.itemsCount);
                    }
                    $('#cartListModal').modal('hide');
                }
            }
        });
        $.spinner().stop();
        return true;
    });

    $(document).on('submit', '.add-cart-to-list-form', function (e) {
        e.preventDefault();
        var $this = $(this);
        $.ajax({
            url: $this.attr('action'),
            type: 'post',
            data: $this.serialize(),
            success: function (data) {
                if (data.success === 'Ok') {
                    $('.save-to-list-section').removeClass('list-class');
                    $('.save-to-list-section').addClass('saved');
                    $('#cartListModal').modal('hide');
                }
            }
        });
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') ||
            !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog).toArray()
                .every(item => $(item).data('available'));

            const allReady = $('.product-availability', dialog).toArray()
                .every(item => $(item).data('ready-to-order'));

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        const selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success(data) {
                    $('#editProductModal').modal('hide');

                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    // add product id for each add-commment modal
    // fill the note
    $('.js-product-note').on('click', function () {
        var productLineItemId = $(this).closest('.dm-cart__item-card').data('attr');
        var productId = $(this).closest('.dm-cart__item-card').data('pdid');
        $('div.add-comment').find('input[name=lineItemUUID]').val(productLineItemId);
        $('div.add-comment').find('input[name=productId]').val(productId);
        $('#addComment').val($(this).attr('data-product-note'));
        $('.modal.add-comment').modal('show');
    });

    // add limit for add-commment textarea
    $('#addComment').on('keypress', function (e) {
        var maxLength = 40;
        var currentValue = $('#addComment').val();
        var currentLength = currentValue.length;
        var remain = parseInt(maxLength - currentLength, 0);
        if (remain <= 0 && e.which !== 0 && e.charCode !== 0) {
            $('#addComment').val((currentValue).substring(0, currentLength - 1));
        }
    });

    var noteUrl;
    var noteListId;
    var noteItemId;
    $(document).on('click', '.shoping-list-js-product-note', function () {
        var wrapper = $(this).closest('.order-details-tile-wrapper');
        const COMMENT_WRAPPER_SELECTOR = '.modal.add-comment';
        const COMMENT_WRAPPER_BG_SELECTOR = '.modal-backdrop.show:not(.fade)';
        noteUrl = wrapper.data('note-url');
        noteListId = wrapper.data('shoping-list-id');
        noteItemId = wrapper.data('shoping-item-id');
        var productLineItemId = wrapper.data('attr');
        $('div.add-comment').find('input[name=lineItemUUID]').val(productLineItemId);
        var productId = wrapper.data('pdid');
        $('div.add-comment').find('input[name=productId]').val(productId);
        $('#addComment').val($(this).attr('data-product-note'));
        const $commentWrapperObject = $(COMMENT_WRAPPER_SELECTOR);
        $commentWrapperObject.modal('show');
        const $commentWrapperBgObject = $(COMMENT_WRAPPER_BG_SELECTOR);
        $commentWrapperBgObject.css('z-index', '1050');
        $commentWrapperObject.css('z-index', '1060');
        $commentWrapperObject.find('.btn-primary').addClass('shoping-list-js-product-note-btn');
    });

    $(document).on('click', '.shoping-list-js-product-note-btn', function (e) {
        e.preventDefault();
        var reqData = {
            note: $('#addComment').val(),
            listID: noteListId,
            itemID: noteItemId
        };
        var $form = $(this).closest('form');
        ajaxRequest(
            noteUrl,
            reqData,
            function (data) {
                var note = $('div.add-comment').find('#addComment').val();
                $(`.shoping-list-js-product-note.uuid-${data.uuid}`).attr('data-product-note', note);
                if (note) {
                    $(`.shoping-list-js-product-note.uuid-${data.uuid}`).removeClass('icon-add-comment').addClass('icon-add-comment-blue');
                } else {
                    $(`.shoping-list-js-product-note.uuid-${data.uuid}`).removeClass('icon-add-comment-blue').addClass('icon-add-comment');
                }
                $('#addCommentModal').modal('hide');
            },
            function (err) {
                if (err.responseJSON) {
                    var errorContainer = $form.find('.invalid-feedback');
                    errorContainer.text(err.responseJSON.message);
                    errorContainer.show();
                }
            }
        );
    });

    $('#addCommentModal').on('hidden.bs.modal', function () {
        const ORDER_PRODUCT_LIST_WRAPPER_CLASS = 'order-products-list-wrapper';
        $('.shoping-list-js-product-note-btn').removeClass('shoping-list-js-product-note-btn');
        $(`.${ORDER_PRODUCT_LIST_WRAPPER_CLASS}`).css('overflow', 'auto');
    });

    $('body').on('submit', '.add-product-comment', function (e) {
        e.preventDefault();
        if ($('.add-product-comment').find('.shoping-list-js-product-note-btn').length) {
            return;
        }

        $.spinner().start();
        var $form = $(this);

        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $form.find('.invalid-feedback').text(data.errorMessage);
                } else {
                    var note = $('div.add-comment').find('#addComment').val();
                    $(`.js-product-note.uuid-${data.uuid}`).attr('data-product-note', note);
                    if (note) {
                        $(`.js-product-note.uuid-${data.uuid}`).removeClass('icon-add-comment').addClass('icon-add-comment-blue');
                    } else {
                        $(`.js-product-note.uuid-${data.uuid}`).removeClass('icon-add-comment-blue').addClass('icon-add-comment');
                    }
                    $('#addCommentModal').modal('hide');
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON) {
                    var errorContainer = $form.find('.invalid-feedback');
                    errorContainer.text(err.responseJSON.message);
                    errorContainer.show();
                }
                $.spinner().stop();
            }
        });
    });

    // initliaze v-ellipsis
    $(document).ready(function () {
        $().vEllipsis({
            element: '.v-ellipsis',
            lines: 2,
            onlyFullWords: false,
            responsive: true,
            tolerance: 5,
            elementEvent: 'scroll',
            callback: function () {
                $(this).mouseenter(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                }).mouseleave(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                });
            }
        });

        $().vEllipsis({
            element: '.promotion-badge',
            lines: 1,
            onlyFullWords: false,
            tolerance: 5,
            callback: function () {
                $(this).mouseenter(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                }).mouseleave(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                });
            }
        });

        $().vEllipsis({
            element: '.product-tile-replacement .product-name',
            lines: 1,
            onlyFullWords: false,
            tolerance: 5
        });
    });

    $(document).on('scroll', function () {
        $().vEllipsis({
            element: '.v-ellipsis',
            lines: 2,
            onlyFullWords: false,
            tolerance: 5,
            callback: function () {
                $(this).mouseenter(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                }).mouseleave(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                });
            }
        });

        $().vEllipsis({
            element: '.promotion-badge',
            lines: 1,
            onlyFullWords: false,
            tolerance: 5,
            callback: function () {
                $(this).mouseenter(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                }).mouseleave(function () {
                    $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                });
            }
        });

        $().vEllipsis({
            element: '.product-tile-replacement .product-name',
            lines: 1,
            onlyFullWords: false,
            tolerance: 5
        });
    });

    // coupons events
    $('.js-carousel-coupons').attr('dir', 'rtl');
    $('.js-carousel-coupons').slick({
        slidesToScroll: 1,
        arrows: true,
        rtl: true,
        infinite: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 912,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 544,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $('.js-cart-tooltip').mouseenter(function () {
        $(this).siblings('.tooltip-information').removeClass('d-none');
    }).mouseleave(function () {
        $(this).siblings('.tooltip-information').addClass('d-none');
    });

    // display cart on mobile
    $('.js-cart-icon').on('click', function () {
        $('.js-carousel-coupons, .cart-slider').slick('refresh');
        $('.minicart-container').show();
        if ($('.js-replacement-carousel').length) {
            $('.js-replacement-carousel .slick-initilized').slick('refresh');
        }
        $('body').addClass('lock-scroll');
        $().vEllipsis({
            element: '.v-ellipsis, .product-tile-replacement .product-name',
            lines: 2,
            onlyFullWords: false,
            responsive: true,
            tolerance: 5,
            elementEvent: 'scroll'
        });

        utils.modalIndex.set('.minicart-container');
        window.MODAL_COUNTER += 1;
    });

    $('.minicart-container').on('scroll', function () {
        $().vEllipsis({
            element: '.v-ellipsis',
            lines: 2,
            onlyFullWords: false,
            responsive: true,
            tolerance: 5
        });
    });

    $('.js-close-cart').on('click', function () {
        $('.discard-order-confirmation-btn').trigger('click');
        $('.minicart-container').hide();
        $('body').removeClass('lock-scroll');
        $('.mobile-menu-container').removeClass('d-none');
        cleanShowEditConfirmModal();

        window.MODAL_COUNTER -= 1;
        if (window.MODAL_COUNTER === 0) {
            $('body').removeClass('lock-scroll');
        }
    });

    $('body').on('click', '.js-apply-coupon-show', function (e) {
        var closestCouponArea = $(e.target).closest('.coupons-title').find('.js-apply-coupon-area');
        e.preventDefault();
        if (closestCouponArea.hasClass('d-none')) {
            closestCouponArea.removeClass('d-none');
        } else {
            closestCouponArea.addClass('d-none');
            closestCouponArea.find('#invalidCouponCode').val('');
            closestCouponArea.find('input[name=couponCode]').val('');
            closestCouponArea.find('input[name=couponCode]').removeClass('is-invalid');
            $('#invalidCouponCode').html('');
        }
    });

    $('body').on('click', '.checkout-btn', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $link = $(this);
        var url = $link.attr('href');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.redirectToCheckoutUrl) {
                    window.location.href = data.redirectToCheckoutUrl;
                } else if (data.noStore) {
                    $('.js-changeStoreBody-emptycart').addClass('d-none');
                    $('.js-changeStoreBody').removeClass('d-none');
                    $('#changeStore').addClass('d-none');
                    $('#address-autocomplete').val('');
                    $('#deliveryModal').modal('show');
                    $('#deliveryModal').on('show.bs.modal', function () {
                        utils.modalIndex.set('#deliveryModal + .modal-backdrop');
                        utils.modalIndex.set('#deliveryModal');
                        window.MODAL_COUNTER += 1;
                    });
                } else {
                    $('#modalLogin').find('#action-common-error').text(data.errorMessage).show();
                    $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
                    $('input[name="rurl"]').val('Checkout-Begin');
                    $('input[name="rquery"]').val('');
                    $('#modalLogin').modal('show');
                    $('#modalLogin').on('hide.bs.modal', function () {
                        $('#modalLogin').find('#action-common-error').text(data.errorMessage).hide();
                        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
                        $('input[name="rurl"]').val($('input[name="rurl"]').data('initial'));
                        $('input[name="rquery"]').val($('input[name="rquery"]').data('initial'));
                    });
                }
            }
        });
        $.spinner().stop();
    });

    $('.banner1').mouseenter(function () {
        $(this).find('.name-on-hover').removeClass('d-none');
    }).mouseleave(function () {
        $(this).find('.name-on-hover').addClass('d-none');
    });

    $('.banner2').mouseenter(function () {
        $(this).find('.name-on-hover').removeClass('d-none');
    }).mouseleave(function () {
        $(this).find('.name-on-hover').addClass('d-none');
    });

    $('#cartListModal').on('show.bs.modal', function () {
        $('.custom-select').selectmenu({
            classes: {
                'ui-selectmenu-menu': 'add-to-list',
                'ui-selectmenu-button': 'add-to-list'
            }
        });
        $('.custom-select').menu({
            position: {
                of: '#cartListModal'
            }
        });
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();

    module.exports = {
        updateCartTotals: updateCartTotals
    };
};
