const Modal = require('../shared/classes/modal.class').Modal;
const RequestService = require('../shared/services/request.service').RequestService;
const formValidation = require('base/components/formValidation');
const validator = require('./../components/clientSideValidation');
const cleave = require('base/components/cleave');
const Inputmask = require('inputmask').default;
const carousel = require('../components/carousels');

// user credit card
const USER_CREDIT_CARD_ADD_MODAL_BUTTON = 'user-payment-actions-link-modal';
const USER_CREDIT_CARD_ADD_MODAL_CLASS = 'user-payment-modal';
const USER_CREDIT_CARD_FORM_ID = 'user-add-credit-card-form';

// user credit card carousel
const USER_CREDIT_CARD_REMOVE_BTN_CLASS = 'user-credit-card-remove-btn';
const USER_CREDIT_CARD_WRAPPER_CLASS = 'order-tile-credit-card-list';
const USER_CREDIT_CARD_SECTION_WRAPPER_CLASS = 'user-credit-card-list-wrapper';

// remove confirmation modal
const REMOVE_CONFIRMATION_MODAL_CLASS = 'remove-confirmation-modal';

/**
 * init credit card tooltip
 */
function initTooltip() {
    $('.js-credit-card-tootltip').mouseenter(function () {
        $(this).siblings('.tooltip-information').removeClass('d-none');
    }).mouseleave(function () {
        $(this).siblings('.tooltip-information').addClass('d-none');
    });
}

/**
 * init fields mask for modal
 */
function initDateMask() {
    cleave.handleCreditCardNumber('#cardNumber', '#dwfrm_creditCard_cardType');
    const selector = document.getElementById('user-add-credit-date');
    Inputmask('99/99').mask(selector);
}

/**
 * Add padding to credit card modal on mobile
 * @param {Object} modalWrapper - modal wrapper
 */
function addModalCreditCardPadding(modalWrapper) {
    if (window.matchMedia('(max-width: 767px)').matches) {
        $('.modal-backdrop').hide();
        var height = $('.header').outerHeight();
        $(modalWrapper).css('padding-top', height);
    }
}

/**
 * Process save card string request
 * @param {string} requestString - request params
 *
 * @return {string} - updated request params
 */
function processSaveCreditCardData(requestString) {
    const dateValue = document.getElementById('user-add-credit-date').value.split('/');
    const firstPram = requestString.split('&')[0];
    const stringPrefix = `${firstPram.split('_')[0]}_${firstPram.split('_')[1]}`;
    const expMonth = `${stringPrefix}_expirationMonth=${dateValue[0]}`;
    const expYear = `${stringPrefix}_expirationYear=20${dateValue[1]}`;
    return `${requestString}&${expMonth}&${expYear}`;
}

/**
 * get credit card list
 * @param {string} url - url for credit card list
 * @param {function} callback - callback after getting credit card list
 */
function getCreditCardList(url, callback) {
    RequestService.setCreditCardListUrl(url);
    RequestService.getCreditCardList(null, (res) => {
        const resJson = JSON.parse(res);
        document.querySelectorAll(`.${USER_CREDIT_CARD_SECTION_WRAPPER_CLASS}`)[0].innerHTML = resJson.renderedTemplate;
        callback();
    }, null);
}

/**
 * confirmation modal
 * @param {Modal} removeCardModal - instance of modal class
 * @param {function} removeButtonHandler - callback after button pressing
 * @param {string} content - string with html content
 * @param {string} additionalClass - addition class for confirmation window
 */
function confirmationModal(removeCardModal, removeButtonHandler, content, additionalClass) {
    removeCardModal.setButtonsHandler({
        okButtonHandler: () => {
            $(`#${removeCardModal.id}`).modal('hide');
        },
        cancelButtonHandler: () => {
            removeButtonHandler(() => {
                $(`#${removeCardModal.id}`).modal('hide');
            });
        }
    });
    removeCardModal.setContent(content, additionalClass);
    removeCardModal.overlapModals();
    $(`#${removeCardModal.id}`).modal('show');

    $(`#${removeCardModal.id}`).on('hidden.bs.modal', function () {
        removeCardModal.unoverlapModals();
    });
}

/**
 * credit card modal
 * @param {string} url - url for show card modal
 * @param {Modal} context - instance of modal class
 */
function showCreditCardModal(url, context) {
    RequestService.setCreditCardFormUrl(url);
    RequestService.getCreditCardForm(null, (res) => {
        context.setContent(res, USER_CREDIT_CARD_ADD_MODAL_CLASS, USER_CREDIT_CARD_FORM_ID);
        $(`#${context.id}`).modal('show');
        $(`#${context.id}`).on('hidden.bs.modal', function () {
            context.destroyWrapper(context.id);
        });
        initDateMask();
        initTooltip();
        addModalCreditCardPadding($(`.${USER_CREDIT_CARD_ADD_MODAL_CLASS}`));
    });
}

/**
 * save credit card request
 * @param {string} url - url for show card modal
 * @param {Object} data - request bodyl
 * @param {Modal} context - instance of modal class
 */
function saveCreditCard(url, data, context) {
    if (!url || !data) return;
    RequestService.setSaveCreditCardUrl(url);
    RequestService.saveCreditCard(null, (res) => {
        const resJson = JSON.parse(res);
        if (resJson.success) {
            const creditCardListUrl = document.querySelectorAll(`.${USER_CREDIT_CARD_ADD_MODAL_BUTTON}`)[0];
            getCreditCardList(creditCardListUrl.dataset.creditCardListUrl, () => {
                $(`#${context.id}`).modal('hide');
                carousel.cardsSlider();
            });
        } else {
            const form = document.querySelectorAll(`#${USER_CREDIT_CARD_FORM_ID}`)[0];
            formValidation(form, resJson);
        }
    }, data);
}

/**
 * remove credit card request
 * @param {string} url - url for show card modal
 * @param {Object} target - html element
 * @param {Modal} removeCardItemModal - instance of modal class
 */
function removeCreditCard(url, target, removeCardItemModal) {
    if (!url) return;
    const content = document.querySelectorAll(`.${USER_CREDIT_CARD_WRAPPER_CLASS}`)[0].dataset.removeModalText;
    confirmationModal(removeCardItemModal, () => {
        RequestService.setRemoveCreditCardUrl(url);
        RequestService.removeCreditCard(null, () => {
            const creditCardListUrl = document.querySelectorAll(`.${USER_CREDIT_CARD_ADD_MODAL_BUTTON}`)[0];
            getCreditCardList(creditCardListUrl.dataset.creditCardListUrl, () => {
                carousel.cardsSlider();
            });
        });
    }, content, REMOVE_CONFIRMATION_MODAL_CLASS);
}

module.exports = {
    init: function () {
        const userCreditCardDetailsButton = document.querySelectorAll(`.${USER_CREDIT_CARD_ADD_MODAL_BUTTON}`)[0];
        if (userCreditCardDetailsButton) {
            Modal.build({
                openButtonClass: USER_CREDIT_CARD_ADD_MODAL_BUTTON,
                modalConfigurations: {
                    okButtonText: userCreditCardDetailsButton.dataset.okBtnText,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.saveUrl;
                        const form = document.querySelectorAll(`#${USER_CREDIT_CARD_FORM_ID}`)[0];
                        const valid = true || validator.functions.validateForm(form);
                        if (url && valid) {
                            const formData = processSaveCreditCardData(cleave.serializeData($(form)));
                            saveCreditCard(url, formData, context);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = context.triggerButton.getAttribute('href');
                    if (url !== 'null') {
                        showCreditCardModal(url, context);
                    }
                }
            });
        }

        let removeCardModal;
        const removeListOkBtn = document.querySelectorAll(`.${USER_CREDIT_CARD_WRAPPER_CLASS}`)[0];
        const removeListCancelBtn = document.querySelectorAll(`.${USER_CREDIT_CARD_WRAPPER_CLASS}`)[0];
        if (removeListOkBtn && removeListCancelBtn) {
            removeCardModal = Modal.build({
                openButtonClass: null,
                modalConfigurations: {
                    okButtonText: removeListCancelBtn.dataset.removeOkBtn,
                    cancelButtonText: removeListOkBtn.dataset.removeCancelBtn
                }
            });
        }

        $('body').on('click', `.${USER_CREDIT_CARD_REMOVE_BTN_CLASS}`, (e) => {
            e.preventDefault();
            const url = $(e.currentTarget).attr('href');
            removeCreditCard(url, e.target, removeCardModal);
        });
    }
};
