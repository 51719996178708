const utils = require('../helpers/utils');

const cache = new Map();

class RequestService {
    static build() {
        return new RequestService();
    }

    emptyCache() {
        cache.clear();
        return this;
    }

    setOrderDetailUrl(url) {
        this.orderDetailUrl = url;
        return this;
    }

    setProductsListUrl(url) {
        this.productsListUrl = url;
        return this;
    }

    setAddToCartUrl(url) {
        this.addToCartUrl = url;
        return this;
    }

    setUpdateProductListItemUrl(url) {
        this.updateProductListItemUrl = url;
        return this;
    }

    setRemoveProductListItemUrl(url) {
        this.removeProductListItemUrl = url;
        return this;
    }

    setSaveUserDataUrl(url) {
        this.saveUserDataUrl = url;
        return this;
    }

    setPasswordForm(url) {
        this.passwordFormUrl = url;
        return this;
    }

    setAddressForm(url) {
        this.addressFormUrl = url;
        return this;
    }

    setSavePassword(url) {
        this.savePasswordUrl = url;
        return this;
    }

    setSaveAddress(url) {
        this.saveAddressUrl = url;
        return this;
    }

    setRemoveAddress(url) {
        this.removeAddressUrl = url;
        return this;
    }

    setRemoveProductListUrl(url) {
        this.removeProductListUrl = url;
        return this;
    }

    setCreditCardFormUrl(url) {
        this.creditCardFormUrl = url;
        return this;
    }

    setSaveCreditCardUrl(url) {
        this.saveCreditCardUrl = url;
        return this;
    }

    setRemoveCreditCardUrl(url) {
        this.removeCreditCardUrl = url;
        return this;
    }

    setAddressListUrl(url) {
        this.addressListUrl = url;
        return this;
    }

    setCreditCardListUrl(url) {
        this.creditCardListUrl = url;
        return this;
    }

    setEditAvatarForm(url) {
        this.editAvatarFormUrl = url;
        return this;
    }

    setSaveAvatar(url) {
        this.saveAvatarUrl = url;
        return this;
    }

    removeAddress(data, callbackHandler) {
        this.sendData(this.removeAddressUrl, data, callbackHandler, false);
    }

    getEditAvatarForm(data, callbackHandler) {
        this.sendData(this.editAvatarFormUrl, data, callbackHandler, false);
    }

    getAddressForm(data, callbackHandler) {
        this.sendData(this.addressFormUrl, data, callbackHandler, false);
    }

    getCreditCardForm(data, callbackHandler) {
        this.sendData(this.creditCardFormUrl, data, callbackHandler, false);
    }

    getPasswordForm(data, callbackHandler) {
        this.sendData(this.passwordFormUrl, data, callbackHandler);
    }

    getProductsList(data, callbackHandler) {
        this.sendData(this.productsListUrl, data, callbackHandler, false);
    }

    getOrderDetail(data, callbackHandler) {
        this.sendData(this.orderDetailUrl, data, callbackHandler);
    }

    getAddToCard(data, callbackHandler) {
        this.sendData(this.addToCartUrl, data, callbackHandler, false);
    }

    getAddressList(data, callbackHandler) {
        this.sendData(this.addressListUrl, data, callbackHandler, false);
    }

    getCreditCardList(data, callbackHandler) {
        this.sendData(this.creditCardListUrl, data, callbackHandler, false);
    }

    removeProductList(data, callbackHandler) {
        this.sendData(this.removeProductListUrl, data, callbackHandler, false);
    }

    removeCreditCard(data, callbackHandler) {
        this.sendData(this.removeCreditCardUrl, data, callbackHandler, false);
    }

    updateProductListItem(data, callbackHandler, body) {
        this.sendData(this.updateProductListItemUrl, data, callbackHandler, false, 'POST', body);
    }

    saveUserData(data, callbackHandler, body) {
        this.sendData(this.saveUserDataUrl, data, callbackHandler, false, 'POST', body, 'FORM');
    }

    savePassword(data, callbackHandler, body) {
        this.sendData(this.savePasswordUrl, data, callbackHandler, false, 'POST', body, 'FORM');
    }

    saveAddress(data, callbackHandler, body) {
        this.sendData(this.saveAddressUrl, data, callbackHandler, false, 'POST', body, 'FORM');
    }

    saveAvatar(data, callbackHandler, body) {
        this.sendData(this.saveAvatarUrl, data, callbackHandler, false, 'POST', body, 'FORM');
    }

    saveCreditCard(data, callbackHandler, body) {
        this.sendData(this.saveCreditCardUrl, data, callbackHandler, false, 'POST', body, 'FORM');
    }

    updateProductListItem(data, callbackHandler, body) {
        this.sendData(this.updateProductListItemUrl, data, callbackHandler, false, 'POST', body);
    }

    removeProductListItem(data, callbackHandler) {
        this.sendData(this.removeProductListItemUrl, data, callbackHandler, false);
    }

    sendData(url, data, callbackHandler, cacheable = true, type = 'GET', body, contentType) { // eslint-disable-line
        const params = utils.objectToQueryString(data);
        const requestString = url + params;
        const cacheRes = cache.get(requestString);
        let reqBody = {};

        if (cacheRes && cacheable) {
            callbackHandler(cacheRes);
            return;
        }

        if (type.toUpperCase() === 'POST' && body) {
            let headers = {};
            if (contentType === 'FORM') {
                headers = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
            }

            reqBody = {
                body: (typeof body === 'object' ? JSON.stringify(body) : body),
                dataType: 'json',
                ...headers
            };
        }

        fetch(requestString, {
            method: type,
            ...reqBody
        })
            .then(res => res.text())
            .then(res => {
                cache.set(requestString, res);
                callbackHandler(res);
            })
            .catch(err => window.console.error(err));
    }
}

module.exports = {
    RequestService: RequestService.build()
};
