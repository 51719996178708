const utils = require('./shared/helpers/utils');
window.MODAL_COUNTER = 0;

/**
 * Delivery pop-up general scripts
  */
function initializeDeliveryEvents() {
    if ($('#deliveryModal').length) {
        $('.choose-store-input').on('change', function () {
            if ($(this).prop('checked') === true) {
                $('.store-slider').removeClass('d-none');
                $('.image').addClass('d-none');
                $('.self-picking-area').removeClass('d-none');
            } else {
                $('.store-slider').addClass('d-none');
                $('.image').removeClass('d-none');
                $('.self-picking-area').addClass('d-none');
            }
        });

        $('.store-slider-input').on('change', function () {
            if ($(this).prop('checked') === true) {
                $(this).val('search-on-map');
                $('.google-map').removeClass('d-none');
                $('.stores-list').addClass('d-none');
            } else {
                $(this).val('choose-store');
                $('.google-map').addClass('d-none');
                $('.stores-list').removeClass('d-none');
            }
        });

        $('.js-header-address').on('click', function () {
            $('.js-changeStoreBody-emptycart').addClass('d-none');
            $('.js-changeStoreBody').removeClass('d-none');
            $('#changeStore').addClass('d-none');
            $('#address-autocomplete').val('');
            $('#deliveryModal').on('show.bs.modal', function () {
                utils.modalIndex.set('#deliveryModal + .modal-backdrop');
                utils.modalIndex.set('#deliveryModal');
                window.MODAL_COUNTER += 1;
            });
        });

        $('#deliveryModal').on('hide.bs.modal', function () {
            $('.mobile-menu-container').css('z-index', '');
        });

        // var input = document.getElementById("address-autocomplete");
        // var autocomplete = new google.maps.places.Autocomplete(input);
    }
}

/**
 * Self pickup script
 */
function initializePickupEvents() {
    if ($('#pickupModal').length) {
        $('.store-slider-input').on('change', function () {
            if ($(this).prop('checked') === true) {
                $(this).val('search-on-map');
                $('.google-map').removeClass('d-none');
                $('.stores-list').addClass('d-none');
            } else {
                $(this).val('choose-store');
                $('.google-map').addClass('d-none');
                $('.stores-list').removeClass('d-none');
            }
        });

        $('#pickupModal').on('hide.bs.modal', function () {
            $('.mobile-menu-container').css('z-index', '');
        });

        $('.self-picking-area').removeClass('d-none');
        $('.stores-list').addClass('d-none');
    }
}

/**
 * Show more stores in stores list
  */
function showMoreStores() {
    var sizeOfList = $('.stores-list .store-container').length;
    var sizeOfDisaplyedStores = 4;

    $('.stores-list .store-container').slice(0, sizeOfDisaplyedStores).show();
    $('.show-more').on('click', function (e) {
        e.preventDefault();
        $('.stores-list .store-container').slice(sizeOfDisaplyedStores, sizeOfList).show();
        $(this).hide();
    });
}

module.exports = function () {
    initializeDeliveryEvents();
    initializePickupEvents();
    showMoreStores();
};
