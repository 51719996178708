/**
 *
 * Style Guide events
 */
function initializeSelects() {
    $('.custom-select').selectmenu({
        classes: {
            'ui-selectmenu-button': 'custom-select'
        }
    });

    $('.custom-select.expirationYear, .custom-select.expirationMonth').selectmenu({
        classes: {
            'ui-selectmenu-menu': 'checkout-menu'
        }
    });
}

module.exports = function () {
    initializeSelects();
};
