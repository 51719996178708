const RequestService = require('../shared/services/request.service').RequestService;
const Modal = require('../shared/classes/modal.class').Modal;
const User = require('../shared/classes/user.class').User;
const validator = require('./../components/clientSideValidation');
const formValidation = require('base/components/formValidation');

//  edit user data
const USER_DATA_FORM_ID = 'user-info-form';
const USER_DATA_FORM_ERROR_WRAPPER_CLASS = 'user-info-board-wrapper-global-error';

//  change password
const CHANGE_PASSWORD_FORM_ID = 'user-password-form';
const CHANGE_PASSWORD_BUTTON_CLASS = 'user-info-action-button-change-password';
const CHNAGE_PASSWORD_MODAL_CLASS = 'password-modal';

/**
 * Add padding to credit card modal on mobile
 * @param {Object} modalWrapper - modal wrapper
 */
function addModalCreditCardPadding(modalWrapper) {
    if (window.matchMedia('(max-width: 767px)').matches) {
        $('.modal-backdrop').hide();
        var height = $('.header').outerHeight();
        $(modalWrapper).css('padding-top', height);
    }
}

/**
 * Update user name to header and dashboard
 */
function updateUserName() {
    const firstName = document.getElementById('user_firstName').value;
    const lastName = document.getElementById('user_lastName').value;
    const headerName = document.querySelectorAll('.user-message.registered')[0];
    const tooltipName = document.querySelectorAll('.user .name-on-hover')[0];
    const dashboardName = document.querySelectorAll('.dashboard-user-name')[0];
    headerName.innerHTML = (firstName + ' ' + lastName);

    if (headerName.innerHTML.length > 9) {
        headerName.innerHTML = headerName.innerHTML.substring(0, 9) + '...';
    }
    tooltipName.innerText = firstName + ' ' + lastName;
    dashboardName.innerHTML = firstName;

    headerName.addEventListener('mouseenter', function () {
        headerName.nextElementSibling.classList.remove('d-none');
    });
    headerName.addEventListener('mouseleave', function () {
        headerName.nextElementSibling.classList.add('d-none');
    });
}

/**
 * Save user data
 * @param {string} url - Save user data url
 * @param {Object} data - request params
 * @param {Object} context - object instance
 */
function saveUserData(url, data, context) {
    if (!url) return;
    RequestService.setSaveUserDataUrl(url);
    RequestService.saveUserData(null, (res) => {
        const resJson = JSON.parse(res);
        const errorWraperr = document.querySelectorAll(`.${USER_DATA_FORM_ERROR_WRAPPER_CLASS}`)[0];
        errorWraperr.innerHTML = '';
        if (resJson.success) {
            context.disableEdit(true);
            updateUserName();
        } else {
            const form = document.querySelectorAll(`#${USER_DATA_FORM_ID}`)[0];
            if (!resJson.ncrUpdated) {
                errorWraperr.innerHTML = resJson.ncrUpdatedError;
            } else {
                formValidation(form, resJson);
            }
        }
    }, data);
}

/**
 * Show change password form
 * @param {string} url - change password form
 * @param {Object} context - class instance
 */
function showChangePassword(url, context) {
    if (!url) return;
    RequestService.setPasswordForm(url);
    RequestService.getPasswordForm(null, (res) => {
        context.setContent(res, CHNAGE_PASSWORD_MODAL_CLASS);
        $(`#${context.id}`).modal('show');
        $(`#${context.id}`).on('hidden.bs.modal', function () {
            context.destroyWrapper(context.id);
        });
        addModalCreditCardPadding($(`.${CHNAGE_PASSWORD_MODAL_CLASS}`));
    });
}

/**
 * Save user password
 * @param {string} url - save password url
 * @param {string} data - save password request body
 * @param {Object} context - class instance
 */
function savePassword(url, data, context) {
    if (!url || !data) return;
    RequestService.setSavePassword(url);
    RequestService.savePassword(null, (res) => {
        const resJson = JSON.parse(res);
        if (resJson.success) {
            $(`#${context.id}`).modal('hide');
        } else {
            const form = document.querySelectorAll(`#${CHANGE_PASSWORD_FORM_ID}`)[0];
            formValidation(form, resJson);
        }
    }, data);
}

module.exports = {
    init: function () {
        const userForm = document.querySelectorAll(`.${USER_DATA_FORM_ID}`)[0];
        if (userForm) {
            User.build({
                fields: {
                    firstName: document.querySelectorAll('#user_firstName')[0],
                    lastName: document.querySelectorAll('#user_lastName')[0],
                    phone: document.querySelectorAll('#user_phone')[0],
                    email: document.querySelectorAll('#user_email')[0]
                },
                actions: {
                    editButtonClass: 'user-info-action-button-edit',
                    saveButtonClass: 'user-info-update-button-save'
                },
                callbacks: {
                    saveUserDataCallback: (context) => {
                        const form = $(`#${USER_DATA_FORM_ID}`).first();
                        var disabled = form.find(':input:disabled').removeAttr('disabled');

                        // serialize the form
                        var serialized = form.serialize();

                        // re-disabled the set of inputs that you previously enabled
                        disabled.attr('disabled', 'disabled');
                        const url = form.attr('action');
                        const valid = validator.functions.validateForm(form);
                        if (url && valid) {
                            saveUserData(url, serialized, context);
                        }
                    },
                    resetUserDataCallback: () => {
                        const form = document.querySelectorAll(`#${USER_DATA_FORM_ID}`)[0];
                        form.querySelectorAll('.is-invalid').forEach((item) => {
                            item.classList.remove('is-invalid');
                        });
                    }
                }
            });
        }

        const changePasswordButton = document.querySelectorAll(`.${CHANGE_PASSWORD_BUTTON_CLASS}`)[0];
        if (changePasswordButton) {
            Modal.build({
                openButtonClass: CHANGE_PASSWORD_BUTTON_CLASS,
                modalConfigurations: {
                    okButtonText: 'עדכן',
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.passwordUrl;
                        const form = document.querySelectorAll(`#${CHANGE_PASSWORD_FORM_ID}`)[0];
                        const valid = true || validator.functions.validateForm(form);
                        if (url && valid) {
                            savePassword(url, $(form).serialize(), context);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = target.getAttribute('href');
                    if (url !== 'null') {
                        showChangePassword(url, context);
                    }
                }
            });
        }
    }
};
