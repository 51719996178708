class TouchEvents {
    /**
     * Create instance of TouchEvents class
     *
     * @param {Object} data - object with configurations
     * @return {TouchEvents} instance of TouchEvents class
     */
    static build(data) {
        return new TouchEvents()
            .setSwipeDownCallbeck(data.swipeDownCallbeck)
            .setSwipeUpCallbeck(data.swipeUpCallbeck)
            .setTarget(data.target)
            .swipeEvents();
    }

    setSwipeUpCallbeck(up) {
        this.swipeUpCallbeck = up;
        return this;
    }

    setSwipeDownCallbeck(down) {
        this.swipeDownCallbeck = down;
        return this;
    }

    setTarget(target) {
        this.target = target;
        return this;
    }

    disableScroll() {
        document.querySelectorAll('body')[0].style.overflow = 'hidden';
        return this;
    }

    enableScroll() {
        document.querySelectorAll('body')[0].style.overflow = 'unset';
        return this;
    }

    swipeEvents() {
        const self = this;
        let startScrollPositionY = null;
        let endScrollPositionY = null;

        this.target.addEventListener('touchstart', (e) => {
            this.disableScroll();
            const touch = e.touches[0] || e.changedTouches[0];
            startScrollPositionY = touch.pageY;
        });

        document.addEventListener('touchend', (e) => {
            if (startScrollPositionY === null) { return; }
            const touch = e.touches[0] || e.changedTouches[0];
            endScrollPositionY = touch.pageY;

            if (startScrollPositionY < endScrollPositionY) {
                self.swipeDownCallbeck();
            } else if (startScrollPositionY > endScrollPositionY) {
                self.swipeUpCallbeck();
            }

            startScrollPositionY = null;
            this.enableScroll();
        });

        return this;
    }
}

module.exports = {
    TouchEvents: TouchEvents
};
