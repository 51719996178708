const utils = require('../helpers/utils');

const MODAL_WRAPPER_CLASS = 'modal-dialog-wrapper';
const OK_BUTTON_CLASS = 'btn-primary';
const CANCLE_BUTTON_CLASS = 'btn-secondary';
const OPEN_MODAL_CLASS = 'modal-open';

const body = document.querySelectorAll('body')[0];
class Modal {
    static build(data) {
        return new Modal()
            .setTriggerClass(data.openButtonClass)
            .setModalConfigurations(data.modalConfigurations)
            .setButtonsHandler(data.modalConfigurations)
            .setOpenHandler(data.openHandler)
            .initEvents();
    }

    setOpenHandler(openHandler) {
        this.openHandler = openHandler;
        return this;
    }

    setButtonsHandler(modalConfigurations) {
        this.okButtonHandler = modalConfigurations.okButtonHandler;
        this.cancelButtonHandler = modalConfigurations.cancelButtonHandler;
        return this;
    }

    setTriggerClass(triggerButtonClass) {
        this.triggerButtonClass = triggerButtonClass;
        return this;
    }

    setModalConfigurations(configurations) {
        this.modalConfigurations = configurations;
        return this;
    }

    setContent(content, additionalClass, formId, wrapper) {
        const appendWrapper = wrapper || body;
        this.id = utils.generateId('modal_');
        this.modalWrapper = this.createWrapper(additionalClass, content, this.id, formId, wrapper);
        appendWrapper.insertAdjacentHTML('beforeend', this.modalWrapper);
        this.setZIndexValue(additionalClass);
        return this;
    }

    setZIndexValue(modalClass) {
        utils.modalIndex.set(`.${modalClass} + .modal-backdrop`);
        utils.modalIndex.set(`.${modalClass}`);
        window.MODAL_COUNTER += 1;
        return this;
    }

    overlapModals() {
        const modalWrappers = document.querySelectorAll('.modal-dialog-wrapper');
        modalWrappers.forEach((item) => {
            if (item.id !== this.id) {
                item.style.zIndex = '1040';
            }
        });
        return this;
    }

    unoverlapModals() {
        const modalWrappers = document.querySelectorAll('.modal-dialog-wrapper');
        modalWrappers.forEach((item) => {
            item.style.zIndex = '';
        });
        return this;
    }

    createWrapper(additionalClass, content, id, formId, wrapper) {
        const styles = wrapper ? 'style="position: absolute;"' : '';
        const primaryBtn = this.modalConfigurations.okButtonText ? `<button type="button" class="btn btn-primary">${this.modalConfigurations.okButtonText}</button>` : '';
        const secondaryBtn = this.modalConfigurations.cancelButtonText ? `<button type="button" class="btn btn-secondary" data-dismiss="modal">${this.modalConfigurations.cancelButtonText}</button>` : '';
        return `<div class="modal fade ${additionalClass} modal-dialog-wrapper" id="${id}" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" ${styles}>
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header border-0 align-items-end flex-column pb-0">
                                <button type="button" class="close m-0 p-0 position-absolute" data-dismiss="modal" aria-label="Close">
                                    <span class="icon icon-close" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="modal-body modal-body-list col-12 mx-auto">
                                ${formId ? '<form id="' + formId + '">' : ''}
                                    ${content}
                                ${formId ? '</form>' : ''}
                            </div>
                            <div class="modal-footer">
                                ${secondaryBtn}
                                ${primaryBtn}
                            </div>
                        </div>
                    </div>
                </div>`;
    }

    destroyWrapper(id) {
        const wrapper = document.querySelectorAll(`#${id}`)[0];
        wrapper.remove();
        const isOpenModalExist = Boolean(document.querySelectorAll(`.${MODAL_WRAPPER_CLASS}`).length);
        if (isOpenModalExist) {
            body.classList.add(OPEN_MODAL_CLASS);
        }
        return this;
    }

    initEvents() {
        body.addEventListener('click', (e) => {
            const hasSomeParentTheClass = utils.hasSomeParentTheClass(e.target, this.triggerButtonClass);
            const hasSomeParentTheClassOk = utils.hasSomeParentTheClass(e.target, OK_BUTTON_CLASS);
            const hasSomeParentTheClassCancel = utils.hasSomeParentTheClass(e.target, CANCLE_BUTTON_CLASS);

            if (e.target.classList.contains(this.triggerButtonClass) || hasSomeParentTheClass) {
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, this.triggerButtonClass);
                    if (!target) return;
                }
                e.preventDefault();
                this.triggerButton = target;

                if (this.openHandler) {
                    this.openHandler(this.triggerButton, this);
                }
            } else if (e.target.classList.contains(OK_BUTTON_CLASS) || hasSomeParentTheClassOk) {
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, OK_BUTTON_CLASS);
                    if (!target) return;
                }

                const wrapper = utils.getParentWithClass(target, MODAL_WRAPPER_CLASS);
                if (!wrapper || wrapper.id !== this.id) {
                    return;
                }
                e.preventDefault();
                this.okButtonHandler(this);
            } else if (e.target.classList.contains(CANCLE_BUTTON_CLASS) || hasSomeParentTheClassCancel) {
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, CANCLE_BUTTON_CLASS);
                    if (!target) return;
                }

                const wrapper = utils.getParentWithClass(target, MODAL_WRAPPER_CLASS);
                if (!wrapper || wrapper.id !== this.id) {
                    return;
                }
                e.preventDefault();
                this.cancelButtonHandler(this);
            }
        });

        return this;
    }
}

module.exports = {
    Modal: Modal
};
