
const formValidation = require('base/components/formValidation');
const CLUB_MEMBER_FORM_SELECTOR = '#clubModalForm';
const CLUB_MEMBER_FORM_SUBMIT_SELECTOR = '.club-member-form-submit-btn';
const NCR_ERROR_WRAPPER_CLASS = '.ncr-error-text';
const Selector = {
    registrationForm: '.js-club-register-form'
};

/**
 * ajax request
 * @param {string} url - url for request
 * @param {Object} data - parameters for share
 * @param {Function} callback - callback function
 */
function request(url, data, callback) {
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function (res) {
            callback(res);
        },
        error: function (error) {
            console.log(error); // eslint-disable-line
        }
    });
}

/**
 * Save form function
 */
function saveFormData() {
    const $form = $(CLUB_MEMBER_FORM_SELECTOR);
    request(
        $form.attr('action'),
        $form.serialize(),
        (res) => {
            $(NCR_ERROR_WRAPPER_CLASS).text('');

            if (!res.success) {
                if (!res.clubSuccess) {
                    $(NCR_ERROR_WRAPPER_CLASS).text(res.ncrError);
                }
                const form = document.querySelectorAll(CLUB_MEMBER_FORM_SELECTOR)[0];
                formValidation(form, res);
            } else {
                window.location.href = res.redirectUrl;
            }
        }
    );
}

/**
 * Register user form function
 * @param {*} form - DOM element for current product note button
 */
function registerFormData(form) {
    const $form = $(form);
    const url = $form.attr('action');
    let formData = {};
    formData.preferences = [];

    $form.find(':input').each((index, element) => {
        let key = $(element).attr('name');
        let val = '';
        if ($(element).attr('type') === 'checkbox') {
            if ($(element).data('preference') && $(element).is(':checked')) {
                formData.preferences.push(key);
            } else {
                val = $(element).is(':checked');
            }
        } else {
            val = $(element).val();
        }
        formData[key] = val;
    });

    // parsing preferences array to retrieve it correctly on the BE
    formData.preferences = JSON.stringify(formData.preferences);

    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: function (res) {
            console.log(res); // eslint-disable-line
            var response = res.responseJSON;
            if (res.success) {
                $('#clubModal').modal('hide');
                $('#clubModalTrigger').addClass('d-none');
                var confirmationModal = $('#confirmationModal');
                if (Object.keys(res.customerData).length !== 0) {
                    let clubNumberText = confirmationModal.find('.club-number-text').html();
                    confirmationModal.find('.club-number-text').html(clubNumberText + ' / ' + res.customerData.clubMemberID);
                    let title = confirmationModal.find('.modal-title').html();
                    confirmationModal.find('.modal-title').html(title.replace('%names%', `${res.customerData.firstName} ${res.customerData.lastName}`));
                }
                confirmationModal.modal('show');
            } else {
                $(NCR_ERROR_WRAPPER_CLASS).text(response.message);
            }
        },
        error: function (res) {
            console.log(res); // eslint-disable-line
            var response = res.responseJSON;
            if (!response.success) {
                $(NCR_ERROR_WRAPPER_CLASS).text(response.message);
            }
        }
    });
}

/**
 * Init all events function
 */
function initEvents() {
    $(document).on('click', CLUB_MEMBER_FORM_SUBMIT_SELECTOR, (e) => {
        e.preventDefault();
        saveFormData();
    });

    $(Selector.registrationForm).on('submit', (e) => {
        e.preventDefault();
        registerFormData(e.currentTarget);
    });
}

module.exports = {
    init: function () {
        initEvents();
    }
};
