var base = require('./base');
var cartHelper = require('../cart/cart');
const { checkStore } = require('../shared/helpers/storeHelpers');

window.MODAL_COUNTER = 0;

var Selector = {
    addToCartButton: '.js-cart-add-all',
    addToListButton: '.js-list-add-all',
    productsSlider: '.js-custom-products-slider',
    addToCartUrl: '.js-addtocart-url',
    productTile: '.js-product-tile',
    selectionCheckbox: '.js-selection-checkbox',
    minicart: '#minicart'
};

const Class = {
    cartFreeShipping: 'cart--freeshipping'
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @param {string} secondaryMeasurementUnit - secondaryMeasurementUnit
 * @param {string} unitConversion - unitConversion
 * @param {string} itemId - product ID
 * @return {undefined}
 */
function updateTile(quantity, selector, secondaryMeasurementUnit, unitConversion, itemId) {
    if (secondaryMeasurementUnit) {
        $(selector).empty().html(quantity);
    } else {
        if (unitConversion) {
            $(selector).empty().html(parseFloat(Number(quantity * unitConversion).toFixed(2)));
        } else {
            $(selector).empty().html(parseFloat(Number(quantity).toFixed(2)));
        }
        $(selector).closest('.add-to-cart').attr('data-secondary-measurement-unit', secondaryMeasurementUnit);
        $(selector).closest('.add-to-cart').attr('data-unit-conversion', unitConversion);
    }

    if (quantity && quantity > 0) {
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').removeClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').addClass('show');
        $(selector).closest('.add-to-cart').parents().closest('.product-tile')
            .find('.promotion-badge-approaching, .tile-body')
            .addClass('promo-active');
    } else {
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').removeClass('show');
        $(selector).closest('.add-to-cart').parents().closest('.product-tile')
            .find('.promotion-badge-approaching, .tile-body')
            .removeClass('promo-active');
    }

    $(`.add-to-cart[data-pid=${itemId}]`).attr('data-quantity', quantity);
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    const items = $('.cart-json').data('cart');
    $('.add-to-cart').find('.tile-add-to-cart').addClass('show');
    $('.add-to-cart').find('.update-quantity').not('.gift-cert-disabled').removeClass('show');
    $('.add-to-cart').parents().closest('.product-tile').find('.promotion-badge-approaching, .tile-body')
        .removeClass('promo-active');
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            if (!item.isBonus) {
                var secondaryMeasurementUnit = item.secondaryMeasurementUnit;
                var unitConversion = item.unitConversion;
                updateTile(item.qty, `.itemquantity-${item.id} .quantity`, secondaryMeasurementUnit, unitConversion, item.id);
            }
        });
    }
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.add-to-cart').data('pid');
}

/**
 * Retrieves the relevant uuid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getUuidValue($el) {
    return $($el).closest('.add-to-cart').data('uuid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-update-quantity');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="'}${data.maxBonusItems}"` +
        `data-UUID="${data.uuid}"` +
        `data-pliUUID="${data.pliUUID}"` +
        `data-addToCartUrl="${data.addToCartUrl}"` +
        'data-pageStart="0"' +
        `data-pageSize="${data.pageSize}"` +
        `data-moreURL="${data.showProductsUrlRuleBased}"` +
        `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">` +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `    <span class="">${data.labels.selectprods}</span>` +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    $('.minicart').trigger('count:update', response);
    if (response && response.cart) {
        if (response.cart.items) {
            const pid = el.closest('.add-to-cart').data('pid');
            const products = response.cart.items.filter(item => item.id === pid);
            if (products && products.length > 0) {
                el.closest('.add-to-cart').find('.update-quantity').addClass('show');
                el.closest('.add-to-cart').parents().closest('.product-tile')
                    .find('.promotion-badge-approaching, .tile-body')
                    .addClass('promo-active');
                el.removeClass('show');
            }
        }

        // Update free shipping message
        $('#freeShippingMessage').empty();
        if (response.cart.freeShippingMessage) {
            $('#freeShippingMessage').append(response.cart.freeShippingMessage);
        }
        if (response.cart.isPromoTriggered) {
            $('#freeShippingMessage').addClass('triggeredFree');
            if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                $(Selector.minicart).addClass(Class.cartFreeShipping);
            }
        } else {
            $('#freeShippingMessage').removeClass('triggeredFree');
            $(Selector.minicart).removeClass(Class.cartFreeShipping);
        }
    }

    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (response.error) {
        if ($('.add-to-cart-messages').length === 0) {
            $('.js-minicart-container').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(`<div class="alert alert-danger add-to-basket-alert" role="alert">${
            response.message
        }</div>`);

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Save legal age confirmation for authenticated customer
* @param {string} url - endpoint url
 */
function saveAgeConfirmation(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // age confirmation saved in customer profile
            }
        });
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    if (response && response.items && response.items) {
        let product;
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.items.filter(item => item.id === pid);
        if (products && products.length > 0) {
            product = products.pop();
            if (product.unitConversion) {
                var weight = product.quantity * product.unitConversion;
                el.closest('.add-to-cart').find('.update-quantity .quantity').html(parseFloat(Number(weight).toFixed(2)));
            } else {
                el.closest('.add-to-cart').find('.update-quantity .quantity').html(product.quantity);
            }
        }
        // Update free shipping message
        $('#freeShippingMessage').empty();
        if (response.freeShippingMessage) {
            $('#freeShippingMessage').append(response.freeShippingMessage);
        }
        if (response.isPromoTriggered) {
            $('#freeShippingMessage').addClass('triggeredFree');
            if (!$(Selector.minicart).hasClass(Class.cartFreeShipping)) {
                $(Selector.minicart).addClass(Class.cartFreeShipping);
            }
        } else {
            $('#freeShippingMessage').removeClass('triggeredFree');
            $(Selector.minicart).removeClass(Class.cartFreeShipping);
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Continue to add product to cart after conditions are met
 * @param {*} el - Add to cart button
 * @param {string} confirmAgeUrl - endpoint url for saving legal age confirmation in customer profile (optional)
 *
 */
function continueAddToCart(el, confirmAgeUrl) {
    $('body').trigger('product:beforeAddToCart', el);
    var pid = getPidValue($(el));
    var uuid = getUuidValue($(el));
    var $tile = $(el).closest('div.product-tile');
    var addToCartUrl = getAddToCartUrl(el);
    var selectedMeasurementUnit = $tile.find('.measurement-unit-slider-input-tile');
    var secondaryMeasurementUnit = $tile.find(`#secondaryUnitMeasure-${pid}`);
    var quantity = selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary' ? 0.5 : $(secondaryMeasurementUnit).data('unitconversion');

    var form = {
        pid,
        uuid,
        quantity: quantity
    };

    if (selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary') {
        form.secondaryMeasurementUnit = $(secondaryMeasurementUnit).data('unitlaw');
        form.unitConversion = $(secondaryMeasurementUnit).data('unitconversion');
    } else if (selectedMeasurementUnit.length > 0) {
        var primaryMeasurementUnit = $tile.find(`#primaryUnitMeasure-${pid}`);
        form.unitConversion = $(primaryMeasurementUnit).data('unitconversion');
    }

    checkStore().then(({ noStore }) => {
        if (addToCartUrl && !noStore) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success(data) {
                    base.updateMinicartView();
                    handlePostCartAdd(data, el);
                    cartHelper.updateCartTotals(data.cart);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                    if (confirmAgeUrl) {
                        saveAgeConfirmation(confirmAgeUrl);
                    }
                    $('body').trigger('productList:removeClassTextMuted');
                    $(`input.measurement-unit-slider-input-${pid}`).each(function () {
                        if (secondaryMeasurementUnit && selectedMeasurementUnit.val() === 'secondary') {
                            $(this).prop('checked', true);
                            $(this).val('secondary');
                        } else {
                            $(this).prop('checked', false);
                            $(this).val('primary');
                        }
                    });
                },
                error() {
                    $.spinner().stop();
                }
            });
        }
    });
}

module.exports = {
    addToCart() {
        $(document).on('click', 'button.tile-add-to-cart', function () {
            var $el = $(this);

            var showConfirmationLegalNotice = $el.data('showconfirmationlegalnotice');
            var isAlcoholicProduct = $el.data('isalcoholicproduct');
            var isAlcoholRestrictedTime = $el.data('isalcoholrestrictedtime');
            var hasShowedConfirmationLegalNotice = window.sessionStorage.getItem('hasShowedConfirmationLegalNotice');
            var disableAddToCart = window.sessionStorage.getItem('disableAddToCart');
            var popupContent = $('.popup-temporary-container').html();
            var $minicartViewWrapper = $('.js-minicart-container');

            if (isAlcoholRestrictedTime) {
                $('#alcoholRestrictionModal').modal('show');
            } else if (showConfirmationLegalNotice && !JSON.parse(hasShowedConfirmationLegalNotice)) {
                $('#confirmationLegalNoticeModal').modal('show');
                $('.under-age').on('click', function () {
                    $('#confirmationLegalNoticeModal').modal('hide');
                });
                var $confirmAgeBtn = $('.confirm-age');
                if ($confirmAgeBtn.length > 0) {
                    $confirmAgeBtn.on('click', function () {
                        window.sessionStorage.setItem('hasShowedConfirmationLegalNotice', true);
                        continueAddToCart($el, $confirmAgeBtn.data('url'));
                        $('.confirmation-legal-notice .close-button').trigger('click');

                        if (localStorage.getItem('storeSelected') === 'true' && !localStorage.getItem('userSelected') && !localStorage.getItem('transactionShown')) {
                            $minicartViewWrapper.append(popupContent);
                            $('.js-minicart-container .transaction-popup-wrapper').show();
                            localStorage.setItem('transactionShown', 'true');
                        }
                    });
                }
            } else if (isAlcoholicProduct && JSON.parse(disableAddToCart)) {
                $(this).css('pointer-events', 'none');
            } else {
                continueAddToCart($el);

                if (localStorage.getItem('storeSelected') === 'true' && !localStorage.getItem('userSelected') && !localStorage.getItem('transactionShown')) {
                    $minicartViewWrapper.append(popupContent);
                    $('.js-minicart-container .transaction-popup-wrapper').show();
                    localStorage.setItem('transactionShown', 'true');
                }
            }
        });
    },
    changeMeasurementUnit() {
        $(document).on('change', '.measurement-unit-slider-input-tile', function () {
            $.spinner().start();
            var $el = $(this);
            var $gridTile = $el.closest('div.product-tile');
            var $searchTile = $el.closest('div.product-tile-suggestions');
            var $tile = $gridTile.length ? $gridTile : $searchTile;
            var pid = $tile.find('.add-to-cart').data('pid');
            if ($('.add-to-cart[data-pid=' + pid + ']').attr('data-quantity') > 0) {
                $('.cart-delete-confirmation-btn[data-pid=' + pid + ']').trigger('click');
            }
            $('.add-to-cart[data-pid=' + pid + ']').attr('data-quantity', 0);
            if (!$el.prop('checked') === true) {
                $el.prop('checked', false);
                $el.val('primary');
            } else {
                $el.prop('checked', true);
                $el.val('secondary');
            }

            $.spinner().stop();
        });
    },
    updateQuantityCart() {
        $(document).on('click', 'button.tile-update-quantity', function () {
            var $el = $(this);
            var $tile = $el.closest('div.product-tile');
            var selectedMeasurementUnit = $tile.find('.measurement-unit-slider-input-tile');
            $('body').trigger('product:beforeAddToCart', this);

            var pid = getPidValue($(this));
            var uuid = getUuidValue($el);
            var secondaryMeasurementUnit = $tile.find(`#secondaryUnitMeasure-${pid}`);
            const updateQuantityUrl = getUpdateQuantityUrl($(this));
            const decrease = $(this).data('decrease');
            var updateQuantityStep = 1;

            if (selectedMeasurementUnit.length > 0) {
                updateQuantityStep = Number(selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary' ? 0.5 : $(secondaryMeasurementUnit).data('unitconversion').toFixed(2));
            }

            var currentQuantity = parseFloat($(this).closest('.add-to-cart').attr('data-quantity'));
            var targetQuantity = decrease ? currentQuantity - updateQuantityStep : currentQuantity + updateQuantityStep;
            if (targetQuantity <= 0) {
                $('.cart-delete-confirmation-btn[data-pid=' + pid + ']').trigger('click');
                $('.add-to-cart[data-pid=' + pid + ']').attr('data-quantity', targetQuantity);
                $.spinner().stop();
                return;
            }

            const form = {
                pid,
                decrease,
                uuid,
                quantity: targetQuantity.toFixed(2)
            };

            if (selectedMeasurementUnit.length > 0 && selectedMeasurementUnit.val() === 'secondary') {
                form.secondaryMeasurementUnit = $(secondaryMeasurementUnit).data('unitlaw');
                form.unitConversion = $(secondaryMeasurementUnit).data('unitconversion');
            } else if (selectedMeasurementUnit.length > 0) {
                var primaryMeasurementUnit = $tile.find(`#primaryUnitMeasure-${pid}`);
                form.unitConversion = $(primaryMeasurementUnit).data('unitconversion');
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (updateQuantityUrl) {
                $.ajax({
                    url: updateQuantityUrl,
                    method: 'GET',
                    data: form,
                    success(data) {
                        base.updateMinicartView(function () {}, decrease);
                        $('body').trigger('product:afterAddToCart', data);
                        if (data && data.basket && data.basket.items) {
                            const id = $el.closest('.add-to-cart').data('pid');
                            const products = data.basket.items.filter(item => item.id === id);
                            if (products.length === 0) {
                                $el.closest('.add-to-cart').find('.update-quantity').removeClass('show');
                                $el.closest('.add-to-cart').parents().closest('.product-tile').find('.promotion-badge-approaching, .tile-body')
                                    .removeClass('promo-active');
                                $el.closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
                            }
                        } else if (data && data.items) {
                            updateQuantity(data, $el);
                            cartHelper.updateCartTotals(data);
                        }
                        $('.cart-json').data('cart', data.iteminfos);
                        $('body').trigger('cart:update');

                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    updateMinicart() {
        $('body').on('cart:update', () => {
            updateQuantities();
        });
    },
    addSelectedElementsToBasket() {
        $('body').on('click', Selector.addToCartButton, (evt) => {
            let el = $(evt.currentTarget);
            let parent = el.closest(Selector.productsSlider);
            let productsList = $(parent).find(Selector.productTile);
            let pidsList = [];
            let pidsChecklist = [];

            productsList.each((i, tile) => {
                let tileCheckbox = $(tile).find(Selector.selectionCheckbox);
                let tilePid = $(tileCheckbox).val();
                // add checklist to avoid duplicating the products pids since they are inside a slider that duplicates the tiles
                if ($(tileCheckbox).is(':checked') && !pidsChecklist.includes(tilePid)) {
                    pidsList.push({
                        pid: tilePid,
                        qty: 1
                    });
                    pidsChecklist.push(tilePid);
                }
            });

            let pidsObj = JSON.stringify(pidsList);
            let addToCartUrl = $(parent).find(Selector.addToCartUrl).val();

            $('body').trigger('product:beforeAddToCart', el);

            const form = {
                pid: '',
                pidsObj,
                uuid: '',
                quantity: 0
            };

            checkStore().then(({ noStore }) => {
                if (addToCartUrl && !noStore) {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success(data) {
                            base.updateMinicartView();
                            handlePostCartAdd(data, el);
                            cartHelper.updateCartTotals(data.cart);
                            $('body').trigger('product:afterAddToCart', data);
                            $.spinner().stop();
                            $('body').trigger('productList:removeClassTextMuted');
                        },
                        error() {
                            $.spinner().stop();
                        }
                    });
                }
            });
        });
    },
    tileLoad: updateQuantities
};
