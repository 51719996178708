'use strict';

/**
 * Create the jQuery Сonsensus plugin.
 *
 * This jQuery plugin will be registered on given element.
 *
*/

(function (factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) { // eslint-disable-line
        define(['jquery'], factory); // eslint-disable-line
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    const UPDATE_EVENT_NAME = 'consensus:update';
    const CHECK_EVENT_NAME = 'consensus:check';

    var methods = {
        init: function (options) {
            var updateEvent = options && options.namespace ? UPDATE_EVENT_NAME + '.' + options.namespace : UPDATE_EVENT_NAME;
            var checkEvent = options && options.namespace ? CHECK_EVENT_NAME + '.' + options.namespace : CHECK_EVENT_NAME;
            var self = this;

            return this.each(function () {
                var $this = $(this);
                var data = $this.data('consensus');

                if (!data) {
                    $(document.body).on(updateEvent, (event, trigData) => { methods.updateState.call(self, trigData); });
                    $(document.body).on(checkEvent, (event, trigData) => { methods.check.call(self, trigData); });

                    $this.data('consensus', {
                        options: options
                    });

                    methods.check.call(self);
                }
            });
        },
        updateState: function (data) {
            var $this = $(this);
            var pluginData = $this.data('consensus');

            if (pluginData.options && pluginData.options.members) {
                var members = pluginData.options.members;
                var $element;
                var newState;
                for (var key in data) { // eslint-disable-line
                    if (Object.hasOwnProperty.call(members, key)) {
                        $element = $(members[key]);
                        newState = data[key];
                        $element.attr('data-consensus-mbr', newState).data('consensusMbr', newState);
                    }
                }
            }

            methods.check.call(this);
        },
        check: function () {
            var $this = $(this);
            var pluginData = $this.data('consensus');
            var result = true;

            if (pluginData.options && pluginData.options.members) {
                var elements = pluginData.options.members;
                var $element;
                var elementData;

                for (var key in elements) { // eslint-disable-line
                    if (Object.hasOwnProperty.call(elements, key)) {
                        $element = $(elements[key]);

                        if ($element.length) {
                            elementData = $element.data('consensusMbr');
                            if (typeof elementData === 'undefined') {
                                result = false;
                                $element.attr('data-consensus-mbr', 'false').data('consensusMbr', false);
                            } else {
                                result = result && elementData;
                            }
                        } else {
                            $.error('No element "' + key + '" with selector:' + elements[key]);
                        }
                    }
                }
            }

            if (!pluginData.options.skipDisabling) {
                $this.prop('disabled', !result);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    $.fn.consensus = function (method) {
        // methods call logic
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object') {
            return methods.init.apply(this, arguments);
        } else if (!method) {
            $.error('Initializin parameters required');
        } else {
            $.error('No method with name: ' + method + ' for jQuery.consensus');
        }
    };
}));
