'use strict';

const Selector = {
    button: '.js-edit-order',
    b2bOrderEditModal: '#b2b-orderedit-modal'
};

const bindEvent = () => {
    $(Selector.button).on('click', (evt) => {
        evt.preventDefault();
        const $button = $(Selector.button);
        const href = $button.data('href');
        const url = $button.data('url');

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                let cartTotal = data.grossTotal;
                let b2bPoints = data.b2bPoints;
                $.spinner().stop();

                // if it's not a b2b customer
                if (!b2bPoints) {
                    window.location.href = href;
                } else if (b2bPoints && (b2bPoints > cartTotal)) {
                    window.location.href = href;
                } else {
                    $(Selector.b2bOrderEditModal).modal('show');
                }
            },
            error() {
                $.spinner().stop();
            }
        });
    });
};

module.exports = () => {
    bindEvent();
};
