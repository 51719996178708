
const formValidation = require('base/components/formValidation');
const B2B_FORM_SUBMIT_SELECTOR = '.business-form-btn';
const B2B_TOGGLE_BTN_SELECTOR = '.business-toggle-btn';
const B2B_FORM_SELECTOR = '#b2bForm';

/**
 * Toggle wrapper
 * @param {string} target - target selector
 */
function toogleWrapper(target) {
    $(target).slideToggle();
}

/**
 * ajax request
 * @param {string} url - url for request
 * @param {Object} data - parameters for share
 * @param {Function} callback - callback function
 */
function request(url, data, callback) {
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function (res) {
            callback(res);
        },
        error: function (error) {
            console.log(error); // eslint-disable-line
        }
    });
}

/**
 * Save form function
 */
function saveFormData() {
    const $form = $(B2B_FORM_SELECTOR);
    request(
        $form.attr('action'),
        $form.serialize(),
        (res) => {
            if (!res.success) {
                const form = document.querySelectorAll(B2B_FORM_SELECTOR)[0];
                formValidation(form, res);
            } else {
                window.location.href = res.redirectUrl;
            }
        }
    );
}

/**
 * Init all events function
 */
function initEvents() {
    $(document).on('click', B2B_FORM_SUBMIT_SELECTOR, (e) => {
        e.preventDefault();
        saveFormData();
    });

    $(document).on('click', B2B_TOGGLE_BTN_SELECTOR, (e) => {
        e.preventDefault();
        const toogleTarget = $(e.target).data('target');
        toogleWrapper(toogleTarget);
    });

    // Form should be hidden by default
    $($(B2B_TOGGLE_BTN_SELECTOR).data('target')).hide();
}

module.exports = {
    init: function () {
        initEvents();
    }
};
