
window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('./dashboard/dashboard'));
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base_store/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('sunnie_foods/components/scheduledorder'));
    processInclude(require('base_store/storeLocator/selectStoreModal'));
    processInclude(require('./storeLocator/storePopup'));
    processInclude(require('./storeLocator/storeLocator'));
    processInclude(require('sunnie_foods/components/sunnieFoodsHeader'));
    processInclude(require('./common'));
    processInclude(require('./product/tile'));
    processInclude(require('./login'));
    processInclude(require('./delivery'));
    processInclude(require('./components/carousels'));
    processInclude(require('./productTile'));
    processInclude(require('./components/about'));
    processInclude(require('build_merger/shoppingList'));
    processInclude(require('./components/addressAutocomplete'));
    processInclude(require('./modalBgScroll'));
    processInclude(require('./clubForm'));
    processInclude(require('./business'));
    processInclude(require('./giftCertificate'));
    processInclude(require('./experience/recipeDetail'));
    processInclude(require('./cart/editOrder'));
    processInclude(require('./search/searchSuggestions'));
    processInclude(require('./gtm'));
});

require('sunnie_foods/thirdParty/bootstrap');
require('./thirdparty/jquery-ui');
require('./thirdparty/jquery.vEllipsis.min');
require('base_store/components/spinner');
require('slick-carousel/slick/slick.min');
require('share-buttons/dist/share-buttons');
require('js-cloudimage-360-view');
require('./components/consensusPlugin');
