// imports section
const utils = require('../helpers/utils');
const ADDRESS_RANGE_LIST = require('../../components/addressAutocomplete').config.address_range;

const DROPDOWN_WRAPPER_CLASS = 'dropdown-list-wrapper';
const FORM_ID_INPUT_CLASS = 'user-address-id';
const FIELDS_TYPE = {
    CITY: 'city',
    STREET: 'street',
    NUMBER: 'number'
};

/**
 * Detect value changes
 * @param {Array} inputsList - elements array
 * @param {Object} state - prev object state
 * @return {boolean} - true if data was changed
 */
function wasChanged(inputsList, state) {
    let changed = false;
    inputsList.forEach((input) => {
        const id = input.id;
        if (state[id] !== input.value) {
            changed = true;
        }
    });
    return changed;
}

/**
 * Filter array for duplicated label
 * @param {Array} list - input array
 * @return {Array} - filtered data
 */
function filterObject(list) {
    return list.reduce((accumulator, currentValue) => {
        const itemsQuantity = accumulator.filter(item => item.label === currentValue.label);
        if (!itemsQuantity.length) {
            accumulator.push(currentValue);
        }
        return accumulator;
    }, []);
}

class AutocompleteForm {
    static build(data) {
        return new AutocompleteForm()
            .setFieldsClass(data.fieldsClass)
            .setGoogleApiService(data.GoogleApiService)
            .setGoogleGeocoderApiService(data.GoogleGeocoderApiService)
            .setFieldDropdownComponent(data.FieldDropdown)
            .setFieldsWrapperClass(data.fieldsWrapperClass)
            .setCoordinatesClass(data.coordinatesClass)
            .setFormId(data.formId)
            .setIdInput()
            .initEvents()
            .setInitCoordinates();
    }

    setFormId(formId) {
        this.formId = formId;
        return this;
    }

    setGoogleGeocoderApiService(GoogleGeocoderApiService) {
        this.GoogleGeocoderApiService = GoogleGeocoderApiService;
        return this;
    }

    setGoogleApiService(GoogleApiService) {
        this.GoogleApiService = GoogleApiService;
        return this;
    }

    setCoordinatesClass(coordinatesClass) {
        this.coordinatesClass = coordinatesClass;
        return this;
    }

    setFieldDropdownComponent(FieldDropdown) {
        this.FieldDropdown = FieldDropdown;
        return this;
    }

    setFieldsState(inputsList) {
        if (!this.fieldsState) this.fieldsState = {};
        inputsList.forEach((input) => {
            this.fieldsState[input.id] = input.value.trim();
        });

        if (this.idInput.dataset.edit !== 'true' && !this.idInput.value) {
            this.idInput.value = `ad_${new Date().getTime()}`;
        }

        return this;
    }

    setFieldsWrapperClass(fieldsWrapperClass) {
        this.fieldsWrapperClass = fieldsWrapperClass;
        return this;
    }

    /* eslint-disable class-methods-use-this */
    getPlaceCoordinates(place) {
        if (!place.geometry) {
            return {
                lat: null,
                lng: null
            };
        }

        const coordinates = place.geometry.location;

        return {
            lat: coordinates.lat(),
            lng: coordinates.lng()
        };
    }
    /* eslint-enable class-methods-use-this */

    setCoordinatesData(placeId) {
        this.GoogleGeocoderApiService.geocode({ placeId: placeId }, (results) => {
            const coordinates = this.getPlaceCoordinates(results[0]);
            const latSelector = document.querySelectorAll(`#${this.formId} .${this.coordinatesClass.LAT}`)[0];
            const lngSelector = document.querySelectorAll(`#${this.formId} .${this.coordinatesClass.LNG}`)[0];
            latSelector.value = coordinates.lat;
            lngSelector.value = coordinates.lng;

            if (window.formToLoad) {
                window.formToLoad.submit();
            }
        });
    }

    setIdInput() {
        this.idInput = document.querySelectorAll(`#${this.formId} .${FORM_ID_INPUT_CLASS}`)[0];
        return this;
    }

    setFiledsListWrapper(target) {
        const wrapper = utils.getParentWithClass(target, this.fieldsWrapperClass);
        const fieldListClass = `dropdown-${target.id}-wrapper`;
        const fieldListWrapper = wrapper.querySelectorAll(`.${fieldListClass}`);

        if (fieldListWrapper.length) {
            this.fieldListWrapper = fieldListWrapper[0];
            return false;
        }

        this.fieldListWrapper = document.createElement('div');
        this.fieldListWrapper.setAttribute('class', `${fieldListClass} ${DROPDOWN_WRAPPER_CLASS}`);
        wrapper.appendChild(this.fieldListWrapper);
        return this;
    }

    setFieldsClass(fieldsClass) {
        this.fieldsClass = fieldsClass;
        return this;
    }

    getFieldPrediction(value, callback, type = []) {
        let options = {};
        if (type.length) {
            options = {
                types: type
            };
        }

        this.GoogleApiService.getPlacePredictions({
            input: value,
            componentRestrictions: {
                country: ADDRESS_RANGE_LIST
            },
            ...options
        }, (predictions, status) => {
            /* eslint-disable */
            if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
                console.log(status);
                return;
            }
            /* eslint-enable */

            callback(predictions);
        });
    }

    /* eslint-disable */
    getValueByType(name, fieldsList) {
        return Array.from(fieldsList).filter(field => field.dataset.type === name)[0].value;
    }
    /* eslint-enable */

    showPredition(target, inputsList) {
        let value = target.value;
        if (!value) {
            this.destroyDropdown(target);
            return;
        }

        const type = target.dataset.type;
        let predictionType = [];

        if (type === FIELDS_TYPE.CITY) {
            predictionType = ['(cities)'];
        } else if (type === FIELDS_TYPE.STREET) {
            const city = this.getValueByType(FIELDS_TYPE.CITY, inputsList);
            predictionType = ['address'];
            const streetPrefix = 'street';
            value = `${city}, ${streetPrefix} ${target.value}`;
        } else if (type === FIELDS_TYPE.NUMBER) {
            const city = this.getValueByType(FIELDS_TYPE.CITY, inputsList);
            const street = this.getValueByType(FIELDS_TYPE.STREET, inputsList);
            predictionType = ['address'];
            const streetPrefix = 'street';
            value = `${city}, ${streetPrefix} ${street}, ${target.value}`;
        }

        this.getFieldPrediction(value, (predictions) => {
            const dataForRender = this.processDataForRender(predictions);
            this.renderFieldPredictions(dataForRender, target);
        }, predictionType);
    }

    /* eslint-disable */
    processDataForRender(predictions) {
        return predictions.map((prediction) => {
            return {
                place_id: prediction.place_id,
                label: prediction.structured_formatting.main_text
            }
        });
    }
    /* eslint-enable */

    cleanField(name, fieldsList) {
        const field = Array.from(fieldsList).filter(filterField => filterField.dataset.type === name)[0];
        field.value = '';
        return this;
    }

    blockField(name, fieldsList) {
        const field = Array.from(fieldsList).filter(filterField => filterField.dataset.type === name)[0];
        field.setAttribute('disabled', 'disabled');
        this.cleanField(name, fieldsList);
        return this;
    }

    unblockField(name, fieldsList) {
        const field = Array.from(fieldsList).filter(filterField => filterField.dataset.type === name)[0];
        field.removeAttribute('disabled');
        this.cleanField(name, fieldsList);
        return this;
    }

    stepFirst() {
        const fieldsList = document.querySelectorAll(`.${this.fieldsClass}`);
        const blockList = [FIELDS_TYPE.STREET, FIELDS_TYPE.NUMBER];

        this.unblockField(FIELDS_TYPE.CITY, fieldsList);

        blockList.forEach(field => {
            this.blockField(field, fieldsList);
        });
    }

    stepSecond() {
        const fieldsList = document.querySelectorAll(`.${this.fieldsClass}`);
        const blockList = [FIELDS_TYPE.NUMBER];

        this.unblockField(FIELDS_TYPE.STREET, fieldsList);

        blockList.forEach(field => {
            this.blockField(field, fieldsList);
        });
    }

    stepThree() {
        const fieldsList = document.querySelectorAll(`.${this.fieldsClass}`);

        this.unblockField(FIELDS_TYPE.NUMBER, fieldsList);
    }

    destroyDropdown(target) {
        const warpper = utils.getParentWithClass(target, this.fieldsWrapperClass);
        const fieldListClass = `dropdown-${target.id}-wrapper`;
        this.fieldListWrapper = warpper.querySelectorAll(`.${fieldListClass}`)[0];
        this.cleanDropdownWrapper();
    }

    cleanDropdownWrapper() {
        if (this.fieldListWrapper) {
            this.fieldListWrapper.innerHTML = '';
        }
        return this;
    }

    /* eslint-disable */
    getStep(target, name) {
        return target.dataset.type === name;
    }
    /* eslint-enable */

    renderFieldPredictions(renderList, target) {
        let renderElement = renderList;
        this.FieldDropdown.setId(`dropdown-${target.id}`);
        this.FieldDropdown.setSelectItemCallback((value, item) => {
            this.destroyDropdown(target);
            this.setInputValue(target, value);

            if (this.getStep(target, FIELDS_TYPE.CITY)) {
                this.stepSecond();
            }

            if (this.getStep(target, FIELDS_TYPE.STREET)) {
                this.stepThree();
            }

            if (this.getStep(target, FIELDS_TYPE.NUMBER)) {
                this.setCoordinatesData(item.dataset.placeId);
            }

            const inputsList = document.querySelectorAll(`.${this.fieldsClass}`);
            this.setFieldsState(inputsList);
        });

        if (this.getStep(target, FIELDS_TYPE.NUMBER)) {
            renderElement = renderElement
                .map(renderItem => {
                    renderItem.label = renderItem.label.replace(/^\D+/g, '');
                    return renderItem;
                });

            renderElement = filterObject(renderElement);
        }

        renderElement = [...new Set(renderElement)].filter(item => item.label !== '');

        const list = this.FieldDropdown.renderList(renderElement, true);
        this.setFiledsListWrapper(target);
        this.cleanDropdownWrapper();

        if (list) {
            this.fieldListWrapper.appendChild(list);
        }
    }

    setInitCoordinates() {
        const predictionType = ['address'];
        const inputsList = document.querySelectorAll(`.${this.fieldsClass}`);
        const city = this.getValueByType(FIELDS_TYPE.CITY, inputsList);
        const street = this.getValueByType(FIELDS_TYPE.STREET, inputsList);
        const number = this.getValueByType(FIELDS_TYPE.NUMBER, inputsList);
        const streetPrefix = 'street';
        const value = `${city}, ${streetPrefix} ${street}, ${number}`;

        this.getFieldPrediction(value, (predictions) => {
            this.setCoordinatesData(predictions[0].place_id);
        }, predictionType);
        return this;
    }

    setInputValue(target, value) {
        target.value = value;
        return this;
    }

    initEvents() {
        const inputsList = document.querySelectorAll(`.${this.fieldsClass}`);
        this.setFieldsState(inputsList);

        if (this.idInput.dataset.edit !== 'true') {
            this.stepFirst();
        }

        inputsList.forEach(input => {
            input.addEventListener('keyup', (event) => {
                const target = event.target;
                if (wasChanged(inputsList, this.fieldsState)) {
                    this.setFieldsState(inputsList);
                    this.showPredition(target, inputsList);
                }
            });

            input.addEventListener('focus', (event) => {
                const target = event.target;
                this.showPredition(target, inputsList);
            });
        });

        return this;
    }
}

module.exports = {
    AutocompleteForm: AutocompleteForm
};
