'use strict';

var slickConfigs = require('../config/slickConfigs');
var breakpoints = require('../utils/breakpoints');

module.exports = {
    sliderTop: function () {
        $('.sl').attr('dir', 'rtl');
        $('.sl').slick(slickConfigs.sliderTop);
    },
    sliderBottom: function () {
        $('.sl2').attr('dir', 'rtl');
        $('.sl2').slick(slickConfigs.sliderBottom);
    },
    circleSlider: function () {
        $('.round-slider').attr('dir', 'rtl');
        $('.round-slider').slick(slickConfigs.circleSlider);
    },
    productsNewSlider: function () {
        $('.new-products-slider').attr('dir', 'rtl');
        $('.new-products-slider').slick(slickConfigs.productsNewSlider);
    },
    accountDashboard: function () {
        $('.order-tile-list-carousel').attr('dir', 'rtl');
        $('.order-tile-list-carousel').slick(slickConfigs.accountDashboard);
    },
    accountDashboardShoppingList: function () {
        $('.order-shoping-list-carousel').attr('dir', 'rtl');
        $('.order-shoping-list-carousel').slick(slickConfigs.accountDashboardShopping);
    },
    accountDashboardClubMember: function () {
        $('.club-member-carousel').attr('dir', 'rtl');
        $('.club-member-carousel').slick(slickConfigs.accountDashboardClub);
    },
    accountDashboardCoupons: function () {
        $('.account-coupons-carousel').attr('dir', 'rtl');
        $('.account-coupons-carousel').slick(slickConfigs.accountDashboardCoupons);
    },
    quickViewDiscount: function () {
        $('.product-qv-carousel').attr('dir', 'rtl');
        $('.product-qv-carousel').slick(slickConfigs.quickViewDiscount);
    },
    quickViewRecommendation: function () {
        $('.product-carousel-slot-wrapper').attr('dir', 'rtl');
        $('.product-carousel-slot-wrapper').slick(slickConfigs.quickViewRecommendation);
    },
    recommendationsSlider: function () {
        $('.cart-slider').attr('dir', 'rtl');
        $('.cart-slider').slick(slickConfigs.cartSlider);
    },
    addressesSlider: function () {
        $('.js-carousel-addresses').attr('dir', 'rtl');
        $('.js-carousel-addresses').slick(slickConfigs.addressesSlider);
    },
    cardsSlider: function () {
        var $cards = $('.js-cards-carousel').eq(0);
        if ($cards.find('.user-credit-card-tile-wrapper').length > 1 || $cards.find('.saved-payment-information').length > 1) {
            $('.js-cards-carousel').attr('dir', 'rtl');
            $('.js-cards-carousel').slick(slickConfigs.cardSlider);
        }
    },
    slotsSlider: function () {
        $('.js-carousel-slots').attr('dir', 'rtl');
        $('.js-carousel-slots').slick(slickConfigs.slotsSlider);
    },
    ingredientsSlider: function () {
        $('.js-ingredients-slider').attr('dir', 'rtl');
        $('.js-ingredients-slider').slick(slickConfigs.ingredientsCarousel);
    },
    recipesSlider: function () {
        $('.js-recipes-slider').attr('dir', 'rtl');
        $('.js-recipes-slider').slick(slickConfigs.recipesCarousel);
    },
    productsSlider: function () {
        if (document.body.clientWidth > breakpoints.lg) {
            $('.js-products-slider').attr('dir', 'rtl');
            $('.js-products-slider').slick(slickConfigs.productsCarousel);
        }
    }
};
