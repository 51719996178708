module.exports = {

    init : function () {
        var modalContainer = $("#shopinglistmodal-container");
        if (!modalContainer.length) {
            $("body").append($("<div>", {"id": "shopinglistmodal-container"}));
        }
    },
    initEvents: function () {
        var addtoshoppinglist = $(".add-to-shopinglist");

        addtoshoppinglist.on("click", function () {
            var url = $(this).data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    var container = $("#shopinglistmodal-container");
                    container.html(data);
                    $("#shopinglistodal").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                }
            });
        });
    },
    pdpCreateNewList: function () {
        $(document).on("click", ".add-to-shopinglist-newlist", function () {
            $("#shopinglistodal").modal("hide");
            var url = $(this).data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    var container = $("#shopinglistmodal-container");
                    container.html(data);
                    $("#shopinglistodalnewlist").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                }
            });
        });
    },
    pdpAddtoList: function () {
        $(document).on("click", ".add-to-shopinglist-modal", function () {
            $("#shopinglistodalnewlist").modal("hide");
            var url = $(this).data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    var container = $("#shopinglistmodal-container");
                    container.html(data);
                    $("#shopinglistodal").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                }
            });
        });
    },
    handleProductAddToList : function () {
        $(document).on("submit", ".add-product-to-list-form", function (e) {
            e.preventDefault();
            var $this = $(this);
            $.ajax({
                url: $this.attr("action"),
                type: "post",
                data: $this.serialize(),
                success: function (data) {
                    if (data.success === "Ok") {
                        $("#shopinglistodal").modal("hide");
                    }
                }
            });
        });
    },
    handleAddNewList: function () {
        $(document).on("submit", ".add-new-shopping-list", function (e) {
            e.preventDefault();
            var $this = $(this);
            $.ajax({
                url: $this.attr("action"),
                type: "post",
                data: $this.serialize(),
                success: function (data) {
                    if (data.success === "OK") {
                        if ($(".add-new-shopping-list").hasClass("pdp")) {
                            $("#shopinglistodalnewlist").modal("hide");
                            if (data.pid) {
                                $.ajax({
                                    url: data.addProductUrl,
                                    type: "post",
                                    dataType: "json",
                                    data: {pid: data.pid, url:data.addProductUrl, listName:data.listName, csrf_token:data.csrf_token},
                                });
                            }
                        } else {
                            window.location.href = data.redirectUrl;
                        }
                    } else {
                        if ($(".add-to-cart-messages").length === 0) {
                            $("body").append("<div class=\"add-to-cart-messages\"></div>");
                        }
                        $(".add-to-cart-messages").append(
                            "<div class=\"alert alert-danger add-to-basket-alert text-center\""
                            + " role=\"alert\">"
                            + data.errorMessage + "</div>"
                        );
                        setTimeout(function () {
                            $(".add-to-basket-alert").remove();
                        }, 3000);
                    }
                }
            });
        });
    },
    removeListPopUp: function () {
        $(document).on("click", ".remove-list", function (e) {
            e.preventDefault();
            var $this = $(this);
            if ($this.data("hasitem")) {
                var $deleteConfirmBtn = $(".cart-delete-confirmation-btn");
                $deleteConfirmBtn.data("listid", $this.data("listid"));
                $deleteConfirmBtn.data("action", $this.data("action"));
                $("#removeListModal").modal({
                    backdrop: "static",
                    keyboard: false
                });
            } else {
                var form = {
                    "listid" : $this.data("listid")
                };

                $.ajax({
                    url: $this.data("action"),
                    type: "post",
                    dataType: "json",
                    data: form,
                    success: function (data) {
                        if (data.success === "Ok") {
                            window.location.reload();
                        }
                    }
                });
            }
        });
    },
    handleSaveCart: function () {
        $(document).on("click", ".save-cart-submit", function (e) {
            e.preventDefault();
            var $this = $(this);
            $.ajax({
                url: $this.data("url"),
                type: "post",
                success: function (result) {
                    if (result.success === "Ok") {
                        $.ajax({
                            url: result.removeCartUrl,
                            type: "post",
                            success: function (data) {
                                if (data.success === "Ok") {
                                    window.location.reload();
                                }
                            }
                        });
                    }
                }
            });
        });
    },
    handleSaveCartModal: function () {
        $(document).on("click", ".save-cart-modal", function (e) {
            e.preventDefault();
            var url = $(this).data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    var container = $("#shopinglistmodal-container");
                    container.html(data);
                    $("#saveCartModal").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                }
            });
        });
    },
    handleRemoveList: function () {
        $(document).on("click", ".cart-delete-list", function (e) {
            e.preventDefault();
            var $this = $(this);

            var form = {
                "listid" : $this.data("listid")
            };

            $.ajax({
                url: $this.data("action"),
                type: "post",
                dataType: "json",
                data: form,
                success: function (data) {
                    if (data.success === "Ok") {
                        window.location.reload();
                    }
                }
            });
        });
    },
    handleRemoveProductList: function () {
        $(document).on("click", ".remove-product-from-list", function (e) {
            e.preventDefault();
            var $this = $(this);

            var form = {
                "listid" : $this.data("listid"),
                "uuid" : $this.data("uuid")
            };

            $.ajax({
                url: $this.data("action"),
                type: "post",
                dataType: "json",
                data: form,
                success: function (data) {
                    if (data.success === "Ok") {
                        window.location.reload();
                    }
                }
            });
        });
    },
    handleAddListToCart: function () {
        $(document).on("click", ".add-list-to-cart", function (e) {
            e.preventDefault();
            var $this = $(this);
            var listContainer = $this.parents(".list-container");
            var pids = [];

            listContainer.find(".add-to-cart-anywhere").each(function (index, item) {
                pids.push($(item).data("pid"));
            });

            var form = {
                "pids" : pids.toLocaleString()
            };

            $.ajax({
                url: $this.data("action"),
                type: "post",
                dataType: "json",
                data: form,
                success: function (data) {
                    if (data.success === "Ok") {
                        window.location.reload();
                    }
                }
            });
        });
    },
    handleRestoreSavedCart: function () {
        var shoppingListUtil = require("../shoppinglist/shoppingListUtil");
        shoppingListUtil.handleRestoreSavedCart();
    }
};
