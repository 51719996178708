/* eslint-disable quotes */
'use strict';


/**
* Push to window.gtm array
*
* @param {Object} currentGtmData gtm data.
*/

function pushToDataLayer(currentGtmData) {

    if (typeof currentGtmData === 'string') {

        var tempGtmData = JSON.parse(currentGtmData);

        if (tempGtmData.length > 0) {
            for (var i = 0; i < tempGtmData.length; i++) {
                window.dataLayer.push(JSON.parse(tempGtmData[i]));
            }
        } else {
            window.dataLayer.push(JSON.parse(tempGtmData));

        }

    } else if (currentGtmData && currentGtmData.length > 0) {

        for (var x = 0; x < currentGtmData.length; x++) {
            window.dataLayer.push(JSON.parse(currentGtmData[x]));
        }

    } else {

        window.dataLayer.push(JSON.parse(currentGtmData));

    }

}


/**
* Parse search query string
*
* @param {Object} search search query string.
* @return {Object} parsed query string
*/

function parseQuery(search) {

    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
    });

}


module.exports = function () {

    if (!window.gtmEnabled) {
        return false;
    }

    if (typeof(window.dataLayer) === 'undefined') {
        window.dataLayer = [];
    }

    if (typeof(window.gtmData) === 'undefined') {
        window.gtmData = [];
    }


    var gtmData = window.gtmData;


    for (var i = 0; i < gtmData.length; i++) {

        var tempGtmData = JSON.parse(gtmData[i]);

        window.dataLayer.push(tempGtmData);

    }


    window.onPromoClick = function (id, name, creative, position) {

        var promoObj = {

            event: 'promoClick',

            ecommerce: {

                promoClick: {

                    promotions: [{

                        id: id, // Name or ID is required.

                        name: name,

                        creative: creative,

                        position: position

                    }]

                }

            }

        };

        window.dataLayer.push(promoObj);

    };


    if ($('div.promoView').length) {

        $('.promoView').each(function () {

            window.onPromoClick($(this).data('promoid'), $(this).data('promoname'), $(this).data('promocreative'), $(this).data('promoposition'));

        });

    }


    $('body').on('stagechange', function (e, stageNumber) {

        if (stageNumber === 0) {

            var selectedShippingMethod;

            if ($('input[name=dwfrm_shipping_shippingAddress_shippingMethodID]:checked').val().indexOf('fedex') !== -1) {

                selectedShippingMethod = 'fedex';

            } else if ($('input[name=dwfrm_shipping_shippingAddress_shippingMethodID]:checked').val().indexOf('colissimo') !== -1) {

                selectedShippingMethod = 'colissimo';

            } else {

                selectedShippingMethod = 'homeDelivery';

            }

            window.dataLayer.push({

                event: 'checkout',

                ecommerce: {

                    checkout: {

                        actionField: {

                            step: 3,

                            option: selectedShippingMethod

                        }

                    }

                }

            });

            window.dataLayer.push({

                event: 'checkout',

                ecommerce: {

                    checkout: {

                        actionField: {

                            step: 4

                        }

                    }

                }

            });

        }


        if (stageNumber === 1) {

            window.dataLayer.push({

                event: 'checkout',

                ecommerce: {

                    checkout: {

                        actionField: {

                            step: 4,

                            option: $('.nav-link.active').text().trim()

                        }

                    }

                }

            });

        }


        if (stageNumber === 2) {

            window.dataLayer.push({

                event: 'checkout',

                ecommerce: {

                    checkout: {

                        actionField: {

                            step: 4

                        }

                    }

                }

            });

        }

    });


    if ($('div.gtm-content-asset').length) {

        $(window).on('load', function () {

            window.dataLayer.push({

                event: $('div.gtm-content-asset').data('event')

            });

        });

    }


    $('body').on('product:beforeAddToCart', function () {

        $(document).bind('ajaxSend', function (event, jqxhr, settings) {

            if ($('.product-set-detail').length) {

                var productSetId = $('.product-set-detail').data('pid');

                settings.data += '&productSetId=' + productSetId; // eslint-disable-line no-param-reassign

            }

        });

    });


    $('body').on('product:afterAddToCart', function (event, data) {

        try {

            pushToDataLayer(data.gtmData);

            // if (data.klData) {

            //     _learnq = _learnq || [];

            //     _learnq.push(["track", "Added to Cart", data.klData]);

            // }

        } catch (e) {

            // Fail silently if data cannot be pushed to data layer

        }

    });


    var gtmPlpRefine = '#ajaxGtmType=refine';

    var gtmPlpShowMore = '#ajaxGtmType=showmore';

    var cartUpdateQuantity = 'UpdateQuantity';

    var cartRemoveProduct = 'RemoveProductLineItem';

    var addToCartSet = 'productSetId';


    $(document).bind('ajaxComplete', function (event, jqxhr, settings) {

        if (settings.url.toLowerCase().indexOf(gtmPlpRefine.toLowerCase()) !== -1 || settings.url.toLowerCase().indexOf(gtmPlpShowMore.toLowerCase()) !== -1) {

            try {

                pushToDataLayer(window.tempGtmData);

            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }

            $(document).unbind('ajaxSend');

            settings.url.replace(gtmPlpRefine, '');

            settings.url.replace(gtmPlpShowMore, '');

            if (window.yotpo) {

                window.yotpo.refreshWidgets();

            }

            if ($('.lazy').length && jQuery.lazy) {

                $('.lazy').lazy('update');

            }

        }
        if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1) {

            var search = jqxhr.responseJSON.queryString;

            var queryObject = parseQuery(search);

            var $originalE = $('div.quantity-form').find("[data-pid='" + queryObject.pid + "']").parent();

            $originalE.data('currentquantity', queryObject.quantity);

            try {

                pushToDataLayer(jqxhr.responseJSON.gtmData);

            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }

        }
        if (settings.url.toLowerCase().indexOf(cartRemoveProduct.toLowerCase()) !== -1) {

            try {

                pushToDataLayer(jqxhr.responseJSON.gtmData);

            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }

        }
        if (settings.data != null) {

            if (settings.data.toLowerCase().indexOf(addToCartSet.toLowerCase()) !== -1) {

                $(document).unbind('ajaxSend');

            }

        }

    });


    $(document).bind('ajaxSend', function (event, jqxhr, settings) {

        if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1 || settings.url.toLowerCase().indexOf(cartRemoveProduct.toLowerCase()) !== -1) {

            var searchHr = settings.url;

            var search = searchHr.substr(searchHr.indexOf('?') + 1);

            var queryObject = parseQuery(search);

            var $originalQ = $('body').find("select[data-pid='" + queryObject.pid + "']");

            settings.url += '&originalq=' + parseInt($originalQ.data('pre-select-qty'), 10); // eslint-disable-line no-param-reassign

        }

    });

    $('a.promotion-click').on('click', (e) => {
        const x = e.target;
        let a = 'asd';
    })


    $('body').on('search:filter', function () {

        $(document).bind('ajaxSend', function (event, jqxhr, settings) {

            if (settings.url.toLowerCase().indexOf(gtmPlpRefine.toLowerCase()) === -1) {

                settings.url += gtmPlpRefine; // eslint-disable-line no-param-reassign

            }

        });

    });


    $('body').on('search:showMore', function () {

        $(document).bind('ajaxSend', function (event, jqxhr, settings) {

            if (settings.url.toLowerCase().indexOf(gtmPlpShowMore.toLowerCase()) === -1) {

                settings.url += gtmPlpShowMore; // eslint-disable-line no-param-reassign

            }

        });

    });


    if ($('div.product-recommendations').length > 0) {

        var listType = $('div.product-recommendations .product-recommendations-title').text().trim();

        var impressions = [];

        $('div.product-recommendations div.slick-slide:not(.slick-cloned)').each(function () {

            impressions.push({

                name: $(this).find('div.pdp-link a').text(),

                id: String($(this).find('div.product-tile').data('pid')),

                price: String($(this).find('span.sales span.value').attr('content')),

                variant: String($(this).find('div.product-tile').data('variation')),

                list: listType,

                position: $(this).find('div.product-tile').data('positionlist')

            });

        });


        var currentGtmData = {

            event: 'impressionView',

            ecommerce: {

                currencyCode: 'EUR',

                impressions: impressions

            }

        };


        window.dataLayer.push(currentGtmData);

    }


    $('body').on('click', 'div.product-tile', function () {

        var productId = $(this).parent().data('pid');

        var positionInList = $(this).data('positionlist');

        var productName = $(this).find('div.pdp-link a').text();

        var price = $(this).find('span.sales span').attr('content');

        var gtmList = $('.gtmList').data('gtmlist');

        var variantI = $(this).data('variation');

        var currentGtmDataClick = {

            event: 'productClick',

            eventCategory: 'Ecommerce',

            eventAction: 'Click',

            eventLabel: productName,

            ecommerce: {

                click: {

                    actionField: {
                        list: gtmList
                    },

                    products: [

                        {

                            id: String(productId),

                            name: productName,

                            price: String(price),

                            sku: String(productId),

                            variant: String(variantI),

                            position: positionInList

                        }

                    ]

                }

            }

        };

        window.dataLayer.push(currentGtmDataClick);

    });


    /*$('body').on('click', '.checkout-btn', function (e) {

        e.preventDefault();

        var gtminfourl = $(this).data('gtminfo');

        $.ajax({

            url: gtminfourl,

            type: 'get',

            dataType: 'json',

            success: function (data) {
                window.dataLayer.push(JSON.parse(data[0]));
            }
        });

        window.location.href = $(this).attr('href');
    }); */

    $('body').on('click', '.checkout-as-guest', function () {

        var checkoutAsGuestData = {
            event: 'checkout_option',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: 'Guest'
                    }
                }
            }
        };

        window.dataLayer.push(checkoutAsGuestData);
    });

    $('body').on('click', '.oauth-google', function () {

        var checkoutGoogleData = {
            event: 'checkout_option',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: 'Google'
                    }
                }
            }
        };

        window.dataLayer.push(checkoutGoogleData);
    });


    $('body').on('click', '.oauth-facebook', function () {

        var checkoutFacebookData = {
            event: 'checkout_option',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: 'Facebook'
                    }
                }
            }
        };

        window.dataLayer.push(checkoutFacebookData);

    });


    $('body').on('click', '.checkout-register', function () {

        var checkoutRegisterData = {
            event: 'checkout_option',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: 'Register'
                    }
                }
            }
        };

        window.dataLayer.push(checkoutRegisterData);
    });

    $('body').on('click', '.checkout-login-init', function () {
        var checkoutLoginData = {
            event: 'checkout_option',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: 'Login'
                    }
                }
            }
        };

        window.dataLayer.push(checkoutLoginData);
    });
};
