'use strict';

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

module.exports = {
    sliderTop: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        rtl: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true
                }
            }
        ]
    },
    circleSlider: {
        mobileFirst: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        rtl: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 399,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    slidesToShow: 7,
                    slidesToScroll: 7
                }
            }
        ]
    },
    productsNewSlider: {
        mobileFirst: true,
        rtl: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 365,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false
                }
            },
            {
                breakpoint: 610,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false
                }
            },
            {
                breakpoint: 1195,
                settings: {
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    centerMode: false
                }
            },
            {
                breakpoint: 1350,
                settings: {
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    centerMode: false
                }
            },
            {
                breakpoint: 1699,
                settings: {
                    arrows: true,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    centerMode: false
                }
            }
        ]
    },
    accountDashboard: {
        infinite: false,
        slidesToScroll: 1,
        dots: false,
        focusOnSelect: false,
        rtl: true,
        draggable: true,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 1880,
                settings: {
                    slidesToScroll: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    },
    accountDashboardShopping: {
        infinite: false,
        slidesToScroll: 1,
        dots: false,
        focusOnSelect: false,
        rtl: true,
        draggable: true,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 1880,
                settings: {
                    slidesToScroll: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    },
    cartSlider: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        rtl: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 10,
                    arrows: false
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    arrows: false
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    arrows: false
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: false
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 544,
                settings: {
                    arrows: false
                }
            }
        ]
    },
    addressesSlider: {
        slidesToScroll: 1,
        arrows: true,
        slidesToShow: 2,
        rtl: true,
        infinite: false,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    },
    cardSlider: {
        slidesToScroll: 1,
        slidesToShow: 2,
        arrows: true,
        rtl: true,
        infinite: false,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    },
    slotsSlider: {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        rtl: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false
                }
            },
            {
                breakpoint: 544,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    },
    accountDashboardClub: {
        arrows: true,
        rtl: true,
        infinite: false,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    },
    accountDashboardCoupons: {
        arrows: true,
        rtl: true,
        infinite: false,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    },
    quickViewDiscount: {
        arrows: true,
        rtl: true,
        infinite: false,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    },
    quickViewRecommendation: {
        arrows: true,
        rtl: true,
        infinite: false,
        variableWidth: true,
        edgeFriction: 0.05,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    },
    ingredientsCarousel: {
        mobileFirst: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        rtl: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            }
        ]
    },
    recipesCarousel: {
        mobileFirst: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        rtl: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    },
    productsCarousel: {
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: true
    }
};
