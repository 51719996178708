const getFreshCSRFToken = async (callback, url = window.urls.GenerateCSRFToken) => {
    await $.ajax({
        url,
        type: 'GET',
        success: function (data) {
            callback(data);
        }
    });
};

const refreshCSRFToken = async (form) => {
    await getFreshCSRFToken(({ csrf }) => {
        if (!csrf) return;
        const { token, tokenName } = csrf;
        $(form).find(`[name=${tokenName}]`).val(token);
    });
};

module.exports = {
    getFreshCSRFToken: getFreshCSRFToken,
    refreshCSRFToken: refreshCSRFToken
};
