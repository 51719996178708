// Tooltip item
const USER_TOOLTIP_CLASS = 'js-coupons-tootltip';
const USER_TOOLTOP_INFO_CLASS = 'tooltip-information';

/**
 * init credit card tooltip
 */
function initTooltip() {
    $(`.${USER_TOOLTIP_CLASS}`).mouseenter(function () {
        $(this).siblings(`.${USER_TOOLTOP_INFO_CLASS}`).removeClass('d-none');
    }).mouseleave(function () {
        $(this).siblings(`.${USER_TOOLTOP_INFO_CLASS}`).addClass('d-none');
    });
}

module.exports = {
    init: function () {
        initTooltip();
    }
};
