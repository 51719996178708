/* eslint-disable no-restricted-globals */
/* globals google */
const storePopup = require('../storeLocator/storePopup');

let research = false;
const LOCATOR_FORM_SELECTORS = {
    INPUT_SELECTOR: '#address-autocomplete',
    FORM_SELECTOR: '.js-save-delivery-address',
    LAT: '.locationLat',
    LNG: '.locationLng'
};

const GoogleGeocoderApiService = (typeof google !== 'undefined') ? new google.maps.Geocoder() : null;
const GoogleApiService = (typeof google !== 'undefined') ? new google.maps.places.AutocompleteService() : null;

// global map variables
const markersList = [];

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * get preferred store id from header
 * @returns {string} preferred store id
 */
function getPreferredStoreId() {
    return $('#selectStoreHeader').data('preferredstoreid');
}

/**
 * removeNotAvailableItemsFromBasket
 * call service to remove not available items from basket
 * @param {*} url - action url
 */
function removeNotAvailableItemsFromBasket(url) {
    $.ajax({
        url,
        type: 'post',
        dataType: 'json',
        success(data) {
            if (data.basketModelPlus.basket.noBasket) {
                window.location.href = data.redirectUrl;
            }
            $('.minicart-quantity').html(data.basketModelPlus.basket.numItems);
        }
    });
}

/**
 * update store result list buttons
 * @param {string} storeId - id of the new store
 */
function showCorrectStoreResultButtons(storeId) {
    $('.infobox').find(`.selectstore[data-storeid!='${storeId}']`).removeClass('d-none');
    $('.infobox').find(`.selectstore[data-storeid='${storeId}']`).addClass('d-none');
    $('.infobox').find(`.list-mystore[data-storeid!='${storeId}']`).addClass('d-none');
    $('.infobox').find(`.list-mystore[data-storeid='${storeId}']`).removeClass('d-none');
}

/**
 * slideUpSearchForm
 * @param {string} visibleButtonId button to be shown
 */
function slideUpSearchForm(visibleButtonId) {
    $('.closebuttons').addClass('d-none');
    $(visibleButtonId).removeClass('d-none');
    $('#searchform').slideUp();
    $('#closecard').slideDown();
}

/**
 * drawSlots
 * Draw the slot selection list when a day is chosen
 * @param {*} dayElement - element containing data-day: date of the slots, e.g. 2020-06-29
 */
function drawSlots(dayElement) {
    const day = $(dayElement).data('day');
    /* Slots for other days invisible */
    // $('#slotsarea').find(`.dayslots[data-day!='${day}']`).removeClass('d-block').addClass('d-none');

    /* If day has slots, show them else show no-slots-message */
    if ($('#slotsarea').find(`.dayslots[data-day='${day}']`).length === 0) {
        $('.noslot').removeClass('d-none').addClass('d-block');
    } else {
        $('#slotsarea').find(`.dayslots[data-day='${day}']`).removeClass('d-none').addClass('d-block');
    }
}

/**
 * initialize vEllipsis for time slot
 */
function initilizeEllipsisTimeSlot() {
    $().vEllipsis({
        element: '.time-slot-value, .address-name',
        lines: 1,
        responsive: true,
        tolerance: 1,
        elementEvent: 'rezise',
        callback: function () {
            $(this).mouseenter(function () {
                $(this).closest('.js-header-address').siblings('.name-on-hover').removeClass('d-none');
            }).mouseleave(function () {
                $(this).closest('.js-header-address').siblings('.name-on-hover').addClass('d-none');
            });
        }
    });
}

/**
 * drawPicker
 * Draw the new picker headline like 'Jun 28 - Jul 4'
 * Draw two views of radio controls for the days:
 *      7 Controls for big screens
 *      3 Controls for mobile
 *
 * */
function drawPicker() {
    const days = !isNaN($('#daycontainer').data('days')) ? $('#daycontainer').data('days') : 0; // eslint-disable-line
    const views = [3, 7];

    for (let i = 0; i < views.length; i += 1) {
        const daysFormatted = $('#daycontainer').data('formatteddays');

        let lastDay = (days + views[i]) - 1;
        if (lastDay >= daysFormatted.length) {
            lastDay = daysFormatted.length - 1;
        }
        const htmlStringHeadline = `${daysFormatted[days].format0} - ${daysFormatted[lastDay].format0}`;
        $(`#dayswitch-${views[i]}`).empty();
        $(`#dayswitch-${views[i]}`).append(htmlStringHeadline);

        let htmlStringPickers = '<table>' +
            '<tr>';

        for (let j = days; j < days + views[i] && j < daysFormatted.length; j += 1) {
            const dateformat = daysFormatted[j];
            const column = `${'<td>' +
                '<div class="square-box">' +
                '    <input data-day="'}${dateformat.datekey}" name="radioday" class="radio" type="radio" id="radio-${views[i]}-${dateformat.datekey}"/>` +
                `    <label class="btn btn-primary square-content" for="radio-${views[i]}-${dateformat.datekey}">${
                    dateformat.format1
                }        <br/>${
                    dateformat.format2
                }    </label>` +
                '</div>' +
                '</td>';
            htmlStringPickers += column;

            if ($('#slotsarea').find(`.dayslots[data-day!='${dateformat.datekey}']`).length === 0) {
                $(`#radio-${views[i]}-${dateformat.datekey}`).attr('disabled', true);
            }
        }
        htmlStringPickers += '</tr>' +
            '</table>';

        $(`#table-${views[i]}`).empty();
        $(`#table-${views[i]}`).append(htmlStringPickers);
    }

    $('.radio').change(function () {
        drawSlots(this);
    });
}

/**
 * setFirstShownDate
 * @param {*} newStartDate newDays
 * @param {*} step step
 */
function setFirstShownDate(newStartDate, step) {
    let newDays = newStartDate;
    const maxDays = parseInt($('#daycontainer').data('maxdays'), 10);

    /* Prevent new days from getting negative */
    if (newDays <= 0) {
        newDays = 0;
        $('.reduceWeek').addClass('d-none');
    } else {
        $('.reduceWeek').removeClass('d-none');
    }

    /* Prevent new days from exceeding mayDays */
    if (newDays + step >= maxDays) {
        newDays = (maxDays - step);
    }

    if (newDays + 3 >= maxDays) {
        $('.increaseWeek').addClass('d-none');
    } else {
        $('.increaseWeek').removeClass('d-none');
    }

    /* Update currentDays */
    $('#daycontainer').data('days', newDays);
    drawPicker();
}

/**
 * Draw Picker with preselected date
 */
function drawPickerSetDate() {
    const slotDate = $('#pickupdate').data('date') || $('#slotsarea').data('firstavailableslotdate');

    if (slotDate && slotDate !== 'undefined') {
        const focusDate = new Date(slotDate).setHours(0, 0, 0, 0);
        const daystogo = Math.round((focusDate - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));
        setFirstShownDate(daystogo, 3);

        const dayslot3 = `#radio-3-${slotDate}`;
        const dayslot7 = `#radio-7-${slotDate}`;
        $(dayslot3).attr('checked', 'checked');
        $(dayslot7).attr('checked', 'checked');
        drawSlots($(dayslot7));
    } else {
        drawPicker();
    }
}

/**
 * updateFirstShownDate
 *
 * @param {*} changeDateButton - element containing the current Day, day step and max days
 */
function updateFirstShownDate(changeDateButton) {
    const currentDays = parseInt($('#daycontainer').data('days'), 10);
    const step = parseInt($(changeDateButton).data('step'), 10);

    const newDays = currentDays + step;

    setFirstShownDate(newDays, step);
}

/**
 * updateHeader
 */
function updateHeader() {
    const url = $('#mystore').data('url');

    $.ajax({
        url,
        type: 'get',
        dataType: 'html',
        success(response) {
            $('#mystore').empty();
            $('#mystore').append(response);
            storePopup.setNextAvailableSlot();
        }
    });
}

/**
 * softreserve
 * call service with storeId, date and time to reserve a slot
 * @param {*} reserveButton - Element containing actionUrl, storeId, date and time to be reserved
 */
function softreserve(reserveButton) {
    const date = $(reserveButton).data('date');
    const time = $(reserveButton).data('time');
    const id = $(reserveButton).data('storeid');
    const slotID = $(reserveButton).data('slotid');

    /* Mark only the new slot as selected */
    $(`#dayslots-${date}`).find(`.yourslot[data-time='${time}']`).removeClass('d-none');
    $(`#dayslots-${date}`).find(`.yourslot[data-time!='${time}']`).addClass('d-none');
    /* Show only select button for not selected slots */
    $(`#dayslots-${date}`).find(`.softreserve[data-time='${time}']`).addClass('d-none');
    $(`#dayslots-${date}`).find(`.softreserve[data-time!='${time}']`).removeClass('d-none');

    /* Update Slot in Store info */
    $('.store-info-flush').find('.slotinfocard').removeClass('d-none');

    let url = $('#slotsarea').data('confirmslot');
    const urlParams = {
        id, slotID
    };
    url = appendToUrl(url, urlParams);

    $.spinner().start();

    $.ajax({
        url,
        type: 'get',
        dataType: 'html',
        success(response) {
            $('#flushmodal').empty();
            $('#flushmodal').append(response);
            $('#confirmSlot').modal('show');
            $.spinner().stop();
            updateHeader();
        },
        error() {
            $('#reserveFailed').modal('show');
            $.spinner().stop();

            /* Mark new slot as not selected */
            $(`#dayslots-${date}`).find(`.yourslot[data-time='${time}']`).addClass('d-none');
            /* Show all select buttons */
            $(`#dayslots-${date}`).find(`.softreserve[data-time='${time}']`).removeClass('d-none');
        }
    });
}

/**
 * setPickerButtonEvents
 */
function setPickerButtonEvents() {
    $('.changeWeek').click(function () { updateFirstShownDate(this); });
    $('.softreserve').click(function () { softreserve(this); });
}

/**
 * showSearchResults
 */
function showSearchResults() {
    /* Make search results visible */
    $('.results-card .card-header').removeClass('d-none');
    $('.results-card .card-body').removeClass('d-none');
}

/**
 * Draw the picker window after the user selected a store
 * @param {*} data - webservice result object containing the right window
 */
function drawPickerWindow(data) {
    /* replace google maps with calendar */
    $('#mapdiv').slideUp();
    $('#pickerdiv').empty();
    $('#pickerdiv').append(data);

    setPickerButtonEvents();
    drawPickerSetDate();
}

/**
 * removeStoreInfo
 */
function removeStoreInfo() {
    $('.card-header').removeClass('d-none');
    $('.card-body').removeClass('d-none');
    $('.store-info-flush').empty();
}

/**
 * backToSearch
 */
function backToSearch() {
    removeStoreInfo();
    showSearchResults();
}

/**
 * drawStoreInfo
 * @param {*} selectedStoreInfo - html with store info
 */
function drawStoreInfo(selectedStoreInfo) {
    $('.store-info-flush').empty();
    $('.store-info-flush').append(selectedStoreInfo);
    $('.store-info-flush').find('.slotinfocard').addClass('d-none');
}

/**
 * moveProcessBar
 * @param {*} step step the bar schould be moved to
 */
function moveProcessBar(step) {
    $('.dw-wizard').find(`.dw-wizard-progress-item[data-progress="${step}"]`).addClass('is-active');
    $('.dw-wizard').find(`.dw-wizard-progress-item[data-progress!="${step}"]`).removeClass('is-active');
}

/**
 * hideSearchResults
 */
function hideSearchResults() {
    $('.results-card .card-header').addClass('d-none');
    $('.results-card .card-body').addClass('d-none');
}

/**
 * showSearchResult
 */
function showSearchResult() {
    $('.js-load-more').on('click', (e) => {
        e.preventDefault();
        $('.js-more-card').addClass('show');
        $(e.currentTarget).hide();
    });
}

/**
 * selectStore
 * Select a new store and remove basket elements if required
 * @param {*} element - calling element containing storeId and action url
 * @param {boolean} removeItems - true if items are to be removed
 */
function selectStoreId(element, removeItems) {
    const storeId = element.getAttribute('data-storeid');

    moveProcessBar(3);
    slideUpSearchForm('#buttonshowMap');
    hideSearchResults();
    showCorrectStoreResultButtons(storeId);

    /* draw slot picker */
    $.spinner().start();
    let url = element.getAttribute('data-action');
    url = `${url}?id=${storeId}`;

    $.ajax({
        url,
        type: 'get',
        dataType: 'html',
        success(response) {
            updateHeader();
            $.spinner().stop();

            drawPickerWindow(response);

            url = $('#mystore').data('url');
            url = `${url}?noPopup=${true}&hideButtons=${true}`;

            $.ajax({
                url,
                type: 'get',
                dataType: 'html',
                success(storeInfo) {
                    drawStoreInfo(storeInfo);
                }
            });

            $('.storepopover').removeClass('d-none');

            if (removeItems) {
                removeNotAvailableItemsFromBasket($('#changeStoreModal').data('removenotavailableitemsaction'));
            }
        }
    });
}

/**
 * checkBasketAvailability
 * @param {*} element element with storeid
 */
function checkBasketAvailability(element) {
    const storeId = $(element).data('storeid');
    let url = $('#changeStoreModal').data('getnotavailableitemsaction');

    $.spinner().start();
    url = `${url}?id=${storeId}`;

    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $.spinner().stop();

            if (data.notAvailableItems.quantity > 0) {
                /* update modal text with items and store name */
                const storeName = $(element).data('storename');
                $('#nbItemsToBeRemoved').text(data.notAvailableItems.quantity);
                $('#storeNameToBeChangedTo').text(`${storeName}.`);

                $('#btn-continue-box').unbind('click');
                $('#btn-continue-box').click(() => { selectStoreId(element, true); });
                $('#changeStoreModal').modal();
            } else {
                selectStoreId(element, false);
            }
        }
    });
}

/**
 * setSelectStoreEvents
 */
function setSelectStoreEvents() {
    $('.selectstore').click(function () { checkBasketAvailability(this); });
}

/**
 *
 * @param {*} element - element containing preferredStoreId
 */
function drawStoreProfilePage(element) {
    const storeId = $(element).data('storeid');
    let url = $(element).data('action');
    url = `${url}?storeId=${storeId}`;

    $.spinner().start();

    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $.spinner().stop();

            slideUpSearchForm('#buttonshowMap');
            hideSearchResults();
            drawStoreInfo(data.selectedStoreInfo);
            showCorrectStoreResultButtons();

            setSelectStoreEvents();
        }
    });

    url = $(element).data('profileurl');
    url = `${url}?storeId=${storeId}`;

    $.ajax({
        url,
        type: 'get',
        dataType: 'html',
        success(response) {
            $('#mapdiv').slideUp();
            $('#pickerdiv').empty();
            $('#pickerdiv').append(response);
        }
    });
}

/**
 * setProfilePageEvents
 */
function setProfilePageEvents() {
    $('.list-group-flush .store-profile-link').click(function () { drawStoreProfilePage(this); });
    $('#mapdiv .store-profile-link').click(function () { drawStoreProfilePage(this); });
}

/**
 * Clean marker icons before update
 * @param {string} icon - path to icon
 */
function cleanMarkerIcon(icon) {
    markersList.forEach(item => {
        item.setIcon(icon);
    });
}

/**
 * @param {*} $map jQuery object of the map div
 * Uses google maps api to render a map
 */
function maps($map) {
    if (typeof google === 'undefined') return;

    let mapdiv = JSON.parse($map.attr('data-locations'));
    let markerPath = $map.attr('data-marker');
    let markerActivePath = $map.attr('data-marker-active');

    const infowindow = new google.maps.InfoWindow();

    if (!mapdiv.length) {
        return;
    }

    // Init U.S. Map in the center of the viewport
    const latlng = new google.maps.LatLng(37.09024, -95.712891);

    const mapOptions = {
        scrollwheel: false,
        zoom: 15,
        center: latlng,
        minZoom: 5,
        maxZoom: 15
    };

    const map = new google.maps.Map($map[0], mapOptions);
    const bounds = new google.maps.LatLngBounds();
    Object.keys(mapdiv).forEach((key) => {
        const item = mapdiv[key];
        const storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        const marker = new google.maps.Marker({
            position: storeLocation,
            map,
            title: item.name,
            icon: markerPath
        });

        markersList.push(marker);

        marker.addListener('click', () => {
            cleanMarkerIcon(markerPath);
            infowindow.setOptions({
                content: item.infoWindowHtml
            });

            marker.setIcon(markerActivePath);
            infowindow.open(map, marker);

            google.maps.event.addListener(infowindow, 'domready', () => {
                /* add events for google maps info window */
                setSelectStoreEvents();
                setProfilePageEvents();
                showCorrectStoreResultButtons(getPreferredStoreId());
            });
        });

        google.maps.event.addListener(infowindow, 'closeclick', function () {
            marker.setIcon(markerPath);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length !== 0) {
        // This is needed to set the zoom after fitbounds,
        google.maps.event.addListener(map, 'zoom_changed', function () {
            var zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function () {
                if (this.initialZoom === true) {
                    // Change max/min zoom here
                    this.setZoom(10);
                    this.initialZoom = false;
                }
                google.maps.event.removeListener(zoomChangeBoundsListener);
            });
        });
        map.initialZoom = true;
        map.fitBounds(bounds);
    }
}

/**
 * setNextAvailableSlots for all list elements
 */
function setNextAvailableSlots() {
    $('.storelist-desktop .list-group-flush .store-profile-link').each(function () {
        const storeId = $(this).data('storeid');
        let url = $(this).data('availableslot');
        url += `?storeid=${storeId}`;

        $.ajax({
            url,
            type: 'get',
            dataType: 'html',
            success(response) {
                const htmlSnippet = `${'<table><tr class="store-address"><td>' +
                    '<i class="fa fa-clock-o" aria-hidden="true"></i>' +
                '</td><td class="pl-2">'}${
                    response
                }</td></tr></table>`;

                $('.storelist-mobile').find(`.storeListNextAvailableSlot[data-storeid='${storeId}']`).append(htmlSnippet);
                $('.storelist-desktop').find(`.storeListNextAvailableSlot[data-storeid='${storeId}']`).append(htmlSnippet);
            }
        });
    });
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    const hasResults = data.stores.length > 0;
    const $resultsDiv = $('.results');
    const $mapDiv = $('#storelocator-map-canvas');
    if (hasResults) {
        moveProcessBar(2);
        $('.store-locator-no-results').hide();
    } else {
        const msgNoSearchResults = $('#buttoncard input').data('msgnoresult');
        $('#buttoncard input').addClass('is-invalid');
        $('#buttoncard .invalid-feedback').html(msgNoSearchResults);
        $('.store-locator-no-results').show();
    }

    if (data.radiusExtended) {
        $('#radius').val($('#radius option:first').val());
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        maps($mapDiv);
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        setNextAvailableSlots();
    }

    setSelectStoreEvents();
    setProfilePageEvents();
    showCorrectStoreResultButtons(getPreferredStoreId());
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    const dialog = element.closest('.in-store-inventory-dialog');
    const spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    const $form = element.closest('.store-locator');
    const radius = $('.results').data('radius');
    let url = $form.attr('action');

    const extendRadiusWhenSearchEmpty = $('#radius').attr('data-changedbycustomer') !== 'true';
    const lat = $('.store-locator .locationLat').val();
    const long = $('.store-locator .locationLng').val();
    const urlParams = {
        radius, extendRadiusWhenSearchEmpty, lat, long
    };

    const payload = $form.is('form') ? $form.serialize() : { postalCode: $form.find('[name="postalCode"]').val() };

    url = appendToUrl(url, urlParams);

    $.ajax({
        url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success(data) {
            spinner.stop();

            updateStoresResults(data);
            $('.select-store').prop('disabled', true);
            showSearchResult();

            const cityNameTag = $('.js-city-name');
            if (cityNameTag.length > 0 && data.stores.length > 0) {
                cityNameTag.empty().text(data.stores[0].city);
            }
        },
        error(data) {
            spinner.stop();

            data.stores = [];
            updateStoresResults(data);
        }
    });
    return false;
}

/**
 * showMap
 */
function showMap() {
    moveProcessBar(1);
    backToSearch();

    /* Get Back the Map */
    $('#mapdiv').slideDown();
    if ($('.map-canvas').data('has-google-api')) {
        let mapsList = $('.map-canvas');
        $(mapsList).each((index, mapEl) => {
            maps($(mapEl));
        });
    }

    /* Delete the picker window */
    $('#pickerdiv').empty();
}

/**
 * proceed Set Store
 * Select a new store and remove basket elements if required
 * @param {*} url - action url
 * @param {string} storeId - store id
 * @param {boolean} removeItems - true if items are to be removed
 */
function proceedSetStore(url, storeId, removeItems) {
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        data: { id: storeId },
        success(data) {
            $('#arriveModal').on('hide.bs.modal', function () {
                window.location.reload();
            });

            $('#deliveryModal').on('hide.bs.modal', function () {
                window.location.reload();
            });

            $('#pickupModal').on('hide.bs.modal', function () {
                window.location.reload();
            });

            $('#deliveryModal').modal('hide');
            $('#pickupModal').modal('hide');
            window.MODAL_COUNTER -= 1;
            if (window.MODAL_COUNTER === 0) {
                $('body').removeClass('lock-scroll');
            }
            $('.js-header-address').html('<span>איסוף עצמי: </span><span class="address-name self-pickup">' + data.address1 + ' ' + data.city + '</span>');
            $('.js-header-address + .name-on-hover').html(data.address1 + ' ' + data.city);

            // reinitialize vEllipsis when change store
            if (removeItems) {
                removeNotAvailableItemsFromBasket($('#changeStore').data('removenotavailableitemsaction'));
            }
        }
    });
}

/**
 * change view Save Delivery Address on Header section without country
 * @param {string} address - address
 * @returns {string} address without word Israel
*/
function modifyAddressWithoutCountry(address) {
    let newAddress;
    const nameCountry = 'ישראל';
    let indexNameOfCounty = address.indexOf(nameCountry);
    if (indexNameOfCounty === -1) {
        newAddress = address;
    }
    if (indexNameOfCounty !== -1) {
        newAddress = address.slice(0, indexNameOfCounty - 2);
    }
    return newAddress;
}

/**
 * proceed Save Delivery Address
 * Select a new store, save address and remove basket elements if required
 * @param {*} url - action url
 * @param {string} address - address
 * @param {string} storeId - store id
 * @param {boolean} removeItems - true if items are to be removed
 */
function proceedSaveDeliveryAddress(url, address, storeId, removeItems) {
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: { address: address, storeId: storeId },
        success(data) {
            if (data.delivery) {
                var modifyAddress = modifyAddressWithoutCountry(data.address);
                $('.js-header-address').html('<div class="address-name text-truncate text-nowrap">' + modifyAddress + '</div><div class="time-slot text-nowrap text-truncate"><span>המשלוח הקרוב:</span> <span class="time-slot-value">' + data.slot + '</span></div>');
                $('.js-header-address + .name-on-hover').html(modifyAddress + ' ' + data.slot);
                $('.store-locator-icon').empty().html(`<i class="icon icon-location-blue-mobile d-xl-none position-absolute"></i>
                    <i class="icon icon-location-blue hidden-lg-down position-absolute"></i>`);
            } else {
                $('.js-header-address').html('<div class="address-name">אז איפה אנחנו תופסים אותך?</div>');
                $('.js-header-address + .name-on-hover').html('אז איפה אנחנו תופסים אותך?');
                $('.store-locator-icon').empty().html(` <i class="icon icon-location-white-mobile d-xl-none position-absolute"></i>
                    <i class="icon icon-location-white hidden-lg-down position-absolute"></i>`);
            }
            $('#deliveryModal').modal('hide');
            window.MODAL_COUNTER -= 1;
            if (window.MODAL_COUNTER === 0) {
                $('body').removeClass('lock-scroll');
            }
            initilizeEllipsisTimeSlot();
            if (data.delivery) {
                $('#arriveModal').modal('show');
                $('#arriveModal').on('hide.bs.modal', function () {
                    location.reload();
                });
            } else {
                $('#notArriveModal').modal('show');
            }
            if (removeItems) {
                removeNotAvailableItemsFromBasket($('#changeStore').data('removenotavailableitemsaction'));
            }
        }
    });
}

/**
 * Make request with date from storelocator screen
 * @param {Object} $form - Jquery object
 * @returns {boolean} nothing to return
 */
function addressDataRequst($form) {
    if (!$form) {
        return false;
    }

    var address = $form.find('input[name=address]').val();
    if (!address || address.length === 0) {
        return false;
    }
    var url = $form.data('check-basket-url');

    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        data: $form.serialize(),
        success(data) {
            const isPickupModal = $('#pickupModal').length;

            if (data.notAvailableItems && data.notAvailableItems.quantity > 0) {
                if (data.delivery) {
                    $('.js-changeStoreBody-emptycart').addClass('d-none');
                    $('.js-changeStoreBody').removeClass('d-none');

                    $('.js-nbItemsToBeRemoved').text(data.notAvailableItems.quantity);
                    $('.js-storeNameToBeChangedTo').text(data.name);
                } else {
                    $('.js-changeStoreBody-emptycart').removeClass('d-none');
                    $('.js-changeStoreBody').addClass('d-none');
                }

                $('#btn-continue-box-change-store').unbind('click');
                $('#btn-continue-box-change-store').click(() => { proceedSaveDeliveryAddress($form.attr('action'), data.address, data.storeId, true); });
                $('#changeStore').removeClass('d-none');
            } else if (!isPickupModal) {
                url = $form.attr('action');
                proceedSaveDeliveryAddress(url, data.address, data.storeId, false);
            }

            if (isPickupModal) {
                if (!$(LOCATOR_FORM_SELECTORS.LAT).val() && !$(LOCATOR_FORM_SELECTORS.LNG).val()) {
                    updateStoresResults(data.storesModel);
                    $('.select-store').prop('disabled', true);
                }

                $('.stores-list')
                    .empty()
                    .append(data.storeRenderTiles);

                $('.store-container').show();

                $(LOCATOR_FORM_SELECTORS.LAT).val('');
                $(LOCATOR_FORM_SELECTORS.LNG).val('');
            }
        }
    });
    return true;
}

/**
 * getting predictions and return to callback
 * @param {string} value - string for prediction
 * @param {Function} callback - callback function
 * @param {Array} type - type prediciton list
 */
function getFieldPrediction(value, callback, type = []) {
    let options = {};
    if (type.length) {
        options = {
            types: type
        };
    }

    GoogleApiService.getPlacePredictions({
        input: value,
        componentRestrictions: {
            country: ['IL']
        },
        ...options
    }, (predictions, status) => {
        /* eslint-disable */
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.log(status);
            return;
        }
        /* eslint-enable */

        callback(predictions);
    });
}

/**
 * getting place coordinates
 * @param {Object} place - place prediciton object
 * @returns {Object} return lat and lng coordinates
 */
function getPlaceCoordinates(place) {
    if (!place.geometry) {
        return {
            lat: null,
            lng: null
        };
    }

    const coordinates = place.geometry.location;

    return {
        lat: coordinates.lat(),
        lng: coordinates.lng()
    };
}

/**
 * setting coordinates for form
 * @param {string} placeId - place id
 * @param {Object} coordinatesClass - selectors object
 * @param {Function} callback - callback function
 */
function setCoordinatesData(placeId, coordinatesClass, callback) {
    GoogleGeocoderApiService.geocode({ placeId: placeId }, (results) => {
        const coordinates = getPlaceCoordinates(results[0]);
        const latSelector = document.querySelectorAll(`${coordinatesClass.form} ${coordinatesClass.lat}`)[0];
        const lngSelector = document.querySelectorAll(`${coordinatesClass.form} ${coordinatesClass.lng}`)[0];
        latSelector.value = coordinates.lat;
        lngSelector.value = coordinates.lng;

        callback();
    });
}

/**
 * displaying detected points on map
 * @param {Object} obj - coordinates information
 */
function mapSearch(obj) {
    const isPickupModal = $('#pickupModal').length;
    if (!isPickupModal) {
        return;
    }

    $.spinner().start();
    if (!navigator.geolocation) {
        $.spinner().stop();
        return;
    }

    const $detectLocationButton = $('.detect-location');
    let url = $detectLocationButton.data('action');
    let radius = document.querySelectorAll('.results')[0].dataset.radius;

    $('#radius').data('lat', obj.lat);
    $('#radius').data('long', obj.lng);

    const extendRadiusWhenSearchEmpty = $('#radius').attr('data-changedbycustomer') !== 'true';
    if (extendRadiusWhenSearchEmpty) {
        $('#radius').val($('#radius option:eq(1)').val());
        radius = $('#radius option:eq(1)').val();
    }
    const urlParams = {
        radius,
        lat: obj.lat,
        long: obj.lng,
        extendRadiusWhenSearchEmpty
    };

    url = appendToUrl(url, urlParams);
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $.spinner().stop();

            if (!data.stores.length && !research) {
                research = true;
                $('.results').data('radius', 1000);
                mapSearch({
                    lat: $(LOCATOR_FORM_SELECTORS.LAT).val(),
                    lng: $(LOCATOR_FORM_SELECTORS.LNG).val()
                });
            }

            if (!data.stores.length) {
                return;
            }

            updateStoresResults(data);
            $('.select-store').prop('disabled', true);
        }
    });
}

/**
 *
 * @param {*} $map map div jquery object
 */
function loadStoreMap($map) {
    if (typeof google === 'undefined') return;

    const latitude = $map.data('latitude');
    const longitude = $map.data('longitude');
    const markerPath = $map.attr('data-marker');

    if (!latitude || !longitude) {
        return;
    }

    const latlng = new google.maps.LatLng(latitude, longitude);

    const mapOptions = {
        scrollwheel: true,
        zoom: 14,
        center: latlng,
        minZoom: 5,
        maxZoom: 15
    };

    const map = new google.maps.Map($map[0], mapOptions);

    // eslint-disable-next-line no-new
    new google.maps.Marker({
        position: latlng,
        map,
        icon: markerPath
    });
}

module.exports = {
    addressDataRequst: addressDataRequst,
    init() {
        if ($('.map-canvas').data('has-google-api')) {
            let mapsList = $('.map-canvas');
            $(mapsList).each((index, mapEl) => {
                maps($(mapEl));
            });
        }

        if (!$('.results').first().data('has-results')) {
            $('.store-locator-no-results').show();
        } else {
            $('.store-locator-no-results').hide();
        }

        setNextAvailableSlots();

        /* Set global button events */
        $('.slideUpSearchForm').click(() => {
            $('#searchform').slideDown();
            $('#closecard').slideUp();
            $('.store-info-flush').empty();
        });
        setProfilePageEvents();
        setSelectStoreEvents();
        showCorrectStoreResultButtons(getPreferredStoreId());
        $('#closeSearchResults').click(() => { backToSearch(); });
        $('#closeSearchResultsShowMap').click(() => { showMap(); });
        $('.js-change-store-cancel').click(() => { $('#changeStore').addClass('d-none'); });

        /* Show picker instead of Map */
        if ($('#mapdiv').data('hideafterinit')) {
            moveProcessBar(3);
            slideUpSearchForm('#buttonshowMap');
            hideSearchResults();
            drawPickerSetDate();
            setPickerButtonEvents();
            $('#mapdiv').hide();
            $('#buttonshowMap').click(() => { showMap(); });
        }

        if ($('.js-store-map').data('has-google-api')) {
            loadStoreMap($('.js-store-map'));
        }
    },

    detectLocation() {
        // clicking on detect location.
        $('.detect-location').on('click', () => {
            $.spinner().start();
            if (!navigator.geolocation) {
                $.spinner().stop();
                return;
            }

            navigator.geolocation.getCurrentPosition((position) => {
                const $detectLocationButton = $('.detect-location');
                let url = $detectLocationButton.data('action');
                let radius = document.querySelectorAll('.results')[0].dataset.radius;

                $('#radius').data('lat', position.coords.latitude);
                $('#radius').data('long', position.coords.longitude);

                const extendRadiusWhenSearchEmpty = $('#radius').attr('data-changedbycustomer') !== 'true';
                if (extendRadiusWhenSearchEmpty) {
                    $('#radius').val($('#radius option:eq(1)').val());
                    radius = $('#radius option:eq(1)').val();
                }
                const urlParams = {
                    radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                    extendRadiusWhenSearchEmpty
                };

                url = appendToUrl(url, urlParams);
                $.ajax({
                    url,
                    type: 'get',
                    dataType: 'json',
                    success(data) {
                        $.spinner().stop();
                        updateStoresResults(data);
                        $('.select-store').prop('disabled', true);
                    }
                });
            });
        });
    },

    search() {
        $('.store-locator-container form.store-locator').submit(function (e) {
            e.preventDefault();
            search($(this));
        });
        $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
            e.preventDefault();
            search($(this));
        });
    },

    changeRadius() {
        $('.store-locator-container .radius').change(function () {
            const radius = $(this).val();
            const searchKeys = $('.results').data('search-key');
            let url = $(this).data('action-url');
            const extendRadiusWhenSearchEmpty = false;
            const lat = $('#radius').data('lat') || searchKeys.lat;
            const long = $('#radius').data('long') || searchKeys.long;
            const urlParams = {
                radius,
                extendRadiusWhenSearchEmpty,
                lat,
                long
            };

            if (searchKeys.postalCode) {
                urlParams.postalCode = searchKeys.postalCode;
            }

            $('#radius').attr('data-changedbycustomer', !extendRadiusWhenSearchEmpty);
            $('#radius').data('changedbycustomer', !extendRadiusWhenSearchEmpty);
            url = appendToUrl(url, urlParams);

            const dialog = $(this).closest('.in-store-inventory-dialog');
            const spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    spinner.stop();
                    updateStoresResults(data);
                    $('.select-store').prop('disabled', true);
                }
            });
        });
    },
    selectStore() {
        $('.store-locator-container').on('click', '.select-store', ((e) => {
            e.preventDefault();
            const selectedStore = $(':checked', '.results-card .results');
            const data = {
                storeID: selectedStore.val(),
                searchRadius: $('#radius').val(),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
                event: e
            };

            $('body').trigger('store:selected', data);
        }));
    },
    updateSelectStoreButton() {
        $('body').on('change', '.select-store-input', (() => {
            $('.select-store').prop('disabled', false);
        }));
    },
    saveDeliveryAddress() {
        $('body').on('submit', '.js-save-delivery-address', function (e) {
            e.preventDefault();
            var $form = $(this);
            const predicitonsQuantity = $('.pac-item').length;

            if (predicitonsQuantity) {
                var val = $(LOCATOR_FORM_SELECTORS.INPUT_SELECTOR).val();
                getFieldPrediction(val, (data) => {
                    $(LOCATOR_FORM_SELECTORS.INPUT_SELECTOR).val(data[0].description);
                    setCoordinatesData(
                        data[0].place_id,
                        {
                            form: LOCATOR_FORM_SELECTORS.FORM_SELECTOR,
                            lat: LOCATOR_FORM_SELECTORS.LAT,
                            lng: LOCATOR_FORM_SELECTORS.LNG
                        },
                        () => {
                            mapSearch({
                                lat: $(LOCATOR_FORM_SELECTORS.LAT).val(),
                                lng: $(LOCATOR_FORM_SELECTORS.LNG).val()
                            });
                            addressDataRequst($form);
                        }
                    );
                });
            } else {
                if ($(LOCATOR_FORM_SELECTORS.LAT).val() || $(LOCATOR_FORM_SELECTORS.LNG).val()) {
                    mapSearch({
                        lat: $(LOCATOR_FORM_SELECTORS.LAT).val(),
                        lng: $(LOCATOR_FORM_SELECTORS.LNG).val()
                    });
                }

                addressDataRequst($form);
            }
            localStorage.setItem('storeSelected', 'true');
        });
    },
    setStore() {
        $('body').on('click', '.js-select-store', function () {
            $.spinner().start();
            var $store = $(this);
            var storeId = $store.data('store-id');
            var checkItemsAvailabilityUrl = $store.data('check-basket-url');
            var setStoreURL = $store.data('url');
            var url = `${checkItemsAvailabilityUrl}?id=${storeId}`;
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    if (data.notAvailableItems.quantity > 0) {
                        $('.js-nbItemsToBeRemoved').text(data.notAvailableItems.quantity);
                        $('.js-storeNameToBeChangedTo').text(data.name);

                        $('#btn-continue-box-change-store').unbind('click');
                        $('#btn-continue-box-change-store').click(() => { proceedSetStore(setStoreURL, storeId, true); });
                        $('#changeStore').removeClass('d-none');
                    } else {
                        proceedSetStore(setStoreURL, storeId, false);
                    }
                    $.spinner().stop();
                }
            });
        });
    },

    cutAddressLongName() {
        initilizeEllipsisTimeSlot();
    }
};
