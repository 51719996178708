class Switcher {
    /**
     * Create instance of Swticher class
     *
     * @param {Object} data - configurations for class
     * @return {Switcher} instance of Swticher class
     */
    static build(data) {
        return new Switcher()
            .setToggler(data.toggler)
            .setOffWrapper(data.offWrapper)
            .setOnWrapper(data.onWrapper)
            .initEvents()
            .initView();
    }

    setToggler(toggler) {
        this.toggler = document.querySelectorAll(toggler)[0];
        return this;
    }

    setOffWrapper(offWrapper) {
        this.offWrapper = document.querySelectorAll(offWrapper)[0];
        return this;
    }

    setOnWrapper(onWrapper) {
        this.onWrapper = document.querySelectorAll(onWrapper)[0];
        return this;
    }

    showWrapper(wrapper) {
        wrapper.style.display = 'block';
        return this;
    }

    hideWrapper(wrapper) {
        wrapper.style.display = 'none';
        return this;
    }

    togglerWrapper(checked) {
        if (checked) {
            this.showWrapper(this.onWrapper);
            this.hideWrapper(this.offWrapper);
        } else {
            this.showWrapper(this.offWrapper);
            this.hideWrapper(this.onWrapper);
        }
    }

    initView() {
        this.togglerWrapper(false);
        return this;
    }

    initEvents() {
        this.toggler.addEventListener('change', event => {
            this.togglerWrapper(event.target.checked);
        });

        return this;
    }
}

module.exports = {
    Switcher: Switcher
};
