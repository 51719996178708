module.exports = {
    handleRestoreSavedCart: function (redirectUrl) {
        $(document).on("click", ".save-cart-restore", function (e) {
            e.preventDefault();
            var $this = $(this);
            $.ajax({
                url: $this.data("url"),
                type: "post",
                data: {isRestore: $this.data("restore")},
                success: function (data) {
                    if (data.success === "Ok") {
                        $.ajax({
                            url: data.restoreCartUrl,
                            type: "post",
                            data: {savedcart: data.savedcart},
                            success: function (result) {
                                if (result.success === "Ok") {
                                    if (redirectUrl) {
                                        location.href = redirectUrl;
                                    } else {
                                        window.location.reload();
                                    }
                                }
                            }
                        });
                    }
                }
            });
        });
    },
    handleRemoveSavedCart: function (redirectUrl) {
        $(document).on("click", ".save-cart-remove", function (e) {
            e.preventDefault();
            var $this = $(this);
            $.ajax({
                url: $this.data("url"),
                type: "post",
                data: {savedcart: $this.data("savedcart")},
                success: function (data) {
                    if (data.success === "Ok") {
                        if (redirectUrl) {
                            location.href = redirectUrl;
                        } else {
                            window.location.reload();
                        }
                    }
                }
            });
        });
    }
};
