/**
 * Dynamically calculated padding to add on top of the page when using fixed header positioning
 */
function addPaddingToPage() {
    if ($('header').css('position') === 'fixed') {
        $('.page').css('paddingTop', $('header').height());
    }
}

module.exports = function () {
    addPaddingToPage();
    $(window).resize(() => {
        addPaddingToPage();
    });
};
