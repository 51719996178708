'use strict';

var base = require('../product/base');
const SelectionProposal = require('../components/selectionProposal.component').SelectionProposal;
const DELIVERY_PROPOSAL_INPUT_SELECTOR = '#giftcert-message';
const DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR = '.dropdown-custom-wrapper.giftcert-message';
const DELIVERY_PROPOSAL_DROPDOWN_TRIGGER_SELECTOR = $(DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR).find('.dropdown-custom-title');
const DELIVERY_PROPOSAL_DROPDOWN_LIST_SELECTOR = $(DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR).find('.dropdown-custom-list');
const DELIVERY_PROPOSAL_DROPDOWN_ITEM_SELECTOR = $(DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR).find('.dropdown-custom-item');

/**
 * Inits the selection purposal js for the gift cert message.
 */
let selectionPurposalInit = () => {
    SelectionProposal.build({
        input: DELIVERY_PROPOSAL_INPUT_SELECTOR,
        dropdown: {
            wrapper: DELIVERY_PROPOSAL_DROPDOWN_WRAPPER_SELECTOR,
            trigger: DELIVERY_PROPOSAL_DROPDOWN_TRIGGER_SELECTOR,
            list: DELIVERY_PROPOSAL_DROPDOWN_LIST_SELECTOR,
            item: DELIVERY_PROPOSAL_DROPDOWN_ITEM_SELECTOR
        }
    });
};

/**
 * create error notification
 * @param {Object} errorMessage - Error message to display
 */
let createErrorNotification = (errorMessage) => {
    if ($('.add-to-cart-messages').length === 0) {
        $('body').append('<div class="add-to-cart-messages"></div>');
    }

    $('.add-to-cart-messages').append('<div class="alert alert-danger add-to-basket-alert text-right" role="alert">' +
        errorMessage +
        '</div>');

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
};

/**
 * Add gift certificate to basket
 */
let submitGiftCertificateForm = () => {
    $('body').on('submit', '.gift-certificate-form', function (e) {
        e.preventDefault();
        let $form = $(this);
        fetch($form.attr('action'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $form.serialize()
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    createErrorNotification(data.errorMessage);
                } else {
                    $('.minicart').trigger('count:update', data);
                    $('body').trigger('product:afterAddToCart', data);
                    base.updateMinicartView();
                    $('.add-to-basket-alert').remove();
                    $('.gift-certificate-form')[0].reset();
                    $('#editWishlistProductModal').remove();
                    $('#giftCertificateModal').modal('hide');
                    $('.modal-backdrop').remove();
                    $('body').removeClass('modal-open');
                }
            });
    });
};

let openModal = () => {
    $('#giftCertificateModal').modal('show');
    window.history.replaceState(null, null, '?mpid=gift-card');
};

let selectGiftCertValue = () => {
    $('#gift-certificate-value-select').selectmenu({
        classes: {
            'ui-selectmenu-menu': 'gift-select-menu'
        }
    });
};

let clearModalBackground = () => {
    $('body').on('click', '.gift-cert-purchase', (e) => {
        e.preventDefault();
        $('.navbar-toggler').trigger('click');
        openModal();
    });
};

let openGiftCertModal = () => {
    $('body').on('click', '.gift-cert-purchase-form', (e) => {
        e.preventDefault();
        openModal();
    });
};

let clearWindowHistory = () => {
    $('body').on('hidden.bs.modal', '#giftCertificateModal', () => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.replaceState(null, null, url);
    });
};

let openOnRefresh = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
    });
    let value = params.mpid;

    if (value === 'gift-card') {
        openModal();
    }
};

module.exports = {
    submitGiftCertificateForm: submitGiftCertificateForm,
    selectionPurposalInit: selectionPurposalInit,
    selectGiftCertValue: selectGiftCertValue,
    clearModalBackground: clearModalBackground,
    openGiftCertModal: openGiftCertModal,
    clearWindowHistory: clearWindowHistory,
    openOnRefresh: openOnRefresh
};
