
const cart = require('../cart/cart');

let updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}

/**
 * Update cart
 * @param {Object} data - response data
 */
function updateCartJson(data) {
    if (!data || !data.items) return;
    var serverData = data.items.filter(function (i) {
        if (i.isBonusProductLineItem) {
            return false; // skip
        }
        return true;
    }).map((i) => ({
        id: i.id,
        qty: i.isBonusProductLineItem ? 0 : i.quantity,
        secondaryMeasurementUnit: i.secondaryMeasurementUnit,
        unitConversion: i.unitConversion
    }));

    $('.cart-json').data('cart', { itemsquantities: serverData });
}

/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        const url = $(this).attr('href');
        const pid = $(this).data('pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal
            },
            success(data) {
                displayMessageAndRemoveFromCart(data);
            },
            error(err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

module.exports = function () {
    cart();
    moveToWishlist();
    $('.minicart').on('count:update', (event, data) => {
        if (data && $.isNumeric(data.quantityTotal)) {
            $('.minicart .minicart-quantity').text(data.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems
            });

            // only needed when new products are added, on plp qty increase this isn't needed
            if (data && data.cart && data.cart.items) {
                const itemsquantities = [];
                const { items } = data.cart;
                items.forEach((item) => {
                    itemsquantities.push({
                        id: item.id, qty: item.quantity, secondaryMeasurementUnit: item.secondaryMeasurementUnit, unitConversion: item.unitConversion
                    });
                });
                $('.cart-json').data('cart', { itemsquantities });
            }
            updateCartJson(data);
            $('body').trigger('cart:update');
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', () => {
        if ($('.search:visible').length === 0) {
            return;
        }
        const url = $('.minicart').data('action-url');
        const count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover').length !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, (data) => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', (event) => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('click', '.js-remove-basket', function () {
        $.spinner().start();
        var $button = $(this);
        var url = $button.data('url');
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            success(data) {
                if (data.success) {
                    $('.minicart-quantity, .js-free-shipping, .js-cart-carousels, .product-summary, .js-minicart-discounts, .shipping-discount').addClass('d-none');
                    $('.empty-cart').removeClass('d-none');
                    $('.total-amount, .shipping-cost').empty().append(data.subTotals);
                    $('#removeBasket').modal('hide');
                    $('body').trigger('productList:addClassTextMuted');
                    $('.checkout-btn').addClass('disabled');
                    // update view on main-container and data-attr with quantity
                    if ($('.update-quantity:visible')) {
                        var $targetParentToUpdate = $('.update-quantity:visible').parent();
                        $targetParentToUpdate.children('.update-quantity').removeClass('show');
                        $targetParentToUpdate.children('.btn-add-to-cart').addClass('show');
                        $targetParentToUpdate.attr('data-quantity', '0.0');
                        $targetParentToUpdate.parents().closest('.product-tile').children('.promotion-badge-approaching, .tile-body').removeClass('promo-active');
                    }
                } else if (data.errorMessage) {
                    var errorContainer = $button.siblings('.invalid-feedback');
                    errorContainer.text(data.errorMessage);
                    errorContainer.show();
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON) {
                    var errorContainer = $button.siblings('.invalid-feedback');
                    errorContainer.text(err.responseJSON.message);
                    errorContainer.show();
                }
                $.spinner().stop();
            }
        });
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });
};
