module.exports = {
    init: function () {
        const container = '.order-delivery-timer .order-delivery-time';
        var orderTimestamps = $(`${container}`);
        orderTimestamps.each(idx => {
            var orderTimestamp = $(orderTimestamps[idx]).data('timestamp');
            var countDownDate = new Date(orderTimestamp).getTime();
            var limitInHours = $(orderTimestamps[idx]).data('limit');

            var x = setInterval(i => {
                var now = new Date();
                now.setHours(now.getHours() - limitInHours);
                var difference = countDownDate - now;

                var hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString();
                if (minutes.length < 2) {
                    minutes = '0' + minutes;
                }
                // digits order is reversed due to Hebrew LTR direction
                $(orderTimestamps[i]).text(minutes + ' : ' + hours);

                if (difference < 0) {
                    clearInterval(x);
                    $(orderTimestamps[i]).text('00 : 0');
                }
            }, 1000, idx);
        });
    }
};
