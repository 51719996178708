'use strict';

const utils = require('./utils');

/**
 * Shows the Select Store Modal on Home Page
 */
function showSelectStoreModal() {
    $('.js-changeStoreBody-emptycart').addClass('d-none');
    $('.js-changeStoreBody').removeClass('d-none');
    $('#changeStore').addClass('d-none');
    $('#address-autocomplete').val('');
    $('#deliveryModal').modal('show');
    $('#deliveryModal').on('show.bs.modal', function () {
        utils.modalIndex.set('#deliveryModal + .modal-backdrop');
        utils.modalIndex.set('#deliveryModal');
        window.MODAL_COUNTER += 1;
    });
}

/**
 * Makes a call to the server to check if store is selected
 * @param {function} successCb - Callback that will be executed on success
 * @param {function} errorCb - Callback that will be executed if error occures
 * @returns {Promise} promise - response to check if store is selected
 */
function checkStoreAjax(successCb, errorCb = () => {}) {
    return $.ajax({
        url: window.urls.checkStore,
        method: 'GET',
        success(data) {
            if (data.noStore) {
                successCb();
            }
        },
        error() {
            errorCb();
            $.spinner().stop();
        }
    });
}

/**
 * Checks if store is selected, if not, shows Store Select Modal on Home Page
 * @returns {Promise} promise - response to check if store is selected
 */
function checkStore() {
    return checkStoreAjax(showSelectStoreModal);
}

module.exports = {
    checkStoreAjax: checkStoreAjax,
    checkStore: checkStore
};
