// imports section
const utils = require('../shared/helpers/utils');
const DashboardNavigation = require('../shared/classes/dashboardNavigation.class').DashboardNavigation;

// dashboard navigation
const DASHBOARD_NAVIGATION_WRAPPER_CLASS = 'dashboard-navigation-wrapper';
const DASHBOARD_NAVIGATION_OPEN_MOBILE_BTN_CLASS = 'dashboard-navigation-mobile-btn';
const DASHBOARD_NAVIGATION_CLOSE_MOBILE_BTN_CLASS = 'dashboard-close';
const DASHBOARD_NAVIGATION_OPEN_ADDITIONAL_CLASS = 'show';
const DASHBOARD_NAVIGATION_PAGE_ID = 'navigation-id';
const DASHBOARD_NAVIGATION_ITEM_PAGE_DATA_ATTRIBUTE = 'link';
const DASHBOARD_NAVIGATION_ITEMS_CLASS = 'dashboard-navigation-item';
const DASHBOARD_NAVIGATION_ITEM_ACTIVE_CLASS = 'active';
// header
const HEADER_BANNER = 'header-banner';

/**
 * Check height on mobile and set up another height if banner-promotion is activate
 *
 */
function checkHeightOfHeader() {
    const headerBanner = document.querySelectorAll(`.${HEADER_BANNER}`)[0];
    if (headerBanner) {
        const isPhone = utils.resolution.isPhone;
        const dashboardNavigation = document.querySelectorAll(`.${DASHBOARD_NAVIGATION_WRAPPER_CLASS}`)[0];
        if (isPhone) {
            const headerBannerHeight = headerBanner.offsetHeight;
            dashboardNavigation.style.marginTop = headerBannerHeight + 'px';
        } else {
            dashboardNavigation.style.marginTop = 0;
        }
    }
}

module.exports = {
    init: function () {
        const dashboardNavigation = document.querySelectorAll(`.${DASHBOARD_NAVIGATION_WRAPPER_CLASS}`)[0];
        const pageIdWrapper = document.getElementById(DASHBOARD_NAVIGATION_PAGE_ID);

        if (dashboardNavigation) {
            const Navigation = DashboardNavigation.build({
                pageId: pageIdWrapper.dataset.linkId,
                navigationItemActiveClass: DASHBOARD_NAVIGATION_ITEM_ACTIVE_CLASS,
                navigationItems: DASHBOARD_NAVIGATION_ITEMS_CLASS,
                navigationItemPageIdAttr: DASHBOARD_NAVIGATION_ITEM_PAGE_DATA_ATTRIBUTE,
                openButton: DASHBOARD_NAVIGATION_OPEN_MOBILE_BTN_CLASS,
                closeButton: DASHBOARD_NAVIGATION_CLOSE_MOBILE_BTN_CLASS,
                wrapper: DASHBOARD_NAVIGATION_WRAPPER_CLASS,
                openClass: DASHBOARD_NAVIGATION_OPEN_ADDITIONAL_CLASS
            });

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const navigationParam = urlParams.get('navigation');

            if (navigationParam !== 'false') {
                Navigation.open();
            }
            checkHeightOfHeader();
        }
    }
};
