const SCREEN_RESOLTION = { PHONE: '(max-width: 991px)', TABLET: '(max-width: 1439px)' };
const MODAL_INDEX_DEFAULT = 2000;

module.exports = {
    objectToQueryString: function (obj, urlString) {
        var url = urlString || '';
        var path = '';
        var hash = '';
        if (url) {
            var qsIndex = url.indexOf('?');
            var hashIndex = url.indexOf('#');
            var index;
            if (hashIndex > -1) {
                index = hashIndex;
                hash = url.substring(hashIndex);
            }
            if (qsIndex > -1) {
                index = qsIndex;
            }
            path = url.substring(0, index);
        }
        var params = $.param(obj) + hash;
        return params ? path + '?' + $.param(obj) + hash : path;
    },
    generateId: function (prefix) {
        const max = Date.now();
        return prefix + Math.floor(Math.random() * Math.floor(max));
    },
    hasSomeParentTheClass: function (element, classname) {
        if (element.className &&
            element.className.trim &&
            element.className.trim() &&
            element.className.split(' ').indexOf(classname) >= 0) {
            return true;
        }

        return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
    },
    getParentWithClass: function (element, classname) {
        if (!element || !element.parentNode) return null;
        if (element.className &&
            element.className.trim &&
            element.className.trim() &&
            element.className.split(' ').indexOf(classname) >= 0) {
            return element;
        }
        return this.getParentWithClass(element.parentNode, classname);
    },
    resolution: {
        isPhone: window.matchMedia(SCREEN_RESOLTION.PHONE).matches,
        isTablet: window.matchMedia(SCREEN_RESOLTION.TABLET).matches
    },
    modalIndex: {
        get: function () {
            if (!window.MODAL_INDEX_MAX) {
                window.MODAL_INDEX_MAX = MODAL_INDEX_DEFAULT;
            } else {
                window.MODAL_INDEX_MAX += 1;
            }
            return window.MODAL_INDEX_MAX;
        },
        set: function (itemSelector) {
            if (document.querySelectorAll(itemSelector).length) {
                document.querySelectorAll(itemSelector)[0].style.zIndex = this.get();
            }
        }
    }
};
