'use strict';

var base = require('./base');
var focusHelper = require('base/components/focus');
const carousels = require('../components/carousels');
const clientValidation = require('./../components/clientSideValidation');
const utils = require('../shared/helpers/utils');
var updateCart = require('../product/updateCart');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<div class="modal fade product-modal" id="quickViewModal" role="dialog">' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<div class="modal-content">' +
        '<div class="modal-header border-0 align-items-end flex-column pb-0">' +
        '    <button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close">' +
        '        <span class="icon icon-close" aria-hidden="true"></span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Init quick view internal carousel
 */
function initSlick() {
    if ($('.js-product-images-carousel .carousel__slide').length > 1) {
        var $carousel = $('.js-product-images-carousel');
        if (!$carousel.hasClass('.slick-initialized')) {
            $carousel.on('init reInit breakpoint', function (event, slick) {
                var dev360Slide = slick.$slides;
                var slideIndex = null;
                var slide;
                var $dots;
                var dot360;
                if (dev360Slide.length) {
                    for (var i = 0, l = dev360Slide.length; i < l; i++) {
                        slide = dev360Slide[i];
                        if (slide.classList.contains('js-360-slide')) {
                            slideIndex = i;
                        }
                    }
                }

                if (slideIndex !== null) {
                    $dots = slick.$dots;
                    dot360 = $dots.find('li').get(slideIndex);

                    if (dot360) {
                        dot360.classList.add('slide-360');
                    }
                }
            });
            $carousel.on('beforeChange', function (event, slick, currentSlideIndex, nextSlideIndex) {
                var nextSlide = slick.$slides.get(nextSlideIndex);
                var touchMove = slick.slickGetOption('touchMove');
                var cont360;
                if (nextSlide && nextSlide.classList.contains('js-360-slide')) {
                    if (touchMove) {
                        slick.slickSetOption('touchMove', false);
                        slick.slickSetOption('draggable', false);
                        slick.slickSetOption('swipe', false);
                    }

                    cont360 = nextSlide.querySelector('.cloudimage-360');
                    if (!cont360.classList.contains('initialized')) {
                        // eslint-disable-next-line no-undef
                        CI360.init();
                    }
                } else if (!touchMove) {
                    slick.slickSetOption('touchMove', true);
                    slick.slickSetOption('draggable', true);
                    slick.slickSetOption('swipe', true);
                }
            });
            $carousel.attr('dir', 'rtl');
            $carousel.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                rtl: true,
                inifinite: false,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });
        }
    }

    $('.dietry-information-carousel').attr('dir', 'rtl');
    $('.dietry-information-carousel').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        rtl: true
    });
}
/**
 * Change Measurement Unit
 * @param {element} $el - checkbox element
 */
function changeMeasurementUnit($el) {
    $.spinner().start();
    var pid = $('.product-quickview').data('pid');
    $('.product-add-to-cart-first-button').show();
    $('.product-add-to-cart-quantity-selector').empty();
    $('.cart-delete-confirmation-btn[data-pid=' + pid + ']').trigger('click');
    $('.add-to-cart[data-pid=' + pid + ']').attr('data-quantity', 0);

    if (!$($el.get(0)).prop('checked') === true) {
        $('#quickViewModal .measurement-unit-slider-input').prop('checked', false);
        $(`.measurement-unit-slider-input-tile.measurement-unit-slider-input-${pid}`).prop('checked', false);
        $(`.measurement-unit-slider-input-tile.measurement-unit-slider-input-${pid}`).val('primary');
        $('#quickViewModal .measurement-unit-slider-input').val('primary');
    } else {
        $('#quickViewModal .measurement-unit-slider-input').prop('checked', true);
        $(`.measurement-unit-slider-input-tile.measurement-unit-slider-input-${pid}`).prop('checked', true);
        $(`.measurement-unit-slider-input-tile.measurement-unit-slider-input-${pid}`).val('secondary');
        $('#quickViewModal .measurement-unit-slider-input').val('secondary');
    }

    $.spinner().stop();
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {string} url - prev url
 */
function fillModalElement(selectedValueUrl, url) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'html',
        success: function (data) {
            var parsedHtml = parseHtml(data);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);

            $('.measurement-unit-slider-input').unbind('change');
            $('.measurement-unit-slider-input').change((e) => { changeMeasurementUnit($(e.target)); });

            var pid = $('.product-quickview').data('pid');
            var tileQuantity = $(`.itemquantity-${pid} .quantity`).html();
            var quantitySpan = $('#quickViewModal .itemquantity span.quantity');
            if ($(`.measurement-unit-slider-input-tile.measurement-unit-slider-input-${pid}`).val() === 'secondary') {
                $('.measurement-unit-slider-input').val('secondary');
                $('.measurement-unit-slider-input').prop('checked', true);
                $(quantitySpan).text(tileQuantity);
            } else {
                $('.measurement-unit-slider-input').val('primary');
                $('.measurement-unit-slider-input').prop('checked', false);
                $(quantitySpan).text(tileQuantity);
            }

            $('#quickViewModal').modal('show');
            $('#quickViewModal').on('shown.bs.modal', function () {
                var $addToCartBtn = $('.product-add-to-cart .product-add-to-cart-first-button');
                carousels.quickViewDiscount();
                carousels.quickViewRecommendation();

                if ($addToCartBtn.is(':hidden')) {
                    $addToCartBtn.removeClass('d-none');
                    $addToCartBtn.hide();
                }

                $('#quickViewModal').on('scroll', function () {
                    $().vEllipsis({
                        element: '.product-name',
                        lines: 2,
                        onlyFullWords: false,
                        responsive: true,
                        tolerance: 5,
                        elementEvent: 'scroll',
                        callback: function () {
                            $(this).mouseenter(function () {
                                $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                            }).mouseleave(function () {
                                $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                            });
                        }
                    });
                });

                if (window.matchMedia('(max-width: 991px)').matches) {
                    $('.modal-backdrop').remove();
                    $('.js-container-for-product-bundle').appendTo('.js-container-for-product-bundle-mobile');
                }

                $('#quickViewModal').on('scroll resize', function () {
                    if ($('.js-quantity').isInViewport()) {
                        $('.sticky-panel').removeClass('sticky');
                    } else {
                        $('.sticky-panel').addClass('sticky');
                    }

                    $().vEllipsis({
                        element: '.pdp-link .js-quickview',
                        lines: 2,
                        onlyFullWords: true,
                        responsive: true,
                        tolerance: 5,
                        elementEvent: 'scroll'
                    });

                    $().vEllipsis({
                        element: '.bundle-item .product-name',
                        lines: 2,
                        onlyFullWords: true,
                        responsive: true,
                        tolerance: 5,
                        elementEvent: 'scroll',
                        callback: function () {
                            $(this).mouseenter(function () {
                                $(this).siblings('.name-on-hover').removeClass('d-none');
                            }).mouseleave(function () {
                                $(this).siblings('.name-on-hover').addClass('d-none');
                            });
                        }
                    });
                });

                $('.sticky-panel .product-name').mouseenter(function () {
                    $(this).siblings('.name-on-hover').removeClass('d-none');
                }).mouseleave(function () {
                    $(this).siblings('.name-on-hover').addClass('d-none');
                });

                initSlick();

                $().vEllipsis({
                    element: '.js-product-main .product-name, .bundle-item .product-name',
                    lines: 2,
                    onlyFullWords: true,
                    responsive: true,
                    tolerance: 5,
                    elementEvent: 'scroll',
                    callback: function () {
                        $(this).mouseenter(function () {
                            $(this).siblings('.name-on-hover').removeClass('d-none');
                        }).mouseleave(function () {
                            $(this).siblings('.name-on-hover').addClass('d-none');
                        });
                    }
                });

                $('body').on('mouseenter', '.promotion-badge', function () {
                    $(this).siblings('.name-on-hover').removeClass('d-none');
                });
                $('body').on('mouseleave', '.promotion-badge', function () {
                    $(this).siblings('.name-on-hover').addClass('d-none');
                });

                var promotionPDP = $('.qv-details-carousel-wrapper').find('.promotion-banner');

                $().vEllipsis({
                    element: promotionPDP,
                    lines: 1,
                    responsive: true,
                    tolerance: 5,
                    elementEvent: 'scroll',
                    onlyFullWords: false,
                    callback: function () {
                        $(this).mouseenter(function () {
                            $(this).siblings('.name-on-hover').removeClass('d-none');
                        }).mouseleave(function () {
                            $(this).siblings('.name-on-hover').addClass('d-none');
                        });
                    }
                });
            });

            $('#quickViewModal').on('hide.bs.modal', function () {
                window.history.replaceState(null, null, url ? window.location.pathname + url : window.location.pathname);
            });

            $('body').trigger('quickview:ready');
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Function that calls pop up require registration
 * @param {string} errorMessage - errorMessage
 */
function popUpRegistrationRequired(errorMessage) {
    $('#quickViewModal').modal('hide');
    $('#modalLogin').find('#action-common-error').text(errorMessage).show();
    $('#modalLogin').find('input[name=loginEmail]').addClass('border-for-error-message');
    $('#modalLogin').modal('show');
    $('#modalLogin').on('hide.bs.modal', function () {
        $('#modalLogin').find('#action-common-error').text(errorMessage).hide();
        $('#modalLogin').find('input[name=loginEmail]').removeClass('border-for-error-message');
    });
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },
    displayProductModal: function () {
        var url = $('.product-modal-js').data('productModalUrl');
        if (url) {
            $('.js-quickview').eq(0).trigger('quickview:show');
            $('#quickViewModal.show + .modal-backdrop.show').remove();
            getModalHtmlElement();
            fillModalElement(url);
            utils.modalIndex.set('#quickViewModal + .modal-backdrop');
            utils.modalIndex.set('#quickViewModal');
            window.MODAL_COUNTER += 1;
        }
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    showProductQuickview: function () {
        $('body').on('click', '.js-quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('.js-quickview').data('href');
            $(e.target).trigger('quickview:show');
            $('#quickViewModal.show + .modal-backdrop.show').remove();
            getModalHtmlElement();
            fillModalElement(selectedValueUrl, window.location.search || false);
            utils.modalIndex.set('#quickViewModal + .modal-backdrop');
            utils.modalIndex.set('#quickViewModal');
            window.MODAL_COUNTER += 1;
            var productId = $(this).closest('.product').data('pid');
            window.history.replaceState(null, null, `?mpid=${productId}`);
        });
    },
    hideProductQuickview: function () {
        $('body').on('click', '.quick-view-dialog .close', function () {
            window.MODAL_COUNTER -= 1;
            if (window.MODAL_COUNTER === 0) {
                $('body').removeClass('lock-scroll');
            }
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr(
                'disabled',
                (!response.product.readyToOrder || !response.product.available)
            );

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr(
                'disabled',
                !$('.global-availability', dialog).data('ready-to-order') ||
                !$('.global-availability', dialog).data('available')
            );
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    },
    showChooseList: function () {
        $('body').on('click', '.add-to-shopinglist', function () {
            $('#prodComment').addClass('d-none');
            let isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
            var $button = $(this);
            var url = $button.data('url');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    if (data.success === 'OK') {
                        if (isMobile) {
                            $('.js-cart-list').empty().append(data.renderedTemplateMobile);
                            $('#cartListModal').modal('show');
                            $('.modal-backdrop').css('z-index', '1060');
                            $('.custom-select').selectmenu({
                                classes: {
                                    'ui-selectmenu-button': 'shopping-list-select',
                                    'ui-selectmenu-menu': 'shopping-list-menu-mobile'
                                },
                                open: function () {
                                    $('#quickViewModal').css('overflow-y', 'hidden');
                                },
                                close: function () {
                                    $('#quickViewModal').css('overflow-y', 'auto');
                                }
                            });
                            $('.custom-select').menu({
                                position: {
                                    of: '#cartListModal'
                                }
                            });
                        } else {
                            $('.shopping-list-container').removeClass('d-none');
                            $('.shopping-list-container').empty().append(data.renderedTemplateDesktop);
                            $('.custom-select').selectmenu({
                                classes: {
                                    'ui-selectmenu-button': 'shopping-list-select',
                                    'ui-selectmenu-menu': 'shopping-list-menu'
                                },
                                open: function () {
                                    $('#quickViewModal').css('overflow-y', 'hidden');
                                    $('#quickViewModal').css('padding-left', '14px');
                                },
                                close: function () {
                                    $('#quickViewModal').css('overflow-y', 'auto');
                                    $('#quickViewModal').css('padding-left', '0');
                                }
                            });
                            $('.custom-select').menu({
                                position: {
                                    of: '#quickViewModal'
                                }
                            });
                        }
                        $('.custom-select').on('selectmenuopen', function () {
                            utils.modalIndex.set('.shopping-list-menu');
                            window.MODAL_COUNTER += 1;
                        });
                    } else if (data.success === 'FALSE') {
                        popUpRegistrationRequired(data.errorMessage);
                    }
                }
            });
        });
        return true;
    },
    showCreateList: function () {
        $('body').on('click', '.create-new-list', function () {
            var $button = $(this);
            var url = $button.data('url');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    $('.shopping-list-container').empty().append(data.renderedTemplateDesktop);
                    clientValidation.invalid();
                }
            });
            return true;
        });
    },

    closeListDropDown: function () {
        $('body').on('click', '.close-dropdown', function () {
            $('.shopping-list-container').addClass('d-none');
        });
    },
    createList: function () {
        $('body').on('submit', '.save-new-list-large-view', function (e) {
            e.preventDefault();
            $.spinner().start();
            var $form = $(this);
            var url = $form.attr('action');
            clientValidation.functions.validateForm($form, e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    if (data.errorMessage) {
                        $form.find('.invalid-feedback').text(data.errorMessage).show();
                    } else if (data.addProductUrl) {
                        var crf = $('.csrfToken')[0];
                        var request = {};
                        request[crf.name] = crf.value;
                        request.listName = data.listName;
                        $.ajax({
                            url: data.addProductUrl,
                            type: 'post',
                            dataType: 'json',
                            data: request
                        });
                        $('.shopping-list-container').addClass('d-none');
                    } else if (data.success === 'Ok') {
                        $('.shopping-list-container').addClass('d-none');
                    }
                }
            });
            $.spinner().stop();
            return true;
        });
    },
    createListMobile: function () {
        $('body').on('submit', '.save-new-list-mobile-view', function (e) {
            e.preventDefault();
            $.spinner().start();
            var $form = $(this);
            var url = $form.attr('action');
            clientValidation.functions.validateForm($form, e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    if (data.success === 'Ok') {
                        if (data.addProductUrl) {
                            var crf = $('.csrfToken')[0];
                            var request = {};
                            request[crf.name] = crf.value;
                            request.listName = data.listName;
                            $.ajax({
                                url: data.addProductUrl,
                                type: 'post',
                                dataType: 'json',
                                data: request
                            });
                        }
                        $('#cartListModal').modal('hide');
                    } else if (data.success === 'FALSE') {
                        $form.find('.invalid-feedback').text(data.errorMessage).show();
                    }
                }
            });
            $.spinner().stop();
            return true;
        });
    },
    productConcurentModal: function () {
        $('body').on('click', '.js-concurent-modal', function () {
            var $btn = $(this);
            var $checkUser = $btn.parent('#comment-section').attr('data-customer');
            if ($checkUser === 'true') {
                var targetModalSel = $btn.data('target');
                var $targetModal = $(targetModalSel);
                var $textField;

                if ($btn.hasClass('js-comment-btn')) {
                    $('.shopping-list-container').addClass('d-none');
                    var $removeBtn = $targetModal.find('.js-comment-remove');
                    var commentTxt = $btn.data('comment');
                    var $tokenEl = $targetModal.find('.js-comment-token');

                    if (commentTxt) {
                        $textField = $targetModal.find('.js-comment-text');
                        $textField.val(commentTxt);
                    }
                    $tokenEl.attr('name', $btn.data('tokenName'));
                    $tokenEl.val($btn.data('token'));
                    $removeBtn.toggleClass('d-none', !commentTxt);
                }
                $targetModal.toggleClass('d-none');
            } else {
                var textMsg = $btn.parent('#comment-section').attr('data-msg');
                popUpRegistrationRequired(textMsg);
            }
        });

        $('body').on('click', '.js-popover-modal-close', function (event) {
            var $btn = $(event.currentTarget);
            var $targetModal = $btn.closest('.js-popover-modal');
            $targetModal.addClass('d-none');
            // Is this modal already opened
            //      Close all modal
            // else open this modal
        });
    },
    productComments: function () {
        // Initialize Comment after quickView has been opened
        $('body').on('quickview:ready', function () {
            var $addCommentBtn = $('.js-comment-btn');
            var updateUrl = $addCommentBtn.data('update-url');
            var $commentIcon = $('.js-comment-icon');
            var $noCommentIcon = $('.js-comment-icon-empty');
            $.ajax({
                url: updateUrl,
                type: 'get',
                dataType: 'json',
                success(data) {
                    if (data.error) {
                        // Normally this scenario not heppen
                    } else {
                        if (!data.empty) {
                            $addCommentBtn.data('comment', data.comment);
                        }
                        $addCommentBtn.data('tokenName', data.csrf.tokenName);
                        $addCommentBtn.data('token', data.csrf.token);
                        $commentIcon.toggleClass('d-none', data.empty);
                        $noCommentIcon.toggleClass('d-none', !data.empty);
                    }
                }
            });
        });

        $('body').on('submit', '.js-comment-form', function (event) {
            event.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var formData = $form.serialize();
            var $textField = $form.find('.js-comment-text');
            var btnId = $form.closest('.js-popover-modal').attr('id');
            var $btn = $('[data-target="#' + btnId + '"]');
            var $removeBtn = $form.find('.js-comment-remove');
            var $commentIcon = $btn.find('.js-comment-icon');
            var $noCommentIcon = $btn.find('.js-comment-icon-empty');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: formData
            }).done(function (data) {
                if (data.error) {
                    console.log('ERROR comment ajax request'); // eslint-disable-line
                } else {
                    updateCart.updateCartView();
                    // Update comment button icon and data attr
                    if (data.empty) {
                        $textField.val('');
                    }
                    $commentIcon.toggleClass('d-none', data.empty);
                    $noCommentIcon.toggleClass('d-none', !data.empty);
                    $removeBtn.toggleClass('d-none', data.empty);
                    $btn.data('comment', data.comment);
                }
            }).fail(function () {
                // var t = data;
                // var t1 = data.error;
            }).always(function () {
                $.spinner().stop();
            });
        });

        $('body').on('click', 'input.js-comment-remove, input.js-comment-save', function () {
            var $this = $(this);
            var $form = $this.closest('.js-comment-form');
            var $targetModal = $('.js-popover-modal');
            var $actionEl = $form.find('.js-comment-action');
            var action = $this.data('action');
            $actionEl.val(action);
            $targetModal.addClass('d-none');
        });
    }
};
