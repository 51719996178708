const base = require('base/product/base');
const detail = require('base/product/detail');

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @return {undefined}
 */
function updateAddToCart(quantity, selector) {
    $(selector).empty().html(quantity);
    if (quantity && quantity > 0) {
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').removeClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').addClass('show');
    } else {
        $(selector).empty().html(1);
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').removeClass('show');
    }
}

/**
 * Update PDP add to cart button after minicart update
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    const items = $('.cart-json').data('cart');
    const pid = $('.product-detail').data('pid');
    let quantity = 0;
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            if (item.id === pid) {
                quantity = item.qty;
            }
        });
    }
    updateAddToCart(quantity, `.itemquantity-${pid} .quantity`);
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.add-to-cart').data('pid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-update-quantity');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="'}${data.maxBonusItems}"` +
        `data-UUID="${data.uuid}"` +
        `data-pliUUID="${data.pliUUID}"` +
        `data-addToCartUrl="${data.addToCartUrl}"` +
        'data-pageStart="0"' +
        `data-pageSize="${data.pageSize}"` +
        `data-moreURL="${data.showProductsUrlRuleBased}"` +
        `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">` +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `    <span class="">${data.labels.selectprods}</span>` +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    $('.minicart').trigger('count:update', response);
    if (response && response.cart && response.cart.items) {
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.cart.items.filter(item => item.id === pid);
        if (products && products.length > 0) {
            el.closest('.add-to-cart').find('.update-quantity').addClass('show');
            el.removeClass('show');
        }
    }


    const messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(`<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${
            response.message
        }</div>`);

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    if (response && response.items && response.items) {
        let product;
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.items.filter(item => item.id === pid);
        if (products && products.length > 0) {
            product = products.pop();
            el.closest('.add-to-cart').find('.update-quantity .quantity').html(product.quantity);
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            },
        });
    }
}

module.exports = {
    exportDetails: $.extend({}, base, detail, {}),
    addToCart() {
        $(document).on('click', 'button.tile-add-to-cart', function () {
            const el = $(this);
            $('body').trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            const addToCartUrl = getAddToCartUrl(el);

            const form = {
                pid,
                quantity: 0,
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success(data) {
                        handlePostCartAdd(data, el);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    },
                });
            }
        });
    },
    updateQuantityCart() {
        $(document).on('click', 'button.tile-update-quantity', function () {
            const el = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));
            const updateQuantityUrl = getUpdateQuantityUrl($(this));
            const decrease = $(this).data('decrease');

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            const form = {
                pid,
                decrease,
                quantity: 1,
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (updateQuantityUrl) {
                $.ajax({
                    url: updateQuantityUrl,
                    method: 'GET',
                    data: form,
                    success(data) {
                        $('.minicart').trigger('count:update', data);
                        $('body').trigger('product:afterAddToCart', data);
                        if (data && data.basket && data.basket.items) {
                            const id = el.closest('.add-to-cart').data('pid');
                            const products = data.basket.items.filter(item => item.id === id);
                            if (products.length === 0) {
                                el.closest('.add-to-cart').find('.update-quantity').removeClass('show');
                                el.closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
                            }
                        } else if (data && data.items) {
                            updateQuantity(data, el);
                        }
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    },
                });
            }
        });
    },

    updateMinicart() {
        $('body').on('cart:update', () => {
            updateQuantities();
        });
    },

    productLoad: updateQuantities,
};
