'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var KlaviyoApi = require('../shared/classes/klavioyApi.class').KlaviyoApi;
const { refreshCSRFToken } = require('../shared/helpers/form');

// selectors const
const REFRESH_MODAL_SELECTOR = '#refreshModal';
const REFRESH_MODAL_TOOGLER_PROPERTY = 'showChangedPricePopup';
const MINICART_WRAPPER_SELECTOR = '.js-minicart-container';

/**
 * Login user store data logic
 *
 */
function unsetLoginUser() {
    if (!$('.registered').length) {
        localStorage.removeItem('userSelected');
    }

    if (!$('.address-name').length) {
        localStorage.removeItem('storeSelected');
    }
}

const kaliyo = document.querySelectorAll('.register-form')[0];
let klaviyoApi;

if (kaliyo) {
    const kaliyoData = kaliyo.dataset;
    klaviyoApi = KlaviyoApi.build({
        apiKey: kaliyoData.klaviyoApiKey,
        listId: kaliyoData.klaviyoUserSubscriptionListId,
        klaviyoApiUrl: kaliyoData.klaviyoRestApiUrl,
        proxyServer: kaliyoData.proxyServer
    });
}

module.exports = {
    refresh: function () {
        var showChangedPricePopup = $(MINICART_WRAPPER_SELECTOR).data(REFRESH_MODAL_TOOGLER_PROPERTY);

        if (showChangedPricePopup) {
            $(REFRESH_MODAL_SELECTOR).modal('show');
        }

        $('.refresh-modal-close').on('click', function () {
            $(REFRESH_MODAL_SELECTOR).modal('hide');
        });
    },

    login: function () {
        unsetLoginUser();

        $('form.login').submit(async function (e) {
            e.preventDefault();
            if (window.sessionStorage.getItem('hasShowedConfirmationLegalNotice')) {
                $('input[name="hasShowedConfirmationLegalNotice"]').val('true');
            }
            var form = $(this);
            await refreshCSRFToken(form);
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                        $('.alert').insertAfter($('#login-button'));
                    } else {
                        $('form.login').trigger('login:success', data);
                        $('#login-button').attr('disabled', true);
                        window.location.href = data.redirectUrl;
                        localStorage.setItem('userSelected', 'true');
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            if (window.sessionStorage.getItem('hasShowedConfirmationLegalNotice')) {
                $('input[name="hasShowedConfirmationLegalNoticeNew"]').val('true');
            }
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('.club-member-already-text').css('border-color', '#e30000');
                    } else {
                        const subscribeCheckbox = document.getElementById('emailSubscribe').checked;
                        if (subscribeCheckbox) {
                            const email = document.getElementById('registration-form-email').value;
                            klaviyoApi.addMemeberToList(email);
                            klaviyoApi.subscribeProfileToList(email);
                        }
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.js-reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.js-reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitSmsButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-sms-btn').empty()
                                .html('<a href="' +
                                    data.returnUrl +
                                    '" class="btn btn-primary btn-block">' +
                                    data.buttonText + '</a>');
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    // TODO: check this functional after backend implementation
    showForgotPasswordPopup: function () {
        $('.password-reset').on('click', function () {
            $('.login-form').addClass('d-none');
            $('.forgot-password-form').removeClass('d-none');
            $('#modalLogin .nav-tabs').hide();
            if (window.matchMedia('(min-width: 1200px)').matches) {
                $('.modal-header').css('background', '#f2f2f0 ');
            }
        });

        $('.sms-email').on('change', function () {
            if ($(this).prop('checked') === true) {
                $(this).val('sms');
                $('.email-form').addClass('d-none');
                $('.sms-form').removeClass('d-none');
            } else {
                $(this).val('email');
                $('.email-form').removeClass('d-none');
                $('.sms-form').addClass('d-none');
            }
        });

        $('.modal.login .icon-close').on('click', function () {
            if ($('.login-form, .register-form').hasClass('d-none')) {
                $('#modalLogin .nav-tabs').show();
                $('.login-form, .register-form').removeClass('d-none');
                $('.forgot-password-form, .already-club-member-form').addClass('d-none');
                $('.club-member-already-text, .club-member-area').removeClass('d-none');
            }
        });
    },

    changeSubscriptionEmailValue: function () {
        $('.email-subscription').on('change', function () {
            if ($(this).prop('checked') === true) {
                $('.email-subscription').val(true);
            } else {
                $('.email-subscription').val(false);
            }
        });
    },

    changeRegisterForm: function () {
        $('.link.already-club-member').on('click', function (e) {
            e.preventDefault();
            $('.register-form').addClass('d-none');
            $('.already-club-member-form').removeClass('d-none');
        });

        $('.join-club-member').on('change', function () {
            if ($(this).prop('checked') === true) {
                $(this).val('no-join');
                $('#clubNumberId').attr('disabled', true);
                $('.slider').addClass('no-join');
                $('.join-club-member-form').addClass('d-none');
            } else {
                $(this).val('join');
                $('#clubNumberId').attr('disabled', false);
                $('.slider').removeClass('no-join');
                $('.join-club-member-form').removeClass('d-none');
            }
        });
    },
    showFullName: function () {
        $('.user-message.registered').mouseenter(function () {
            $(this).siblings('.name-on-hover').removeClass('d-none');
        }).mouseleave(function () {
            $(this).siblings('.name-on-hover').addClass('d-none');
        });
    },

    existClubMember: function () {
        $('.club-member').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.club-member').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.data.success) {
                        formValidation(form, data);
                    } else {
                        // render the modalLogin template with filled data
                        $('.club-member-registration-error').text('');
                        $('.register-form').removeClass('d-none');
                        $('.already-club-member-form').addClass('d-none');
                        $('.club-member-already-text').addClass('d-none');
                        $('.already-club-member-message').removeClass('d-none');
                        $('.club-member-area').remove();
                        if (data.data.returnFirstName) {
                            $('#registration-form-fname').val(data.data.returnFirstName).attr('readOnly', true);
                        }
                        if (data.data.returnLastName) {
                            $('#registration-form-lname').val(data.data.returnLastName);
                        }
                        if (data.data.returnEmail) {
                            $('#registration-form-email').val(data.data.returnEmail);
                            $('#registration-form-email-confirm').val(data.data.returnEmail);
                        }
                        if (data.data.returnPhoneNumber) {
                            $('#registration-form-phone').val(data.data.returnPhoneNumber);
                        }
                        $('#registration-form-civilityID').val(data.data.civilityID);
                    }

                    if (data.data) {
                        // clean errors after submit
                        $('.club-member-registration-error').text('');
                        $('#registration-form-phone').removeClass('is-invalid');
                        $('#register-id-number').removeClass('is-invalid');
                        $('.form-phone-error').text('');
                        var ncrErrorText = data.data.hasOwnProperty('errorMsg') ? data.data.errorMsg : ''; // eslint-disable-line
                        if (data.data.field) {
                            $('#registration-form-phone').addClass('is-invalid');
                            $('.form-phone-error').text(ncrErrorText);
                        } else {
                            $('#register-id-number').addClass('is-invalid');
                            $('.club-member-registration-error').text(ncrErrorText);
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
        });
    },

    restoreRegistrationForm: function () {
        $('#register-tab').on('click', function () {
            $('.register-form').removeClass('d-none');
            $('.already-club-member-form').addClass('d-none');
        });
    }
};
