const utils = require('../shared/helpers/utils');

class RemoveComponent {
    static build(data) {
        return new RemoveComponent()
            .setButtonClass(data.button)
            .setWarpperClass(data.wrapper)
            .setRemoveCallbeck(data.removeCallbeck)
            .initEvents();
    }

    setButtonClass(buttonClass) {
        this.buttonClass = buttonClass;
        return this;
    }

    setWarpperClass(wrapperClass) {
        this.wrapperClass = wrapperClass;
        return this;
    }

    setRemoveCallbeck(removeCallbeck) {
        this.removeCallbeck = removeCallbeck;
        return this;
    }

    removeWrapper(target) {
        const wrapper = utils.getParentWithClass(target, this.wrapperClass);
        wrapper.remove();
        return this;
    }

    removeEvent(target) {
        this.removeCallbeck(target, this);
    }

    initEvents() {
        const body = document.querySelectorAll('body')[0];
        body.addEventListener('click', (e) => {
            const hasSomeParentTheClass = utils.hasSomeParentTheClass(e.target, this.buttonClass);
            if (e.target.classList.contains(this.buttonClass) || hasSomeParentTheClass) {
                e.preventDefault();
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, this.buttonClass);
                    if (!target) return;
                }
                this.removeEvent(target);
            }
        });
        return this;
    }
}

module.exports = {
    RemoveComponent: RemoveComponent
};
