const RequestService = require('../shared/services/request.service').RequestService;
const Modal = require('../shared/classes/modal.class').Modal;
const base = require('../product/base');
const utils = require('../shared/helpers/utils');

// order variables
const REORDER_BUTTON_CLASS = 'order-reorder-btn';
const ORDER_DETAILS_WRAPPER_CLASS = 'order-details-wrapper';
const ORDER_DETAILS_BODY_CLASS = 'order-details-body';
const ORDER_LIST_BODY_CLASS = 'order-shoping-item-body';
const ORDER_DETAILS_BUTTON_CLASS = 'order-details-btn';
const ORDER_EDIT_CLASS = 'edit-order';
const CART_OPEN_CLASS = 'js-cart-icon';
const MOBILE_MENU_CONTAINER = 'mobile-menu-container';

// utils helper
const isPhone = utils.resolution.isPhone;

/**
 * Add ellipsis to long product titles
*/
function shortenProductTitles() {
    $().vEllipsis({
        element: '.order-details-tile-name-value',
        lines: 2,
        onlyFullWords: false,
        responsive: true,
        tolerance: 5,
        callback: function () {
            $(this).on('mouseenter', function () {
                $(this).siblings('.name-on-hover').removeClass('d-none');
            }).on('mouseleave', function () {
                $(this).siblings('.name-on-hover').addClass('d-none');
            });
        }
    });
}

/**
 * Open minicart trigger
 *
 */
function openMinicart() {
    document.querySelectorAll(`.${CART_OPEN_CLASS}`)[0].click();
}

/**
 * Add order to cart
 * @param {string} url - url for add requst
 * @param {Object} context - modal instances
 */
function addProductsToCart(url, context) {
    if (!url) return;
    RequestService.setAddToCartUrl(url);
    RequestService.getAddToCard(null, (res) => {
        const resJson = JSON.parse(res);
        if (resJson.success === 'OK') {
            if (isPhone) {
                openMinicart();
            }
            base.updateMinicartView();

            if (context) {
                $(`#${context.id}`).modal('hide');
            }
        }
    });
}

/**
 * Get order details data
 * @param {string} url - order details url
 * @param {Object} context - modal instances
 */
function showOrderDetails(url, context) {
    if (!url) return;
    RequestService.setOrderDetailUrl(url);
    RequestService.getOrderDetail(null, (res) => {
        context.setContent(res, ORDER_DETAILS_WRAPPER_CLASS);
        $(`#${context.id}`).modal('show');
    });
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true" class="float-right">&times;</span>' +
        '</button>'}${message}</div>`;

    $('.cart-error').html(errorHtml);
    if (isPhone) {
        openMinicart();
    }
}

/**
 * opem cart for mobile view
 */
function showMobileCart() {
    if (document.querySelectorAll(`.${MOBILE_MENU_CONTAINER}`).length) {
        openMinicart();
    }
}

/**
 * open cart by parameter
 */
function showCartByParam() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openCart = urlParams.get('openCart');

    if (openCart) {
        setTimeout(() => {
            showMobileCart();
        }, 0);
    }
}

module.exports = {
    init: function () {
        $(window).on('shown.bs.modal', () => {
            shortenProductTitles();

            $(`.${ORDER_DETAILS_WRAPPER_CLASS}, .${ORDER_DETAILS_BODY_CLASS}, .${ORDER_LIST_BODY_CLASS}`).on('scroll', () => {
                $(window).trigger('scroll');
            });
        });

        $('body').on('click', `.${REORDER_BUTTON_CLASS}`, (e) => {
            e.preventDefault();
            // const url = e.target.getAttribute('href');
            const url = $(e.currentTarget).attr('href');
            addProductsToCart(url);
        });

        const orderDetailsBtn = document.querySelectorAll(`.${ORDER_DETAILS_BUTTON_CLASS}`)[0];
        if (orderDetailsBtn) {
            Modal.build({
                openButtonClass: ORDER_DETAILS_BUTTON_CLASS,
                modalConfigurations: {
                    okButtonText: orderDetailsBtn.dataset.modalBtn,
                    okButtonHandler: (context) => {
                        const url = context.triggerButton.dataset.reorder;
                        if (url !== 'null') {
                            addProductsToCart(url, context);
                        }
                    }
                },
                openHandler: (target, context) => {
                    const url = target.getAttribute('href');
                    if (url !== 'null') {
                        showOrderDetails(url, context);
                    }
                }
            });
        }

        const editOrderBtn = document.querySelector(`.${ORDER_EDIT_CLASS}`);
        if (editOrderBtn) {
            $('body').on('click', `.${ORDER_EDIT_CLASS}`, (e) => {
                e.preventDefault();
                let url = $(e.currentTarget).data('action');
                const orderid = $(e.currentTarget).data('order');
                const merge = $(e.currentTarget).data('merge');
                const urlParams = {
                    orderid,
                    merge
                };

                url = appendToUrl(url, urlParams);

                $.spinner().start();
                $.ajax({
                    url,
                    type: 'post',
                    dataType: 'json',
                    success(data) {
                        if (data.error) {
                            createErrorNotification(data.errorID);
                        }
                        if (data.success) {
                            // createErrorNotification(data.msgID);
                        }

                        if (data.storageData) {
                            const paramsNames = Object.keys(data.storageData);
                            paramsNames.forEach((item) => {
                                localStorage.setItem(item, data.storageData[item]);
                            });
                        }

                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl + (isPhone ? '?openCart=true' : '');
                        }
                        $.spinner().stop();
                    },
                    error(err) {
                        if (err.redirectUrl) {
                            window.location.href = err.redirectUrl;
                        } else {
                            createErrorNotification(err.errorMessage);
                        }
                        $.spinner().stop();
                    }
                });
            });
        }
        showCartByParam();
    }
};
