const utils = require('../helpers/utils');

class User {
    static build(data) {
        return new User()
            .setUserData(data.fields)
            .setActions(data.actions)
            .setCallbacks(data.callbacks)
            .initEvents();
    }

    setUserData(data) {
        if (!data) return false;
        this
            .setFirstName(data.firstName)
            .setLastName(data.lastName)
            .setPhone(data.phone)
            .setEmail(data.email);
        return this;
    }

    updateUserValue(data) {
        if (!data) return false;
        this.firstName.value = data.firstName;
        this.lastName.value = data.lastName;
        this.email.value = data.email;
        this.phone.value = data.phone;
        return this;
    }

    setCallbacks(data) {
        if (!data) return false;
        this
            .setSaveUserDataCallback(data.saveUserDataCallback)
            .setResetUserDataCallback(data.resetUserDataCallback);
        return this;
    }

    setResetUserDataCallback(resetUserDataCallback) {
        this.resetUserDataCallback = resetUserDataCallback;
        return this;
    }

    setSaveUserDataCallback(saveUserDataCallback) {
        this.saveUserDataCallback = saveUserDataCallback;
        return this;
    }

    setActions(data) {
        if (!data) return false;
        this
            .setEditButtonClass(data.editButtonClass)
            .setSaveButtonClass(data.saveButtonClass)
            .setSaveButton(data.saveButtonClass);
        return this;
    }

    setEditButtonClass(editButtonClass) {
        this.editButtonClass = editButtonClass;
        return this;
    }

    setSaveButtonClass(saveButtonClass) {
        this.saveButtonClass = saveButtonClass;
        return this;
    }

    setSaveButton(saveButtonClass) {
        this.saveButton = document.querySelectorAll(`.${saveButtonClass}`)[0];
        return this;
    }

    setFirstName(firstName) {
        this.firstName = firstName;
        return this;
    }

    getFirstName() {
        return this.firstName.value;
    }

    setLastName(lastName) {
        this.lastName = lastName;
        return this;
    }

    getLastName() {
        return this.lastName.value;
    }

    setPhone(phone) {
        this.phone = phone;
        return this;
    }

    getPhone() {
        return this.phone.value;
    }

    setEmail(email) {
        this.email = email;
        return this;
    }

    getEmail() {
        return this.email.value;
    }

    enableEdit() {
        this.firstName.disabled = false;
        this.lastName.disabled = false;
        this.email.disabled = false;
        this.phone.disabled = false;
        this.saveButton.disabled = false;

        this.cacheData();

        this.editableMode = true;
    }

    disableEdit(update = false) {
        this.firstName.disabled = true;
        this.lastName.disabled = true;
        this.email.disabled = true;
        this.phone.disabled = true;
        this.saveButton.disabled = true;

        if (!update) {
            this.resetFieldsData();
        }

        this.editableMode = false;
    }

    toggleEditableMode() {
        if (this.editableMode) {
            this.disableEdit();
        } else {
            this.enableEdit();
        }
    }

    cacheData() {
        this.cache = {
            firstName: this.getFirstName(),
            lastName: this.getLastName(),
            email: this.getEmail(),
            phone: this.getPhone()
        };

        return this;
    }

    resetFieldsData() {
        this.updateUserValue(this.cache);
        this.resetUserDataCallback();
    }

    save() {
        this.saveUserDataCallback(this);
    }

    initEvents() {
        const body = document.querySelectorAll('body')[0];
        body.addEventListener('click', (e) => {
            const hasSomeParentTheClassEditButton = utils.hasSomeParentTheClass(e.target, this.editButtonClass);
            const hasSomeParentTheClassSaveButton = utils.hasSomeParentTheClass(e.target, this.saveButtonClass);
            if (e.target.classList.contains(this.editButtonClass) || hasSomeParentTheClassEditButton) {
                let target = e.target;
                if (hasSomeParentTheClassEditButton) {
                    target = utils.getParentWithClass(e.target, this.editButtonClass);
                    if (!target) return;
                }
                e.preventDefault();
                this.toggleEditableMode();
                if (target.classList.contains('club-member')) {
                    this.firstName.disabled = true;
                }
            } else if (e.target.classList.contains(this.saveButtonClass) || hasSomeParentTheClassSaveButton) {
                let target = e.target;
                if (hasSomeParentTheClassSaveButton) {
                    target = utils.getParentWithClass(e.target, this.saveButtonClass);
                    if (!target) return;
                }
                e.preventDefault();
                this.save();
            }
        });
    }
}

module.exports = {
    User: User
};
