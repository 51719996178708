'use strict';

var Switcher = require('../shared/classes/switcher.class').Switcher;
var togglerElement = document.getElementById('about-switcher');

module.exports = {
    init: function () {
        if (togglerElement) {
            Switcher.build({
                toggler: '#about-switcher',
                offWrapper: togglerElement.dataset.off,
                onWrapper: togglerElement.dataset.on
            });
        }
    }
};
