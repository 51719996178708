const dashboardNavigation = require('./dashboardNavigation');
const orderDetails = require('./orderDetails');
const productsList = require('./productsList');
const userInfo = require('./userInfo');
const userAddress = require('./userAddress');
const userCreditCard = require('./userCreditCard');
const countDownTimer = require('./countDownTimer');
const userAvatar = require('./userAvatar');
const userCoupons = require('./userCoupons');
const giftCertificate = require('./giftCertificate');

module.exports = {
    init: function () {
        //  common init
        dashboardNavigation.init();
        userAvatar.init();

        //  order tab
        orderDetails.init();
        productsList.init();
        countDownTimer.init();

        //  user tab
        userInfo.init();
        userAddress.init();
        userCreditCard.init();

        // coupons tab
        userCoupons.init();

        // gift cert check form
        giftCertificate.init();
    }
};
