const utils = require('../shared/helpers/utils');

const OPERATION = {
    INCREASE: 'increase',
    DECREASE: 'decrease'
};

class OperationComponent {
    static build(data) {
        return new OperationComponent()
            .setOperationButtonClass(data.button)
            .setOperationValueClass(data.value)
            .setOperationWrapperClass(data.wrapper)
            .setChangeValueCallback(data.changeValueCallback)
            .initEvents();
    }

    setOperationButtonClass(button) {
        this.buttonClass = button;
        return this;
    }

    setOperationValueClass(value) {
        this.valueClass = value;
        return this;
    }

    setOperationWrapperClass(wrapper) {
        this.wrapperClass = wrapper;
        return this;
    }

    getOperationValue(target) {
        const wrapper = utils.getParentWithClass(target, this.wrapperClass);
        const valueWrapper = wrapper.querySelectorAll(`.${this.valueClass}`)[0];
        return parseInt(valueWrapper.innerHTML, 10) || 0;
    }

    setOperationValue(value, target) {
        const wrapper = utils.getParentWithClass(target, this.wrapperClass);
        const valueWrapper = wrapper.querySelectorAll(`.${this.valueClass}`)[0];
        valueWrapper.innerHTML = value;
    }

    setChangeValueCallback(changeValueCallback) {
        this.changeValueCallback = changeValueCallback;
        return this;
    }

    setValue(value, target) {
        this.value = value;
        this.setOperationValue(value, target);
        return this;
    }

    increase(target) {
        const value = this.getOperationValue(target) + 1;
        this.changeValueCallback(value, target, this);
    }

    decrease(target) {
        const value = this.getOperationValue(target) - 1;
        if (value < 0) return;
        this.changeValueCallback(value, target, this);
    }

    operationEvent(target) {
        const currentOperation = target.dataset.operation;
        if (currentOperation === OPERATION.INCREASE) {
            this.increase(target);
        } else if (currentOperation === OPERATION.DECREASE) {
            this.decrease(target);
        }
    }

    initEvents() {
        const body = document.querySelectorAll('body')[0];
        body.addEventListener('click', (e) => {
            const hasSomeParentTheClass = utils.hasSomeParentTheClass(e.target, this.buttonClass);
            if (e.target.classList.contains(this.buttonClass) || hasSomeParentTheClass) {
                e.preventDefault();
                let target = e.target;
                if (hasSomeParentTheClass) {
                    target = utils.getParentWithClass(e.target, this.buttonClass);
                }
                this.operationEvent(target);
            }
        });
        return this;
    }
}

module.exports = {
    OperationComponent: OperationComponent
};
