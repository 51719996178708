const isMobile = window.matchMedia('only screen and (max-width: 991px)').matches;
class DashboardNavigation {
    static build(data) {
        return new DashboardNavigation()
            .setBody()
            .setPageId(data.pageId)
            .setNavigationItems(data.navigationItems)
            .setNavigationItemPageIdAttr(data.navigationItemPageIdAttr)
            .setNavigationItemActiveClass(data.navigationItemActiveClass)
            .setActive(data.closeButton)
            .setOpenButton(data.openButton)
            .setCloseButton(data.closeButton)
            .setWrapper(data.wrapper)
            .setOpenClass(data.openClass)
            .initEvents();
    }

    setPageId(pageId) {
        this.pageId = pageId;
        return this;
    }

    setNavigationItems(navigationItemsClass) {
        this.navigationItems = document.querySelectorAll(`.${navigationItemsClass}`);
        return this;
    }

    setNavigationItemPageIdAttr(navigationItemPageIdAttr) {
        this.navigationItemPageIdAttr = navigationItemPageIdAttr;
        return this;
    }

    setNavigationItemActiveClass(navigationItemActiveClass) {
        this.navigationItemActiveClass = navigationItemActiveClass;
        return this;
    }

    setActive(closeBtnClass) {
        this.activeNavigationItem = this.findActiveNavigationItem(
            this.navigationItems,
            this.navigationItemPageIdAttr,
            this.pageId
        );

        this.activeNavigationItem.classList.add(this.navigationItemActiveClass);
        this.activeNavigationItem.classList.add(closeBtnClass);

        return this;
    }

    /* eslint-disable class-methods-use-this */
    findActiveNavigationItem(items, attr, id) {
        return Array.from(items).filter(item => item.dataset[attr] === id)[0];
    }

    setBody() {
        this.body = document.querySelectorAll('body')[0];
        return this;
    }

    setOpenClass(openClass) {
        this.openClass = openClass;
        return this;
    }

    setOpenButton(buttonClass) {
        this.openButton = document.querySelectorAll(`.${buttonClass}`)[0];
        return this;
    }

    setCloseButton(buttonClass) {
        this.closeButton = document.querySelectorAll(`.${buttonClass}`)[0];
        return this;
    }

    setWrapper(wrapperClass) {
        this.wrapper = document.querySelectorAll(`.${wrapperClass}`)[0];
        return this;
    }

    open() {
        this.wrapper.classList.add(this.openClass);

        if (isMobile) {
            this.body.style.overflow = 'hidden';
        }

        return this;
    }

    close() {
        this.wrapper.classList.remove(this.openClass);

        if (isMobile) {
            this.body.style.overflow = 'unset';
        }

        return this;
    }

    initEvents() {
        if (this.openButton) {
            this.openButton.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.open();
            });
        }

        if (this.closeButton) {
            this.closeButton.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.close();
            });
        }

        return this;
    }
}

module.exports = {
    DashboardNavigation: DashboardNavigation
};
